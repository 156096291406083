.aboutReservationContent.settings.myPayments {
  overflow: visible;
  padding-right: 27px;
  padding-left: 27px;
}

.aboutReservationContent.settings.myPayments > p {
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
}

.transactionHistoryItem.payment:hover {
  background-color: var(--input);
}

.transactionHistoryItem.payment:hover .transactionHistoryIconWrapper {
  background-color: var(--white);
}

.transactionHistoryItem.payment .transactionHistoryIconWrapper > svg {
  stroke-width: 1.5px;
}

.aboutReservationContent.settings.myPayments .transactionHistoryItem.payment {
  box-sizing: border-box;
  padding: 16px 8px;
  border-radius: 8px;
  overflow: visible;
  cursor: pointer;
  margin-bottom: 0px;
  padding-right: 16px;
}

.paymentDetailsActions {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}

.paymentDetailsActions > span {
  font-size: 14px;
  color: var(--text);
  margin-bottom: 15px;
  margin-left: 8px;
}

.paymentDetailsActions .sidebarShortcutItem.minPad {
  margin-left: 0px;
  width: 100%;
  margin-right: 0px;
}

.transactionHistoryItem.active {
  padding-left: 12px;
  margin-left: 8px;
  margin-right: 8px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-top: 16px;
  margin-bottom: 35px;
  background-color: var(--input);
  border-radius: 12px;
}

.grayBackBtn.black {
  background-color: var(--text);
  color: var(--white);
}

.transactionHistoryItem.active .transactionHistoryIconWrapper {
  background-color: var(--white);
}

.transactionHistoryItem.active .transactionHistoryIconWrapper > svg {
  stroke-width: 1.5;
}

@media (max-width: 480px) {
  .aboutReservationContent.settings.myPayments {
    padding-bottom: 30px;
    padding-left: 17px;
    padding-right: 16px;
  }
}
