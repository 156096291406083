.noResultsContainer {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noResultsContainerList {
  display: flex;
  align-items: center;
  margin-top: 84px;
  border: 1px solid var(--border);
  box-sizing: border-box;
  padding: 18px 25px 22px 22px;
  border-radius: 5px;
}

.noResultsContent {
  margin-left: 24px;
}

.noResultsContent > b {
  font-size: 14.5px;
  font-weight: var(--semibold);
}

.noResultsContent > p {
  margin-top: 5px;
  font-size: 14px;
  max-width: 80%;
  color: var(--subheader);
}

.icon {
  stroke-width: 1.2;
  color: var(--primary);
}

.noResultsContainer > b {
  margin-top: 20px;
  font-weight: var(--semibold);
}

.noResultsContainer > p {
  text-align: center;
  max-width: 75%;
  margin: 10px auto;
  color: var(--subheader);
}

.bbRoundedOutline {
  margin-top: 30px;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-weight: normal;
  padding: 11px 18px;
  font-size: 14.5px;
  border-radius: 5px;
  background-color: var(--white);
  transition: 0.2s ease;
}

.bbRoundedOutline:hover {
  background-color: var(--primary);
  color: var(--white);
}

.bbRoundedOutline.radius.list {
  min-width: fit-content;
  margin-top: 0px;
  font-size: 13px;
  padding: 8.5px 14px;
}

@media (max-width: 767px) {
  .noResultsContainer {
    margin-top: 0px;
    align-items: start;
  }

  .noResultsContainer > b {
    text-align: start;
    font-size: 15px;
  }
}
