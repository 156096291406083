.currentPayoutMethod {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 22px 15px;
  background-color: var(--input);
  border-radius: 12px;
  margin-top: 10px;
  margin-left: -3px;
}

.currentPayoutMethod > svg {
  font-size: 23px;
  stroke-width: 1.4;
  margin-right: 15px;
}

.currentPayoutMethod > p {
  font-size: 15px;
}

.grayBackBtn {
  width: fit-content;
  box-sizing: border-box;
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #f1f1f1;
  font-size: 13px;
  font-weight: var(--medium);
}

.grayBackBtn.settings {
  margin-top: 20px;
}

.aboutReservationContent.settings .error {
  font-size: 13px;
  margin-top: 8px;
  color: var(--error);
  font-weight: var(--medium);
}

.saveChangesBtn {
  margin-top: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 12px;
  background-color: var(--primary);
  color: var(--white);
  font-weight: var(--medium);
}

.disabledBtn {
  background-color: var(--border);
}
