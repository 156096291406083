.trailerTableListItemContainer.reservations {
  display: grid;
  column-gap: 30px;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 22px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 28px;
}

.trailerTableListItemContainer.reservations:nth-child(2) {
  margin-top: 25px;
}

.trailerTableListItemContainer.reservations:hover
  .reservationTableListItemSelect {
  border: 1.5px solid var(--primary);
}

.reservationTableListItem {
  display: flex;
  align-items: center;
}

.reservationTableListItemSelect {
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid var(--border);
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reservationsTableAvatar {
  margin-right: 15px;
  width: 48px !important;
  height: 48px !important;
  min-width: 48px !important;
}

.reservationsTableAvatar img {
  min-width: 100%;
  min-height: 100%;
  margin-left: 15px;
}

.reservationTableListItemInfo > p:nth-child(1) {
  font-size: 14px;
  font-weight: var(--medium);
  color: var(--primary);
}

.reservationTableListItemInfo > p:nth-child(2) {
  margin-top: 3px;
  font-size: 14px;
  color: var(--text);
}

.reservationTableListItem > svg {
  width: 24px;
  margin-right: 15px;
  stroke-width: 1.6;
}

.reservationTableListItem > p {
  font-weight: var(--medium);
  font-size: 14px;
}

.reservationTableListItemAction {
  font-size: 24px !important;
}

.reservationTableListItemAction > svg {
  stroke-width: 1.5;
  color: var(--text);
}

.reservationTableListItemAction.confirm {
  color: var(--secondary) !important;
  left: -8px;
}

.reservationTableListItemAction.deny {
  color: var(--error) !important;
  left: -5px;
}

.reservationTableListItemSelectIndicator {
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background-color: var(--primary);
}

.reservationTableListItemSelect.active {
  border: 1.5px solid var(--primary);
}

.reservationTableListItemStar {
  margin-left: 6px;
  color: #e8c85e;
}

.status.completed {
  color: var(--secondary);
  font-weight: var(--semibold);
}

.status.request {
  color: var(--subheader);
  font-weight: var(--semibold);
}

.status.upcoming {
  font-weight: var(--semibold);
  color: var(--primary);
}
