.aboutReservationContent.settings.transactions > p > u {
  color: var(--text);
  font-weight: var(--medium);
  cursor: pointer;
}

.transactionHistoryContainer {
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;
}

.transactionHistoryItem {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.transactionHistoryIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: var(--input);
  border-radius: 50%;
  margin-right: 15px;
}

.transActionHistoryDetails {
  display: flex;
  flex-direction: column;
}

.transActionHistoryDetails > p {
  font-weight: var(--semibold);
  font-size: 14px;
  color: var(--text);
}

.transActionHistoryDetails > span {
  font-weight: normal;
  color: var(--subheader);
  margin-top: 3px;
  font-size: 13.3px;
}

.transactionHistoryItem > b {
  color: var(--text);
  margin-left: auto;
  font-size: 14px;
}
