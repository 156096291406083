.licenseGrid {
  display: flex;
  flex-direction: column;
}

.licenseGridItem,
.licenseGridItemActive {
  width: 100%;
  height: 100%;
  max-height: fit-content;
  border: 1px solid var(--border);
  border-radius: 8px;
  display: flex;
  box-sizing: border-box;
  padding: 24px;
  position: relative;
  transition: 0.2s ease;
  cursor: pointer;
  margin-top: 15px;
  align-items: center;
}

.licenseGridItem > svg,
.licenseGridItemActive > svg {
  min-width: 20px;
}

.licenseGridItem > div,
.licenseGridItemActive > div {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  max-width: 88%;
}

.licenseGridItemText > b {
  font-weight: var(--semibold);
  font-size: 15px;
}

.licenseGridItemText > p {
  font-size: 14px;
  color: var(--subheader);
  margin-top: 4px;
  font-weight: var(--medium);
}

.licenseGridItemActive {
  border: 1.8px solid var(--text);
}

.licenseGridItem:hover {
  border: 1.8px solid var(--text);
  cursor: pointer;
  transition: 0.2s ease;
}

.licenseIcon {
  font-size: 24px !important;
}

.trailerTypeInfoContainer.licenses {
  padding: 25px;
}

.trailerTypeInfoContainer.licenses > p:first-child {
  font-weight: var(--semibold);
  margin: 0px;
}

.trailerTypeInfoContainer.licenses > p:nth-child(2) {
  font-weight: normal;
  margin: 12px 0 8px 0;
  color: #000;
}

@media (max-width: 430px) {
  .licenseGridItem,
  .licenseGridItemActive {
    padding: 15px;
  }
}

@media (max-width: 350px) {
  .licenseGridItem,
  .licenseGridItemActive {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .licenseGridItem > div,
  .licenseGridItemActive > div {
    margin-left: 0px;
    margin-top: 15px;
  }
}
