@media (max-width: 480px) {
  .logoLarge {
    display: block;
  }

  .largeLogoContainer {
    display: none;
  }
}

@media (min-width: 480px) {
  .logoSmall {
    display: block;
  }

  .smallLogoContainer {
    display: none;
  }
}

@media (width: 480px) {
  .logoSmall {
    display: inline;
  }

  .smallLogoContainer {
    display: inline;
  }
}
