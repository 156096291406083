.sideBarSettingsWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.sidebarSettingsHeader {
  box-sizing: border-box;
  padding: 30px 30px 20px 30px;
  position: relative;
  background-color: var(--white);
  width: 100%;
}

.sidebarSettingsHeader .goBack {
  padding: 4px !important;
  color: var(--primary) !important;
  position: absolute !important;
  left: 18px;
  top: 27.5px;
}

.sidebarSettingsHeader > p {
  font-weight: var(--semibold);
  font-size: 18px;
  margin-left: 32px;
}

.sidebarSettingsHeader > p:nth-child(3) {
  color: var(--subheader);
  font-size: 14.8px;
  font-weight: normal;
  margin-left: 0px;
  margin-top: 12px;
}

.sidebarSettingsContentContainer {
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 30px;
}

.sidebarSettingsContentContainer > p,
.sidebarSettingsContentContainer.accessoires > p {
  font-weight: var(--semibold);
  font-size: 13.5px;
  margin-bottom: 10px;
}

.sidebarSettingsContentContainer.accessoires > p {
  color: var(--subheader);
}

.searchbox.accessoires {
  padding-right: 6px;
  margin-bottom: 45px;
  margin-top: 20px;
}

.searchbox.accessoires > input {
  margin-left: 0px;
}

.addAccessoire {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  background-color: var(--primary);
  color: var(--white);
}

.accessoireItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 18px 0;
  border-bottom: 1px solid var(--border);
  cursor: pointer;
}

.accessoireItem.active > p {
  font-size: 14px;
}

.checkbox.remove {
  border: 0px solid var(--error) !important;
  color: var(--white);
  font-size: 12.5px !important;
  background-color: var(--error);
  border-radius: 50%;
  padding: 1px;
}

.accessoireItem:hover .checkbox {
  border: 1px solid var(--primary);
}

.accessoireItem > p {
  margin-right: auto;
  font-size: 14px;
}

.sidebarSettingsContentContainer.popular {
  margin-top: 45px;
}

.sidebarSettingsContentContainer.popular > p {
  margin-bottom: 10px;
}

.sidebarSettingsContentContainer.noResults {
  margin-top: 0px;
}

.accessoireItem > div > img,
.accessoireItem.popular > div > svg {
  max-width: 21px;
  margin-right: 15px;
}

.accessoireItem > div {
  font-size: 10px !important;
}

.checkbox.isActive {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  min-height: fit-content;
  font-size: 18px;
}

.checkboxCheck {
  margin: auto !important;
  color: var(--white);
  font-size: 14px;
  stroke-width: 2.5px;
}

.sidebarSettingsContentContainer.variable > span {
  font-size: 14px;
  font-weight: normal;
  color: var(--subheader);
}

.trailerTypeSelectContainer.accessoires > div > img {
  width: 20px;
}

.trailerTypeSelectContainer.accessoires > div > svg {
  width: 21px;
}

.trailerTypeSelectContainer.accessoires.flexbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;
  background-color: #f1f1f1;
}

.trailerTypeSelectContainer.accessoires.flexbox:hover {
  border: 1px solid var(--border);
}

.trailerTypeSelectContainer.accessoires.flexbox > p {
  margin: 0px;
}
