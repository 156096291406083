.formContainer {
  width: 50%;
  height: fit-content;
  margin: 50px auto 0 auto;
  box-sizing: border-box;
  padding-bottom: 120px;
  position: relative;
  padding: 20px;
}

.formContainer > b {
  margin: 0px;
  font-size: 22px;
}

.formContainer a {
  text-decoration: none;
  color: var(--primary);
}

.formContainer > b > span {
  color: var(--primary);
  font-weight: var(--bold);
}

.formContainer > p {
  margin: 5px 0 0 0;
  color: var(--subheader);
  font-weight: var(--medium);
}

.formQuestions {
  display: flex;
  flex-direction: column;
}

.formInputItem {
  width: 100%;
}

.formQuestions > span,
.formInputItem > span {
  margin-top: 30px;
  font-size: 14px;
  font-weight: var(--semibold);
  color: black;
}

.formSelect {
  width: 50%;
  height: 46px;
  border: 1px solid var(--border);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.formSelect > select,
.formSelect > input {
  width: 100%;
  height: 44px;
  border: none;
  outline: none;
  border-radius: 5px;
  box-sizing: border-box;
}

.formSelect > select:nth-child(1) {
  color: var(--subheader) !important;
}

.formSelect > textarea {
  width: 95%;
  height: 90%;
  border: none;
  resize: none;
  outline: none;
}

.formSelect > textarea::placeholder {
  font-weight: normal;
}

::placeholder {
  font-weight: normal;
}

.formConfirm {
  height: 42.5px;
  margin-top: 10px;
  display: flex;
  justify-content: end;
}

@media (max-width: 1050px) {
  .formContainer {
    width: 80%;
  }
}

@media (max-width: 680px) {
  .formContainer > p {
    font-size: 14px;
  }
}
