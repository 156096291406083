.sidebarSettingsContentContainer.price {
  margin-bottom: 30px;
}

.sidebarSettingsContentContainer.variable > p {
  font-weight: var(--medium);
  margin-bottom: 6px;
}

.searchbox.price {
  height: 50px;
  margin-top: 15px;
  font-size: 17px;
}

.sidebarSettingsContentContainer.variable {
  margin-top: 0px;
}

.sidebarShortcutItem.price {
  padding: 0px;
  margin-top: 40px;
  margin-bottom: 15px;
}

.sidebarShortcutItem.price:hover {
  background-color: var(--white);
}

.sidebarShortcutItem.price > p {
  font-weight: var(--medium);
  font-size: 14.5px;
  text-decoration: underline;
}

.searchbox.price > input {
  font-size: 15px;
  font-weight: var(--semibold);
}
