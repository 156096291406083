.headerMenuItem {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  font-size: 14px;
  font-weight: var(--medium);
  text-decoration: none;
  box-sizing: border-box;
  padding: 10px 15px;
  min-width: fit-content;
  color: #000;
  cursor: pointer;
  min-width: 210px !important;
}

.headerContainer {
  border-bottom: 1px solid var(--border) !important;
}

.headerMenuItem:hover {
  background-color: #f1f1f1;
}

.headerMenuItem:last-child > p {
  font-size: 14px;
  font-weight: var(--semibold);
  color: #000;
}

.headerMenuContainer {
  box-sizing: border-box;
  padding: 20px;
  position: absolute !important;
  top: 20px;
  right: 25px !important;
}

/* Bottom nav */

.bottomNavContainer {
  position: fixed;
  bottom: -60px;
  left: 0px;
  width: 100vw;
  height: 128px;
  padding-bottom: 60px;
  box-sizing: border-box;
  /* border-top: 1px solid var(--border); */
  background-color: var(--white);
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-columns: 1fr 1fr 1fr 1fr 0.8fr;
  z-index: 91;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px;
  transition: transform 0.3s ease;
}

.bottomNavItem {
  width: 100%;
  height: 68px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.bottomNavItem > svg {
  font-size: 20px;
  color: var(--text);
}

.bottomNavItem > p {
  margin-top: 5px;
  font-size: 11.5px;
  font-weight: var(--semibold);
  color: var(--text);
}

.bottomNavContainer.visible {
  transform: translateY(0);
}

.bottomNavContainer.hidden {
  transform: translateY(128px);
}

.bottomNavItem.active svg,
.bottomNavItem.active p {
  color: var(--primary);
}

.bottomNavContainer
  .bottomNavItem.general.flexbox.column.center
  .bottomNavAvatar
  > svg {
  color: #fff;
}

.showMapbutton {
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 50px;
  padding: 9.5px 16px;
  font-size: 14px;
  font-weight: var(--medium);
  transition: 0.3s ease;
}

.showMapbutton > svg {
  margin-left: 6px;
}
