.trailerTableSidebarContainer {
  border-right: 1px solid var(--border);
  height: 86.9vh;
  background-color: var(--white);
  position: fixed;
  left: 0px;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 40px;
  min-width: 354px !important;
  width: 354px;
  max-width: 354px;
  width: 100%;
  z-index: 3;
}

.trailerSidebarHeader {
  box-sizing: border-box;
  padding: 30px 30px 20px 30px;
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: fit-content;
  top: 0px;
  min-width: 353px;
}

.trailerSidebarHeader.mobile {
  min-width: 0px;
  max-width: 100vw;
}

.trailerSidebarHeader > p {
  font-weight: var(--semibold);
  font-size: 18px;
}

.searchbox {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--border);
  border-radius: 10px;
  height: 50px;
  margin-top: 25px;
  box-sizing: border-box;
  padding: 0 11px;
  font-size: 15px;
}

.searchbox > input {
  margin-left: 5px;
  flex: 1;
  height: 90%;
}

.sidebarShortcuts:nth-child(2) {
  margin-top: 5px;
}

.sidebarShortcuts {
  margin-top: 30px;
  box-sizing: border-box;
  padding-right: 22px;
  padding-left: 22px;
}

.sidebarShortcuts > p {
  font-weight: var(--semibold);
  font-size: 14px;
  color: var(--subheader);
  margin-bottom: 10px;
  margin-left: 8px;
}

.sidebarShortcutItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 22px 8px;
  cursor: pointer;
  border-radius: 6px;
  font-size: 18px;
}

.sidebarShortcutItem:hover {
  background-color: #f1f1f1;
}

.sidebarIcon {
  font-size: 23px;
  stroke-width: 1.4px;
  margin-right: 15px;
}

.sidebarShortcutItem > p {
  margin-right: auto;
  font-size: 15.5px;
}

.sidebar.divider {
  width: calc(100% - 60px) !important;
  margin: 10px auto 30px auto !important;
}

.sidebarShortcutItem.error,
.sidebarShortcutItem.damage {
  color: var(--error);
}

.alertIcon {
  font-size: 23px;
  stroke-width: 1.4px;
  margin-right: 15px;
  color: var(--error);
}

.sidebarShortcutItem.damage {
  border: 1px solid var(--error);
  width: calc(100% - 18px);
  margin: 0 auto;
  padding: 17px 12px;
  margin-bottom: 30px;
}

.sidebarShortcutItem.damage p {
  font-weight: var(--medium);
  font-size: 14px;
}

.sideBarSettingsWrapper {
  animation: sidebar_animation 0.8s ease;
}

.mobileTrailerTableSidebarContainer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  background-color: var(--white);
  box-sizing: border-box;
  padding: 0 0 90px 0;
  overflow-y: auto;
  animation: slideInBottomMobile 0.3s ease;
  z-index: 95;
}

.mobileTrailerTable {
  position: relative;
  top: -20px;
}
