.trailerTableListItemContainer,
.trailerTableListItemContainerActive {
  display: grid;
  column-gap: 30px;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 0px;
  cursor: pointer;
  box-sizing: border-box;
  max-width: fit-content;
  padding: 20px 60px 0 20px;
  max-width: 1373px;
}

.trailerTableListItemContainer:hover .checkbox,
.trailerTableListItemContainerActive .checkbox {
  border: 1px solid var(--primary);
  background-color: var(--primary);
}

.trailerTableImageContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.trailerTableImageContainer > img,
.myTrailerIcon {
  height: 72px;
  object-fit: cover;
  width: 130px;
  max-width: 130px;
  min-width: 130px;
  border-radius: 8px;
  background-color: var(--secondary);
  margin-left: 20px;
}

.myTrailerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.myTrailerIcon > img {
  width: 34px;
}

.trailerTableImageContainer > div {
  display: flex;
  flex-direction: column;
  font-size: 13.8px;
  font-weight: var(--medium);
  margin-left: 18px;
}

.trailerTableImageContainer > div > p:nth-child(1) {
  margin-bottom: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 225px;
}

.trailerTableImageContainer > div > p:nth-child(2) {
  color: var(--primary);
}

.trailerTableItem {
  font-size: 13.8px;
  font-weight: var(--medium);
}

.trailerTableItem:nth-child(3) p {
  max-width: 80%;
}

.trailerTableIconItem {
  display: flex;
  align-items: center;
}

.trailerTableIcon {
  margin-left: auto !important;
  margin-right: auto !important;
}

.widthContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.widthContainer > p {
  visibility: hidden;
}

.dash {
  width: 25px;
  height: 2px;
  background-color: var(--border);
}

.trailerTableItem.inActive > p:first-child {
  color: var(--error);
  font-weight: var(--semibold);
}

.trailerTableItem.inActive > p:nth-child(2) {
  margin-top: 4px;
}

.trailerTableItem.status {
  color: var(--secondary);
}

.conceptContainer {
  box-sizing: border-box;
  padding: 5px 12px !important;
  border: 1px solid var(--error);
  border-radius: 50px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conceptContainer > p {
  color: var(--error);
  font-size: 13px;
}

.check {
  stroke-width: 3px;
  color: var(--white);
}

/* Action menu */

.trailerTableIconItem.action {
  position: relative;
}

.trailerActionMenu {
  position: absolute;
  right: 0px;
  width: 155px;
  height: fit-content;
  background-color: var(--white);
  top: 40px;
  z-index: 2;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  box-sizing: border-box;
  padding: 8px 0;
}

.trailerActionMenuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 8px 12px;
  font-size: 17px;
}

.trailerActionMenuItem:hover {
  background-color: #f1f1f1;
}

.trailerActionMenuItem > p {
  font-weight: var(--medium);
  font-size: 13.5px;
}

.trailerActionMenuItem svg {
  width: 25px;
}

@media (max-width: 768px) {
  .trailerTableListItemContainer,
  .trailerTableListItemContainerActive {
    padding-left: 0px;
  }

  .trailerTableImageContainer > img,
  .myTrailerIcon {
    margin-left: 0px !important;
  }

  .trailerTableImageContainer .checkbox {
    display: none;
  }
}
