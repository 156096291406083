.loginRegisterModalContainer {
  width: 500px;
  min-width: 500px;
  max-width: 500px;
  height: auto;
  outline: none;
  border-radius: 2;
  margin: auto;
  background-color: var(--white);
  box-sizing: border-box;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: fixed;
}

.closeLoginRegister {
  position: absolute !important;
  top: 15px;
  right: 15px;
  color: var(--primary) !important;
  font-size: 22px !important;
}

.loginRegisterModalHeader > p {
  margin: 0 auto;
  font-weight: var(--medium);
  font-size: 22px;
  max-width: 70%;
  text-align: center;
  line-height: 1.5;
}

.loginRegisterModalHeader > p > span {
  color: var(--primary) !important;
  font-weight: var(--semibold);
}

.loginRegisterContainerContent {
  position: relative;
  width: 100%;
  height: fit-content;
  margin-top: 40px;
}

.loginTextField:nth-child(2) {
  margin-top: 25px;
  font-weight: normal !important;
}

.loginRegisterSelect {
  font-size: 14px;
  color: var(--subheader);
  margin-top: 15px;
}

.loginRegisterSelect > span {
  color: var(--primary);
}

.loginRegisterSelect > span {
  cursor: pointer;
}

.loginRegisterSelect > span:hover {
  text-decoration: underline;
}

.alterNateLoginContainer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.loginDivider {
  display: flex;
  align-items: center;
  position: relative;
  height: 1px;
  background-color: var(--border);
  width: 100%;
}

.loginDivider > p {
  margin: 0 auto;
  font-size: 14px;
  font-weight: var(--medium);
  background-color: var(--white);
  padding: 0 12px;
}

.alternateLoginButton {
  margin-top: 15px;
  font-weight: var(--medium);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  background-color: #d15d5d;
  border-radius: 5px;
  color: var(--white);
  font-size: 20px;
}

.alternateLoginButton > p {
  font-size: 14.5px;
  font-weight: var(--medium);
  margin-left: 15px;
  color: var(--white);
}

.alternateLoginButton:nth-child(2) {
  margin-top: 30px;
  font-weight: var(--medium);
  background-color: #4664be;
  font-size: 16px;
}

@media (max-width: 550px) {
  .loginRegisterModalContainer {
    width: 100vw;
    min-width: 100vw;
    height: 80vh;
    bottom: 0px;
    border-radius: 20px 20px 0 0;
    padding: 60px 30px 0 30px;
  }

  .loginRegisterModalHeader > p {
    max-width: 100%;
  }
}

@media (max-width: 440px) {
  .loginRegisterModalHeader > p {
    font-size: 20px;
  }
}
