.sidebarShortcutItem.price.type {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border);
  border-radius: 0px;
  color: var(--primary);
}

.accessoireItem.type {
  border-bottom: none;
  padding-bottom: 5px;
}

.accessoireItem.type:hover .select {
  border: 1px solid var(--primary);
}

.select {
  min-width: 23px;
  width: 23px;
  min-height: 23px;
  height: 23px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border: 1px solid var(--border);
}

.selectIndicator {
  width: 14px;
  min-width: 14px;
  height: 14px;
  min-height: 14px;
  border-radius: 50%;
  background-color: var(--primary);
}

.select.active {
  border: 1px solid var(--primary);
}

.sideBarSettingsWrapper {
  position: relative !important;
}

.sidebarTrailerTypeGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
}

.sidebarTrailerTypeGrid .iconWrapper > svg {
  width: 80%;
  stroke-width: 1.6;
}

.trailerTypeSelectContainer {
  border-radius: 8px;
  border: 1px solid var(--border);
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
  position: relative;
  background-color: var(--white);
}

.trailerTypeSelectContainer.active {
  border: 1px solid var(--text);
}

.trailerTypeSelectContainer:hover {
  border: 1px solid var(--text);
}

.trailerTypeSelectContainer > div {
  padding: 0px;
  max-height: fit-content;
  max-width: fit-content;
}

.trailerTypeSelectContainer > div > img {
  width: 26px;
}

.trailerTypeSelectContainer > p {
  margin-top: 15px;
  font-weight: var(--medium);
  font-size: 13.5px;
  max-width: 90%;
}

@media (min-width: 769px) {
  .trailerTypeSelectContainer > p {
    word-wrap: break-word;
    max-width: 115.5px;
  }
}
