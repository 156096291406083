.col-start-1 {
  grid-column-start: 7;
}

.col-start-2 {
  grid-column-start: 1;
}

.col-start-3 {
  grid-column-start: 2;
}

.col-start-4 {
  grid-column-start: 3;
}

.col-start-5 {
  grid-column-start: 4;
}

.col-start-6 {
  grid-column-start: 5;
}

.col-start-7 {
  grid-column-start: 6;
}

/* Calendar styling */

.calendarViewDaysGridWrapper {
  margin-bottom: 100px;
  position: relative;
  top: 0px;
}

.calendarViewDaysGridWrapper:last-child {
  margin-bottom: 0px;
}

.calendarViewDaysGrid {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  height: fit-content;
  width: 100%;
  bottom: 0px;
  right: 0px;
  /* background-color: var(--white); */
  position: relative;
  z-index: 0;
  border-collapse: collapse;
  border: 0px solid transparent;
  padding-top: 1px;
  overflow: hidden;
  margin-bottom: 80px;
  top: -2px;
}

.calenderViewDayContainer {
  width: calc(100% - 1px);
  margin-left: -1px;
  margin-top: -1px;
  border: 1px solid var(--border);
  height: calc(100% - 1px);
  background-color: var(--white);
  min-height: 146px;
  cursor: pointer;
}

@media (max-width: 744px) {
  .dayItemContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 0px !important;
    position: relative;
    border-radius: 0px;
  }

  .calendarDayContentTimePrice {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .calendarDayContentTimePrice > time {
    margin: 0 auto !important;
  }
}

.calendarViewDayItem {
  background-color: transparent;
  border: 0px;
  display: block;
  padding: 0px;
  margin: 0px;
  position: relative;
  width: 100%;
  height: 100%;
}

.calendarViewDayItem::before {
  content: '';
  display: block;
  border: 0px;
  inset: -1px;
}

.dayItemContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: start;
  box-sizing: border-box;
  padding: 14px !important;
  position: relative;
  border-radius: 0px;
}

.dayItemContent.withinRange {
  background-color: #f9ede5;
}

.dayItemContent.withinRange .calendarDayContentTimePrice > p {
  color: var(--text);
  font-weight: var(--semibold);
}

.calendarDayContentTimePrice {
  z-index: 12;
}

.calendarDayContentTimePrice > time {
  font-size: 14px;
  font-weight: var(--medium);
}

.calendarDayContentTimePrice > time > p {
  color: var(--primary);
  font-weight: var(--semibold);
}

.calendarDayContentTimePrice > p {
  font-size: 14px;
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: normal;
  color: var(--subheader);
  margin-top: 25px;
}

.dayItemDetails {
  margin-top: auto;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 14.2px;
  color: rgb(113, 113, 113);
}

.dayItemDetails > p {
  font-weight: normal;
  font-size: 14px;
  color: rgb(113, 113, 113);
  margin-left: 6px;
}

/* Styling of todays calendar day */

.dayItemContent.currentDay > p,
.dayItemContent.currentDay .dayItemDetails,
.dayItemContent.currentDay .dayItemDetails > p {
  color: #000;
}

/* Selected calendar day styling */

.dayItemContent.isSelected > time > span {
  color: var(--primary);
  font-weight: var(--semibold);
}

.dayItemContent.isSelected {
  background-color: #ffece3;
}

.isSelected > p,
.isSelected .dayItemDetails,
.isSelected .dayItemDetails > p {
  color: #000;
  font-weight: var(--medium);
}

/* Before current date styling */

.beforeCurrentDay {
  background-color: var(--white);
  opacity: 1;
  background-size: 17px 17px;
  background-image: repeating-linear-gradient(
    45deg,
    #f1f1f1 0,
    #f1f1f1 1.7000000000000002px,
    #ffffff 0,
    #ffffff 50%
  );
}

.beforeCurrentDay > time {
  color: var(--subheader);
}

/* .currentDay.isSelected {
    border: 2px solid var(--primary);
    border-radius: 5px;
} */

.beforeCurrentDay > p,
.beforeCurrentDay .dayItemDetails,
.isNotAvailable > p,
.isNotAvailable .dayItemDetails {
  display: none;
}

.calendarViewDaysGrid *::selection {
  background: none;
}

.calenderViewDayContainer .calendarViewDayItem .beforeCurrentDay {
  cursor: default;
}

/* Not available */
.dayItemContent.isNotAvailable .calendarDayContentTimePrice > time > span {
  color: var(--error);
  padding: 2px;
  text-decoration: line-through;
}

/* Is reserved content */

.isReservedContent {
  height: 34px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  background-color: var(--text);
  margin-top: auto;
  box-sizing: border-box;
  padding: 2px 5px 2px 2px;
  z-index: 99;
  position: relative;
  width: 100%;
  border: 1.5px solid var(--text);
  min-width: 100%;
  overflow: hidden;
  max-width: 100%;
}

/* .isReservedContentDuring.reservationInRange {
    border-top: 1.5px solid var(--text) !important;
    border-bottom: 1.5px solid var(--text) !important;
}

.isReservedContent.reservationInRange {
    border: 1.5px solid var(--text) !important;
    border-right: none !important;
}

.isReservedContentDuring.end.reservationInRange {
    border-top: 1.5px solid var(--text) !important;
    border-bottom: 1.5px solid var(--text) !important;
    border-right: 1.5px solid var(--text) !important;
} */

.isReservedContentDuring {
  height: 34px;
  background-color: var(--text);
  margin-top: auto;
  box-sizing: border-box;
  /* border: 1.5px solid #f1f1f1; */
  padding: 2px 5px 2px 0px;
  z-index: 98;
  position: absolute;
  bottom: 8px;
  left: 0px;
  width: calc(100% + 1px);
}

.isReservedContentDuring.end {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  max-width: calc(100% - 20px);
}

.isReservedContent.request,
.isReservedContent.request.rangeStart,
.isReservedContentDuring.request,
.isReservedContentDuring.end.request {
  background-color: #808588;
  border: 1.5px solid #808588;
}

.isReservedContent.request > p,
.isReservedContent.request.rangeStart > p,
.isReservedContentDuring.request > p,
.isReservedContentDuring.end.request > p {
  color: var(--white);
  font-weight: var(--semibold);
}

.isReservedContent.rangeStart {
  position: absolute;
  bottom: 8px;
  left: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.isReservedAvatar {
  height: 27px !important;
  width: 27px !important;
  border: 1px solid var(--white) !important;
}

@media (max-width: 745px) {
  .isReservedContent,
  .isReservedContentDuring,
  .isReservedContentDuring.end {
    height: 28px;
    margin-bottom: -3px;
  }

  .isReservedAvatar {
    height: 20px !important;
    width: 20px !important;
    border: 1px solid var(--white) !important;
  }
}

.isReservedContent > p {
  font-size: 12px;
  font-weight: var(--medium);
  color: var(--white);
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 30px;
  position: absolute;
  z-index: 98;
  text-align: start;
}

/* Within range styling */

.rangeContainer {
  position: relative;
}

.dayItemContent.isSelected::before {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  /* border: 2px solid var(--primary); */
  border-radius: 5px;
}

.rangeContainer.isSelected .rangeIndicator.isInRange {
  background-color: var(--primary);
}

.rangeIndicator {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background-color: transparent;
  z-index: 98;
  position: absolute;
  top: -1px;
  left: -1px;
  box-sizing: border-box;
}

.rangeIndicator.singleDay {
  border: 2px solid var(--primary) !important;
  border-radius: 5px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}

.rangeIndicator.firstInRange {
  background-color: transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
  border-left: 2px solid var(--primary) !important;
}

.rangeIndicator.lastInRange {
  background-color: transparent;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
  border-right: 2px solid var(--primary) !important;
}

.monthName {
  margin-left: 30px;
  font-size: 18px;
  font-weight: var(--semibold);
}

.isReservedContentDuring.end.current,
.isReservedContentDuring.current {
  background-color: var(--secondary);
}

/* Outline */

.sameWeekRange::before,
.startSameWeekRange::before,
.endSameWeekRange::before {
  content: '';
  width: calc(100% + 1px);
  height: calc(100% + 2px);
  position: absolute;
  top: -1px;
  left: -1px;
  border-top: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
  box-sizing: border-box;
}

.startSameWeekRange::before {
  border-left: 2px solid var(--primary);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.endSameWeekRange::before {
  border-right: 2px solid var(--primary);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* .sameWeekRange:nth-child(1) {
    background-color: red;
} */

.endOfWeekRounded::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: -1px;
  left: -1px;
  border-bottom: 2px solid blue;
  border-top: 2px solid blue;
  border-right: 2px solid blue;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.startOfWeekRounded::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: -1px;
  left: -1px;
  border-bottom: 2px solid blue;
  border-top: 2px solid blue;
  border-left: 2px solid blue;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.topLeftEdge::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: -1px;
  left: -1px;
  border-top-left-radius: 5px;
  border-left: 2px solid var(--primary);
  border-top: 2px solid var(--primary);
}

.bottomRightEdge::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-bottom-right-radius: 5px;
  border-right: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
}

.topBorderEdge::before {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 2px;
  background-color: var(--primary);
}

.bottomBorderEdge::before {
  content: '';
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: 100%;
  height: 2px;
  background-color: var(--primary);
}

.endOfWeekEdge::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-bottom-right-radius: 0px;
  /* border-right: 2px solid var(--primary); */
  border-bottom: 2px solid var(--primary);
}

.calendar.dash {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 5px;
}

/* -------------------------------------------------------------------------------- */

.topLeftBorderRounded::before {
  content: '';
  height: calc(100%);
  width: calc(100% + 1px);
  border-top: 2px solid var(--primary);
  border-left: 2px solid var(--primary);
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: 5px;
  box-sizing: border-box;
}

.startDateAfter::after {
  content: '';
  border-width: 0px 2px 2px 0px;
  border-top-style: initial;
  border-left-style: initial;
  border-top-color: initial;
  border-left-color: initial;
  border-image: initial;
  border-right-style: solid;
  border-right-color: var(--primary);
  border-bottom-style: solid;
  border-bottom-color: var(--primary);
  border-bottom-right-radius: 5px;
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: -3px;
  left: -8px;
}

.bottomRightBorderRounded::before {
  content: '';
  height: calc(100% - 5px);
  width: calc(100% + 1px);
  border-right: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
  position: absolute;
  bottom: -1px;
  left: 0px;
  border-bottom-right-radius: 5px;
  box-sizing: border-box;
}

.endDateAfter::after {
  content: '';
  border-width: 2px 0px 0px 2px !important;
  border-right-style: initial !important;
  border-bottom-style: initial !important;
  border-right-color: initial !important;
  border-bottom-color: initial !important;
  border-image: initial !important;
  border-left-style: solid !important;
  border-left-color: var(--primary) !important;
  border-top-style: solid !important;
  border-top-color: var(--primary) !important;
  border-top-left-radius: 4px !important;
  width: 8px;
  height: 8px;
  position: absolute;
  top: -2px;
  right: -9px;
}

.leftBorder::before {
  content: '';
  height: calc(100% + 1px);
  width: calc(100% + 1px);
  position: absolute;
  top: 0px;
  left: 0px;
  border-left: 2px solid var(--primary);
  box-sizing: border-box;
}

.rightBorder::before {
  content: '';
  height: calc(100% + 1px);
  width: calc(100% + 1px);
  position: absolute;
  top: 0px;
  left: 0px;
  border-right: 2px solid var(--primary);
  box-sizing: border-box;
}

.topBorder::before {
  content: '';
  height: calc(100% + 1px);
  width: calc(100% + 1px);
  position: absolute;
  top: 0px;
  left: 0px;
  border-top: 2px solid var(--primary);
  box-sizing: border-box;
}

.bottomBorder::before {
  content: '';
  height: calc(100% + 1px);
  width: calc(100% + 1px);
  position: absolute;
  top: 0px;
  left: 0px;
  border-bottom: 2px solid var(--primary);
  box-sizing: border-box;
}

.bottomLeftRounded::before {
  border-bottom-left-radius: 5px;
}

.topRightRounded::before {
  border-top-right-radius: 5px;
}

.topLeftRounded::before {
  border-top-left-radius: 5px;
}

.bottomRightRounded::before {
  border-bottom-right-radius: 5px;
}

/* .topBorder.moreHeight::before {
    top: -2px;
} */

/* .bottomBorder.lessHeight::before {
    top: 2px;
} */
