.sidebarSettingsContentContainer.description .textarea {
  width: 100%;
  min-height: 255px !important;
  height: fit-content !important;
  border-radius: 12px;
  border: 1px solid var(--border);
  box-sizing: border-box;
  padding: 18px;
  resize: vertical;
  outline: none;
  margin-top: 25px;
}

.sidebarSettingsContentContainer.description > p {
  margin-top: 6px;
  color: var(--subheader);
}

.sidebarSettingsFooterAction {
  position: fixed;
  left: 0px;
  bottom: 0px !important;
  width: 353px;
  box-sizing: border-box;
  animation: slide_up 0.1s ease-in;
  background-color: var(--white);
  box-sizing: border-box;
  padding: 14px 30px 0 30px;
  height: 90px;
}

.sidebarActionButton {
  width: 100%;
  height: 46px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 13.5px;
  color: var(--white);
  background-color: var(--border);
}

.sidebarSettingsFooterAction.updated .sidebarActionButton {
  background-color: var(--primary);
}

@keyframes slide_up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
