.errorContainer {
  position: fixed;
  bottom: 110px;
  width: 620px;
  height: fit-content;
  background-color: var(--white);
  z-index: 11 !important;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -6px;
  border: 1px solid var(--border);
  box-sizing: border-box;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  animation: pop_in 0.3s ease;
}

.errorMessageContainer {
  margin-left: 15px;
}

.errorMessageContainer > b {
  font-weight: var(--semibold);
  font-size: 14.5px;
}

.errorMessageContainer > p {
  margin-top: 2px;
  font-size: 14.5px;
}

.closeError {
  position: absolute !important;
  top: 10px;
  right: 10px;
  padding: 5px !important;
  font-size: 18px !important;
  color: var(--black) !important;
}

@keyframes pop_in {
  0% {
    bottom: 0px;
  }

  100% {
    bottom: 110px;
  }
}

@keyframes pop_out {
  0% {
    bottom: 110px;
  }

  100% {
    bottom: 0px;
  }
}

@media (max-width: 720px) {
  .errorContainer {
    width: 500px;
  }
}

@media (max-width: 560px) {
  .errorContainer {
    width: 400px;
    padding: 10px 12px;
  }
}

@media (max-width: 430px) {
  .errorContainer {
    width: 90vw;
    padding: 10px;
    flex-direction: column;
  }

  .errorContainer > svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0px;
  }

  .errorMessageContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  .errorMessageContainer > b {
    margin: 0 auto;
  }

  .errorMessageContainer > p {
    margin: 5px auto;
    text-align: center;
  }
}
