.trailerFormFooterContainer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: fit-content;
  box-sizing: border-box;
  padding: 16px 60px;
  width: 100%;
  border-top: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background);
  z-index: 90;
}

/* Responsive layout */
@media (max-width: 750px) {
  .trailerFormFooterContainer {
    padding: 14px 25px;
  }
}

@media (max-width: 500px) {
  .trailerFormFooterContainer {
    padding: 14px 15px;
  }
}
