.trailerOfferPage {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.trailerOfferMap {
  width: 100%;
  height: 91.7vh;
  background-color: var(--white);
  position: relative;
  right: 0px;
  z-index: 2;
}

.trailerOfferMap .map > div,
.trailerOfferMap .map {
  border-radius: 0px !important;
  min-height: 100%;
}

.trailerOfferResults {
  box-sizing: border-box;
  padding: 0px 40px 50px 44px;
  background-color: var(--white);
  max-height: 91.6vh;
  overflow-y: auto;
  border-right: 1px solid var(--border);
}

.trailerOffersContent {
  padding-top: 100px;
  max-width: 100%;
  overflow-x: hidden;
}

@media (max-width: 980px) {
  .trailerOfferResults {
    padding: 22px 24px 50px 24px;
    width: 100vw;
    border-right: none;
    max-width: 100vw;
    overflow-x: hidden;
  }
}
