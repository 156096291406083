.reservationPopupContainer.main {
  animation: slide_to_top 0.25s ease-in forwards !important;
  overflow: hidden;
  max-height: 94vh;
}

.reservationPopupContainerMainContent.settings {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100%;
}

/* Change user info */

.settingsSubCategory {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.settingsSubCategory > span {
  font-size: 15px;
  font-weight: var(--medium);
}

.inputSpanContainer:nth-child(2) {
  margin-top: 25px;
}

.inputSpanContainer {
  width: 100%;
  border: 1px solid var(--border);
  margin-top: 12px;
  box-sizing: border-box;
  padding: 20px 20px 21px 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 16px;
}

.inputSpanContainer:hover {
  border: 1.5px solid var(--text);
}

.inputSpanContainer > span {
  font-size: 13.5px;
  color: var(--subheader);
  font-weight: normal;
  margin-left: 2px;
}

.inputSpanContainer > input {
  margin-top: 1px;
  font-size: 14.5px;
  margin-top: 5px;
  font-weight: var(--semibold);
  cursor: pointer;
  color: var(--text);
}

.changeUserInfoItem {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
}

.changeUserInfoItem:nth-child(4) {
  margin-top: 25px;
}

.divider.settings {
  margin: 20px 0 0 0;
}

.changeUserInfoItem > span {
  font-size: 14.5px;
  font-weight: var(--medium);
}

.changeUserInfoItem > input {
  margin-left: -1px;
  margin-top: 5px;
}

.changeUserInfoItem .textButton {
  position: absolute;
  font-size: 13px;
  top: 0px;
  right: 0px;
  color: var(--primary);
}

.aboutReservationContent.settings,
.aboutReservationContent.settings.changePassword,
.aboutReservationContent.settings.notifications {
  min-height: 100% !important;
}

.aboutReservationContent.settings > p {
  margin-top: 13px;
  margin-bottom: 15px;
  font-size: 14.5px;
}

.reservationPopupContainerHeader > p {
  position: relative;
}

.aboutReservationContent {
  animation: slide_in_left_popup 0.5s ease;
  background-color: var(--white);
}

@media (max-width: 480px) {
  .reservationPopupContainer.main {
    animation: slide_to_top_mobile 0.25s ease-in forwards !important;
  }

  .reservationPopupContainer {
    padding-bottom: 0px;
  }

  .reservationPopupContainerHeader .closeBtn {
    top: 12px !important;
    right: 12px !important;
  }

  .reservationPopupContainerMainContent {
    padding: 0px 24px 30px 24px;
  }

  .reservationPopupContainerHeader {
    padding: 0 24px;
  }
}

@keyframes slide_to_top {
  0% {
    top: 150%;
  }

  100% {
    top: 50%;
  }
}

@keyframes slide_to_top_mobile {
  0% {
    top: 150%;
  }

  100% {
    top: 0px;
  }
}

@keyframes slide_in_left_popup {
  0% {
    opacity: 0;
    left: -35px;
  }

  100% {
    opacity: 1;
    left: 0px;
  }
}
