.priceTimeGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.88fr;
  grid-gap: 20px;
  min-height: 540px;
  height: fit-content;
}

.priceTimeContainer {
  display: flex;
  flex-direction: column;
}

.priceTimeItem {
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 15px;
  box-sizing: border-box;
  padding: 40px 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: fit-content;
}

.priceTimeItem:first-child {
  margin-top: 0px;
}

.priceTimeItem > b {
  font-size: 16px;
  font-weight: var(--semibold);
}

.priceTimeItem > p {
  margin-top: 7px;
  font-size: 14.5px;
  text-align: center;
  color: var(--subheader);
}

.salaryItem {
  display: flex;
  align-items: center;
  margin: 40px auto 0 auto;
  max-width: fit-content;
}

.input-mirror {
  position: absolute;
  top: -9999px;
  left: -9999px;
  white-space: pre;
  pointer-events: none;
  visibility: hidden;
  font-size: 46px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.priceInputContainer.trailerForm {
  margin-top: 5px;
  margin-left: 12px;
  border-radius: 5px;
  width: 80px;
  height: 50px;
  font-size: 46px !important;
  font-weight: var(--bold);
  box-sizing: border-box;
  padding: 0px;
  max-width: fit-content;
  color: var(--text);
  min-width: 26px;
}

@media (max-width: 480px) {
  .priceInputContainer.trailerForm {
    height: fit-content;
  }
}

.priceInputContainer.trailerForm.restricted {
  max-width: 26px !important;
}

.priceInputContainer::placeholder {
  font-weight: var(--bold) !important;
  font-size: 46px !important;
  color: #e0e0e0;
}

.priceSliderContainer {
  display: flex;
  flex-direction: column;
}

.setAverageContainer {
  font-size: 14px;
  font-weight: var(--medium);
  color: var(--primary);
  margin-top: 0px;
  cursor: pointer;
}

.salaryItem > span {
  font-weight: var(--bold) !important;
  font-size: 46px;
  font-weight: var(--medium);
  color: var(--text);
  margin-top: 4px;
}

.rangeSliderContainer {
  position: relative;
  margin-top: 60px;
  width: 100%;
}

.slider {
  padding: 5px 0 !important;
  max-width: 100% !important;
}

.rangeSliderMarks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  margin-top: 4px;
  font-weight: var(--medium);
}

.priceCompareContainer {
  width: 100%;
  height: 100%;
  min-width: 100%;
}

.selectTimesContainer {
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--border);
}

.priceTimeItem > span {
  margin-top: 20px;
  font-size: 14.5px;
  font-weight: var(--semibold);
}

.selectTimeItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
}

.slash {
  font-size: 20px;
  margin: 0 12px;
  color: var(--primary);
}

.timeSelect,
.timeSelectActive {
  border: 1px solid var(--border);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 11px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s ease;
  width: 48%;
  font-weight: var(--medium);
  color: var(--subheader);
  font-size: 15px;
  position: relative;
}

.timeSelectActive {
  border: 1px solid var(--darkBackground);
}

.timeSelectActive > p:first-child {
  color: var(--darkBackground);
}

.timeSelect:hover {
  border: 1px solid var(--text);
  transition: 0.2s ease;
  color: var(--text);
}

.selectTimeContainer,
.selectTimeContainerTop {
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  padding: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: absolute;
  background-color: var(--white);
  z-index: 91;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border-radius: 10px;
  max-height: 150px;
  overflow-y: scroll;
  left: 0px;
}

.selectTimeContainer {
  top: 48px;
}

.selectTimeContainerTop {
  bottom: 50px;
}

.selectTimeContainer > div,
.selectTimeContainerTop > div {
  padding: 8px 15px;
  margin: 5px 5px;
  font-size: 14px;
  font-weight: var(--medium);
  cursor: pointer;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
}

.selectTimeContainer > div:hover,
.selectTimeContainerTop > div:hover {
  background-color: #f5e7dd;
  color: var(--primary);
  transition: 0.3s ease;
}

.aboutAverageContainer {
  display: flex;
  align-items: center;
  border: 1px solid var(--border);
  border-radius: 50px;
  margin-top: 55px;
  margin-bottom: 0px;
  box-sizing: border-box;
  padding: 8px 20px;
}

.aboutAverageContainer > svg {
  font-size: 26px;
  color: var(--primary);
}

.aboutAverageContainer > p {
  margin: 0 auto;
  text-align: center;
  font-size: 13px;
  font-weight: var(--medium);
}

.priceTimeItem > u {
  margin: 10px 0 25px;
  font-size: 13px;
  font-weight: var(--medium);
  color: var(--subheader);
  cursor: pointer;
}

.priceTimeItem > u:hover {
  color: var(--text);
}

.popupContainer.infoBox {
  width: 480px;
  min-height: 300px;
  animation: slide_in 0.3s ease;
}

.popupContainerHeader {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.popupContainerHeader > p {
  font-weight: var(--semibold);
  font-size: 14px;
}

.popupContainerContent > p {
  font-size: 14px;
  margin-top: 6px;
}

.popupContainerContent > b {
  font-weight: var(--semibold);
  font-size: 14px;
  margin-top: 30px;
}

.popupContainerContent > b:nth-child(1) {
  margin-top: 0px;
}

.popupContainerContent {
  box-sizing: border-box;
  padding: 20px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  max-height: 380px;
  overflow-y: auto;
}

.closePopup.transformed {
  top: 50%;
  transform: translateY(-50%);
}

/* Responsive layout */

.formStepContainer.price {
  max-width: 39vw;
}

@media (max-width: 1450px) {
  .formStepContainer {
    max-width: 50vw !important;
  }
}

@media (max-width: 1240px) {
  .formStepContainer {
    max-width: 55vw !important;
  }
}

@media (max-width: 1130px) {
  .formStepContainer {
    max-width: 60vw !important;
  }
}

@media (max-width: 1030px) {
  .formStepContainer {
    max-width: 65vw !important;
  }
}

@media (max-width: 945px) {
  .formStepContainer {
    max-width: 85vw !important;
  }
}

@media (max-width: 750px) {
  .priceTimeGrid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    min-height: fit-content;
    box-sizing: border-box;
    padding-bottom: 50px;
  }

  .priceCompareContainer {
    height: 300px;
  }

  .priceTimeItem {
    min-width: 100%;
  }

  .aboutAverageContainer > svg {
    max-width: 20px !important;
  }
}

@media (max-width: 480px) {
  .priceTimeGrid {
    max-width: calc(100vw - 48px);
  }

  .setAverageContainer {
    font-size: 14px;
  }

  .moreInfoButton {
    font-size: 13px;
  }
}

@media (max-width: 370px) {
  .aboutAverageContainer {
    flex-direction: column;
    border-radius: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (max-width: 550px) {
  .popupContainer.infoBox {
    width: 90vw;
  }
}

.myTrailerPriceContainer {
  width: 50px;
  height: 50px;
  background-color: var(--text);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--medium);
  font-size: 13px;
}
