.availabilityContent {
  font-weight: var(--semibold);
  font-size: 15px;
}

.availabilityContentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infoCircleContainer {
  color: var(--primary) !important;
  font-size: 19px !important;
  cursor: pointer !important;
  position: relative;
}

.infoCircleContainer:hover::after {
  content: 'Pas de beschikbaarheid per dag aan of d.m.v. de knop hieronder kunnen meerdere dagen tegelijk geselcteerd worden.';
  position: absolute;
  font-size: 13px;
  font-weight: normal;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  box-sizing: border-box;
  padding: 15px;
  bottom: 35px;
  background-color: var(--white);
  border-radius: 10px;
  color: var(--text);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.availabilitySelectRow {
  display: flex;
  align-items: center;
  column-gap: 10px;
  row-gap: 20px;
  width: 100%;
  margin-top: 30px;
  flex-wrap: wrap;
}

.availabilitySelectItem {
  color: var(--text);
  box-sizing: border-box;
  width: 71px;
  min-width: 62px;
  height: 94px;
  border-radius: 12px;
  font-weight: var(--medium);
  font-size: 13px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--input);
}

.availabilitySelectItem .dash {
  margin-top: 13px;
  border-radius: 10px;
  background-color: var(--text);
  max-width: 14px;
}

.availabilitySelectItem:hover {
  border: 1.5px solid var(--primary);
  background-color: var(--white);
  color: var(--primary);
}

.availabilitySelectItem.active {
  background-color: var(--primary);
  color: var(--white);
  border: 1.5px solid var(--primary);
}

.availabilitySelectItem.active .dash {
  background-color: var(--white);
}

.availabilityActionHeader {
  margin-top: 30px;
  /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

.availabilityActionHeader > div > p:first-child {
  font-size: 15px;
  font-weight: var(--semibold);
}

.availabilityActionHeader > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.availabilityActionHeader > div > span {
  margin-top: 10px;
  color: var(--subheader);
  font-size: 14px;
  max-width: 95%;
  font-weight: normal;
}

.availabilityActionRow {
  margin-top: 40px;
  display: flex;
  align-items: start;
}

.primaryBtn.rounded.availability,
.deleteTimeslot {
  font-size: 12.5px;
  padding: 8px 15px;
  border-radius: 50px;
  margin-left: 10px;
}

.deleteTimeslot:hover {
  border: 1px solid var(--text);
}

.deleteTimeslot {
  border: 1px solid var(--border);
  background-color: var(--white);
  display: flex;
  align-items: center;
  font-weight: normal;
  margin-left: auto;
  /* margin-right: 10px; */
}

.deleteTimeslot > svg {
  margin-left: 8px;
}

.setAvailabilityContainer {
  max-width: 100%;
  height: fit-content;
  display: inline-block;
  flex-wrap: wrap;
  column-gap: 0px;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  box-sizing: border-box;
  padding-bottom: 50px;
}

.availabilityWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.availabilitySlider {
  width: 100%;
  height: 120px;
  margin-top: 85px;
}

.trackThumb {
  position: absolute;
  left: -30px;
  top: -35px;
  width: 60px;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 50px;
  padding: 6px 0px;
  display: flex;
  align-items: center;
  outline: none;
  justify-content: center;
  font-size: 13px;
}

.timesContainerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--subheader);
  height: fit-content;
  width: 104%;
  position: relative;
  left: -2%;
  z-index: 0;
}

.timesContainerWrapper > div {
  font-size: 12.5px !important;
  margin-top: 10px;
  font-weight: var(--medium);
}

.switchContainerWrapper {
  margin-top: 20px;
  position: relative;
}

.switchContainerWrapper > p:nth-child(2) {
  color: var(--subheader);
  font-size: 14.5px;
  font-weight: normal;
  max-width: 80%;
  margin-top: 4px;
}

.isAvailableContainer.offer {
  margin-top: 60px;
  margin-bottom: 10px;
}

.isAvailableContainer.offer > div {
  display: flex;
  align-items: center;
  width: 10%;
  justify-content: space-between;
}

.trailerNotAvailableContent {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.trailerNotAvailableContent > svg {
  color: var(--primary);
  font-size: 26px;
}

.trailerNotAvailableContent > p {
  margin-top: 20px;
  font-size: 15px;
  max-width: 90%;
}

.trailerNotAvailableContent > p > b {
  font-size: 15px;
  font-weight: var(--medium);
}

.primaryBtn.disabled {
  background-color: var(--border);
}

.multiSelect {
  color: var(--text);
  border: 1.5px solid var(--text);
  box-sizing: border-box;
  width: 70px;
  min-width: 62px;
  height: 90px;
  border-radius: 12px;
  font-weight: var(--medium);
  font-size: 13px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  transition: 0.3s ease;
}

.multiSelect > svg {
  font-size: 24px;
  stroke-width: 1.4;
}

.multiSelect:hover,
.multiSelect.active {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid var(--primary);
}

.availabilitySelectItem > svg {
  display: none;
}

.availabilitySelectItem.hasContent > svg {
  display: block;
  margin-top: 12px;
  font-size: 20px;
  stroke-width: 1.5;
}

.availabilitySelectItem.hasContent .dash {
  display: none;
}

.availabilitySelectItem.hasContent {
  border: 1.5px solid var(--secondary);
  background-color: var(--secondary);
  color: var(--white);
  font-weight: var(--medium);
}

.activeRangesTagRow {
  display: flex;
  align-items: center;
}

.activeRangesTagRow > div {
  margin-right: 12px;
}

.deleteTimeslot.tag {
  background-color: var(--text);
  color: var(--white);
  cursor: pointer;
  font-weight: var(--medium);
  border: 1px solid var(--text);
}

.deleteTimeslot.tag .verticalDivider {
  max-height: 15px;
  min-width: 1.1px;
  margin: 0 6px 0 8px;
  cursor: pointer;
  background-color: var(--white) !important;
}

.deleteTimeslot.tag > svg {
  margin: 0px;
  font-size: 15px;
  stroke-width: 1.6;
}

.borderBtn.isNotAvailable {
  margin-left: 0px;
  margin-top: 50px;
  font-size: 14px;
  width: 190px;
  height: 48px;
}

.borderBtn.isNotAvailable:hover {
  border: 1.5px solid var(--text);
}

.mobileTimeSelectContainer {
  width: 100%;
}
