.datepicker__button {
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0;
}

.datepicker__button > svg {
  min-width: 16px;
  min-height: 16px;
}

/* datepicker specific styling */
.datepicker__body {
  background-color: var(--white);
  border-radius: var(--borderRadiusMedium);
  border: 1px solid var(--input);
  padding: 4px;
  margin: 16px;
}

.rdp {
  --rdp-cell-size: 36px; /* Size of the day cells. */
  --rdp-caption-font-size: 16px; /* Font size for the caption labels. */
  --rdp-accent-color: var(
    --primary
  ); /* Accent color for the background of selected days. */
  --rdp-background-color: var(
    --input
  ); /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: var(
    --primary
  ); /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: var(
    --input
  ); /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff; /* Color of selected day text */
}

.rdp-cell {
  font-size: 14px;
}

polygon {
  visibility: hidden;
}

.rdp-nav > button > svg {
  width: 12px;
  height: 12px;
}
