.calendarDayDetailsContent.out.reservations {
  box-sizing: border-box;
  padding: 30px;
}

.trailerSidebarHeader.reservations {
  z-index: 5;
}

.trailerSidebarHeader.reservations > p:nth-child(2) {
  margin-top: 8px;
  font-size: 14px;
  color: var(--subheader);
  font-weight: normal;
}

.selectReservationSelectContainerWrapper {
  width: 100%;
  position: relative;
}

.reservationsHeaderActions {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.iconBorderBtn {
  flex: 1;
  margin-left: 15px;
  height: 48px;
  min-width: 48px;
  width: 48px;
  border-radius: 6px;
  border: 1px solid var(--border);
  background-color: var(--white);
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconBorderBtn:hover {
  border: 1px solid #000;
  background-color: #f1f1f1;
}

.selectTrailerSelectContainer.reservations {
  margin-top: 0px;
  border-radius: 10px;
}

.sidebarShortcuts.reservations {
  margin-top: 30px;
  box-sizing: border-box;
  padding: 0 30px;
}

.sidebarShortcuts.reservations > p {
  margin: 0px;
}

.reservationDetailsItem {
  box-sizing: border-box;
  padding-top: 15px;
  padding-bottom: 19px;
  border-bottom: 1px solid var(--border);
  position: relative;
}

.reservationDetailsItem > p:nth-child(1) {
  font-size: 13.5px;
  font-weight: var(--semibold);
}

.reservationDetailsItem > p:nth-child(2) {
  margin-top: 4px;
  font-size: 13.6px;
}

.reservationDetailsItemIcon {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  font-size: 18px;
}

.reservationDetailsItem.renter {
  margin-top: 15px;
  cursor: pointer;
}

.reservationDetailsItem.trailer {
  cursor: pointer;
}

/* sidebar Actions */

.sidebarShortcuts.reservations:nth-child(3) {
  margin-top: 0px !important;
}

.sidebarShortcuts.reservations.padding {
  padding: 0 22px;
}

.sidebarShortcuts.reservations.padding > p {
  margin-left: 8px;
  margin-bottom: 10px;
}

.reservationDetailsItem:last-child {
  border-bottom: none;
}

.selectTrailerOptionContainer.reservations {
  top: 56px;
  min-height: 50px;
  width: 100%;
}

.reservationTypeOption {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 7.5px 6px;
  cursor: pointer;
  border-radius: 5px;
}

.reservationTypeOption:last-child {
  margin-bottom: 15px;
}

.reservationTypeOption:hover {
  background-color: #f1f1f1;
}

.reservationTypeOption > p {
  font-size: 14px;
  font-weight: var(--medium);
  margin-left: 10px;
}

.notAvailableSidebarContent.paddingAdded {
  margin-right: 30px;
  margin-left: 30px;
  margin-top: -20px;
}

.sidebar.divider.reservations {
  margin-top: 60px !important;
}

@media (max-width: 767px) {
  .trailerTableSidebarContainer {
    background-color: var(--white);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
  }
}

@media (max-width: 480px) {
  .trailerSidebarHeader.reservations {
    padding-left: 24px;
    padding-right: 24px;
  }

  .sidebarShortcuts.reservations.padding {
    padding: 0px 16px;
  }
}
