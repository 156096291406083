.calendarViewWrapper {
  width: 100%;
  /* min-width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -1px;
}

.calendarViewContainer {
  height: fit-content;
  max-height: 785px;
  width: 100%;
  bottom: 0px;
  left: 0px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
