.reservation__form_footer {
  margin-top: 24px;
}

.reservation__details {
  border: 1px solid var(--input);
  border-radius: var(--borderRadiusMedium);
}

.reservation__details_image {
  max-width: 100%;
  border-radius: var(--borderRadiusMedium) var(--borderRadiusMedium) 0 0;
}

.reservation__details_content {
  padding: var(--padding2Xl) var(--padding2Xl);
}

.reservation__details_title {
  font-weight: var(--semibold);
}

.reservation__details_location {
  margin-top: 8px;
  font-size: 14px;
  font-weight: var(--regular);
}

.reservation__details_date {
  font-size: 14px;
  font-weight: var(--regular);
  margin-top: 4px;
}

.trailer__flex-seperator {
  margin-top: 16px;
  margin-bottom: 16px;
}
