.imageUploadContainer,
.imageUploadContainerSmall {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dotted var(--subheader);
  border-radius: 12px;
  position: relative;
}

.imageUploadContainerSmall > img,
.imageUploadContainer > img {
  border-radius: 12px;
}

.imageUploadContainerSmall {
  height: 190px;
}

.imageUploadContainerSmall:nth-child(2) {
  margin-top: 18px;
}

.imageUploadContainer > p:nth-child(2) {
  font-size: 22px;
  font-weight: var(--medium);
  margin-top: 15px;
}

.imageUploadContainerSmall > p:nth-child(2) {
  font-size: 14px;
  margin-top: 12px;
  font-weight: var(--semibold);
}

.imageUploadContainer > p:nth-child(3) {
  margin-top: 5px;
}

.imageUploadContainer > span {
  margin-top: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.uploadBtn {
  margin-top: 80px;
  font-weight: var(--semibold);
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}

.imageUploadGrid {
  display: grid;
  grid-template-columns: 1.9fr 1fr;
  grid-gap: 20px;
}

.imageUploadFlexBox {
  width: 100%;
  height: fit-content;
}

.previewImages {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 5px;
}

.moreImagesFlex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
}

@media (max-width: 780px) {
  .imageUploadGrid {
    display: flex;
    flex-direction: column;
  }

  .imageUploadFlexBox {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2%;
  }

  .imageUploadContainer {
    height: 350px;
    max-width: 100%;
  }

  .imageUploadContainerSmall {
    margin-top: 15px !important;
    width: 96%;
    height: 170px;
  }
}

@media (max-width: 330px) {
  .imageUploadContainer > p:nth-child(2) {
    font-size: 18px;
    max-width: 80%;
    text-align: center;
  }

  .imageUploadContainer > p:nth-child(3) {
    max-width: 80%;
    font-size: 15px;
    text-align: center;
  }

  .imageUploadContainer > span {
    max-width: 80%;
    font-size: 13px;
    text-align: center;
  }
}

.extraImageUploadContainer {
  margin-top: 20px;
  width: 100%;
}

.extraImageUploadContainer:last-of-type {
  padding-bottom: 60px;
}

.extraImageUploadContainer > div {
  height: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

@media (max-width: 525px) {
  .extraImageUploadContainer > div,
  .imageUploadFlexBox {
    grid-template-columns: 1fr !important;
  }

  .extraImageUploadContainer > div {
    margin-top: 10px !important;
  }

  .extraImageUploadContainer > div > div,
  .imageUploadFlexBox > div {
    min-width: 100%;
    box-sizing: border-box;
  }
}

.extraImageUploadContainer > div > div {
  margin-top: 0px !important;
  height: 280px;
}

.extraImageUploadContainer > div:last-child {
  margin-top: 0px;
}

.showImageMenu {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 6px 15px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  color: var(--text);
}

.coverFoto {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 13px;
  font-weight: var(--medium);
  padding: 6px 15px;
  background-color: var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.imageUploadFullWidth {
  width: 100%;
  height: 100px !important;
  border: 1px dotted var(--subheader);
  border-radius: 12px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 25px 25px;
}

.imageUploadFullWidth .imageUploadText {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.imageUploadFullWidth .imageUploadText > p {
  font-size: 15px;
  font-weight: var(--semibold);
}

.imageUploadFullWidth .imageUploadText > span {
  font-size: 15px;
  font-weight: normal;
  margin-top: 2px;
  text-decoration: underline;
}

.imageMenu {
  position: absolute;
  top: 50px;
  background-color: var(--white);
  right: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 8px 6px;
  border-radius: 5px;
}

.imageMenuItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  cursor: pointer;
  border-radius: 3px;
  justify-content: space-between;
}

.imageMenuItem:hover {
  background-color: #f1f1f1;
}

.imageMenuItem > p {
  font-size: 14px;
  font-weight: var(--medium);
  margin-right: 10px;
}

@media (max-width: 500px) {
  .imageUploadContainer {
    max-height: 400px;
  }
}

@media (max-width: 330px) {
  .imageUploadFullWidth {
    flex-direction: column;
    height: fit-content !important;
    padding: 25px 10px !important;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .imageUploadFullWidth > svg {
    height: 22px;
    margin: 0 0 6px 0;
  }
}
