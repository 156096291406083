.trailerTableViewContainer {
  width: 100vw;
  max-width: 100vw;
  height: fit-content;
  min-height: 86vh;
  background-color: var(--white);
  position: fixed;
  left: 0px;
  top: 58px;
  z-index: 89;
  overflow-x: auto;
}

.trailerTableGrid {
  display: flex;
  width: 100%;
  background-color: var(--white);
  position: relative;
  z-index: 92;
}

.trailerTableContent {
  box-sizing: border-box;
  position: relative;
  min-height: 86vh;
  width: 100vw;
  min-width: fit-content;
  box-sizing: border-box;
  padding-left: 354px;
}

.myTrailerContainer {
  height: 775px;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 30px;
}

.trailerTableContent.mobile {
  box-sizing: border-box;
  padding-top: 0px;
  padding-right: 20px;
  padding-left: 20px;
  min-height: 86vh;
}

.mobileTrailerTableHeader {
  box-sizing: border-box;
  padding-bottom: 5px;
  position: fixed;
  width: calc(100% - 40px);
  background-color: var(--white);
  z-index: 2;
}

.mobileTrailerTableTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileTrailerTableTitleContainer > p {
  font-weight: var(--semibold);
}

.borderIconBtn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}

.searchbox.mobile {
  margin-top: 20px;
}

.mobileTrailerSortByContainer {
  display: flex;
  align-items: center;
  margin-top: 25px;
  box-sizing: border-box;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border);
  width: 100%;
}

.mobileTrailerSortByContainer > p {
  font-size: 14px;
  font-weight: var(--medium);
}

.customSelect.mobile {
  margin-left: auto;
  max-width: fit-content;
}

.trailerTableListItemContainer.mobile,
.trailerTableListItemContainerActive.mobile {
  display: flex;
  align-items: center;
  padding-right: 0px;
}

.trailerTableListItemContainer.mobile
  .trailerTableImageContainer
  .myTrailerIcon,
.trailerTableListItemContainerActive.mobile
  .trailerTableImageContainer
  .myTrailerIcon {
  max-width: 110px;
  min-width: 110px;
  width: 110px;
}

.mobileTrailerTableSidebarWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  z-index: 5;
}

.mobileTrailerTableSidebarContainer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  background-color: var(--white);
  /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
  box-sizing: border-box;
  padding: 0 0 90px 0;
  overflow-y: auto;
  animation: slideInBottomMobile 0.3s ease;
}

@keyframes slideInBottomMobile {
  0% {
    bottom: -78vh;
  }

  100% {
    bottom: 0px;
  }
}

@keyframes slideInBottomHeader {
  0% {
    top: 110vh;
  }

  100% {
    top: 197px;
  }
}

.mobileTrailerTableSidebarContent {
  margin: 0px;
}

.trailerSidebarHeader.mobile {
  position: sticky;
  width: 100%;
  margin: 0 0 0 0;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: var(--white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  top: 0px;
  /* animation: slideInBottomHeader .215s ease; */
}

.sidebarShortcuts.mobile:nth-child(2) {
  margin-top: 30px;
}

.sidebarSettingsHeader.mobile {
  position: relative;
  width: 100%;
}

.closeMobileSidebar {
  position: absolute !important;
  top: calc(50% + 10px);
  transform: translateY(-50%);
  right: 20px;
  z-index: 6 !important;
  color: var(--subheader) !important;
  font-size: 22px !important;
}

.customSelect.mobile {
  color: var(--text);
  padding-right: 0px;
}

.customSelect.mobile > svg {
  margin-left: 15px;
  margin-right: 0px;
}

@media (max-width: 767px) {
  .myTrailerContainer {
    padding-top: 170px;
  }

  .trailerTableViewContainer {
    top: 60px;
  }
}
