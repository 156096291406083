.availabilityOverviewContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 680px;
  height: fit-content;
  min-height: 300px;
  max-height: 550px;
  border-radius: 10px;
  background-color: var(--white);
  animation: slide_in 0.3s ease;
  box-sizing: border-box;
  padding-top: 60px;
  padding-bottom: 30px;
}

.availabilityOverviewHeader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 25px 20px 25px;
  background-color: var(--white);
  z-index: 2;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.availabilityOverviewContent {
  height: 400px;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 25px;
}

.availabilityOverviewHeader > p {
  font-weight: var(--semibold);
  font-size: 15px;
}

.availabilityOverviewItem {
  margin-top: 25px;
}

.availabilityOverviewItem > p {
  font-size: 13px;
  font-weight: var(--medium);
}

.availabilityOverviewSlider {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  background-color: #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.timesContainerWrapper.preview {
  max-width: 100%;
  margin: 0 auto;
  left: 0px;
}

.timesContainerWrapper.preview .slider-label {
  font-size: 11px !important;
  color: var(--text);
}

.closePopup.availability {
  position: relative !important;
  top: 0px;
  right: 0px;
  width: 36px !important;
  height: 36px;
}

.activeRange {
  background-color: #9cbbba;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  border: 1.5px solid var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeRange > p {
  font-size: 13px;
  font-weight: var(--medium);
  color: var(--white);
  text-align: center;
}
