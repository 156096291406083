.aboutReservationContent.settings.userInfo {
  min-height: 100% !important;
}

.aboutReservationContent.settings.userInfo > p > u {
  font-weight: var(--medium);
  color: var(--text);
  cursor: pointer;
}

.aboutReservationContent.settings.userInfo > p:nth-child(7) {
  margin-top: 0px;
}

.changeProfileImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: fit-content;
  margin: 15px auto 0 auto;
}

.locationInputGrid {
  width: 100%;
  min-height: fit-content;
  height: fit-content;
  border: 1px solid var(--border);
  border-radius: 12px;
  margin-top: 15px;
}

.locationInputItem.streetName {
  border-top: none;
}

.locationInputItem {
  min-height: 60px;
  width: 100%;
  border-top: 1px solid var(--border);
  box-sizing: border-box;
  padding: 7px 11px;
  display: flex;
  flex-direction: column;
}

.locationBottomGrid {
  display: grid;
  grid-template-columns: 0.25fr 0.2fr 0.65fr;
}

.locationInputItem.houseNumber,
.locationInputItem.postalCode {
  border-right: 1px solid var(--border);
}

.locationInputItem > span {
  font-size: 12px;
  font-weight: normal;
  color: var(--subheader);
}

.locationInputItem > input {
  max-width: 200px;
  width: 170px;
  margin-top: 4px;
  font-size: 13px !important;
}

.locationInputItem.streetName > input {
  max-width: 100%;
  width: 100%;
}

.locationInputItem.streetName:hover {
  border: 1px solid var(--text);
  top: -1px;
  left: -1px;
  bottom: -3px;
  right: -1px;
  margin-right: -3px;
  position: relative;
  width: calc(100% + 2px);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  cursor: pointer;
}

.locationBottomGrid {
  margin-top: -2px;
}

.locationInputItem.houseNumber > input {
  max-width: 40px;
}

.locationInputItem.postalCode > input {
  max-width: 70px;
}

.settingsMapContainer {
  width: 100%;
  min-height: 310px;
  height: 310px;
  margin-top: 15px;
  position: relative;
}

.locationDisplayContainer {
  display: flex;
  align-items: center;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 18px 12px;
  background-color: var(--input);
  margin-top: 20px;
}

.locationDisplayContainer > svg {
  font-size: 19px;
  stroke-width: 1.7;
}

.locationDisplayContainer > p {
  margin-left: 15px;
  font-size: 14px;
  font-weight: var(--medium);
}

.locationSettingAutocomplete {
  font-size: 13.5px !important;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0px !important;
  box-sizing: border-box;
  padding-left: 20px;
}

.searchbox.price.location.settings {
  max-width: 86%;
  margin: 25px auto;
  border-radius: 50px;
  box-sizing: border-box;
  padding: 0px;
}

.userLocationIndicator {
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userLocationIndicator > svg {
  font-size: 20px;
  color: var(--white);
  stroke-width: 1.6;
}

.editProfilePicture {
  position: absolute;
  bottom: -6px;
  right: -6px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.editProfilePicture > svg {
  color: var(--white);
  font-size: 21px;
  stroke-width: 1.7;
  margin-left: 1px;
}

.iconBtn.edit {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--text);
  background-color: var(--text);
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconBtn.edit > svg {
  color: var(--white);
  font-size: 21px;
  stroke-width: 1.5;
}

.inputSpanContainer.iconSelectItem {
  position: relative;
}

.inputSpanContainer.iconSelectItem > svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  font-size: 22px;
  stroke-width: 1.5;
}

.inputSpanContainer.iconSelectItem > u {
  font-size: 13.5px;
  font-weight: var(--medium);
  margin-top: 4px;
  margin-left: 3px;
}

.css-1q0c2bl-MuiPaper-root-MuiPickersPopper-paper {
  border-radius: 20px !important;
}

.inputSpanContainer.iconSelectItem > p {
  font-size: 13.5px;
  font-weight: var(--medium);
  margin-top: 4px;
  margin-left: 3px;
}

@media (max-width: 480px) {
  .aboutReservationContent.settings.userInfo {
    min-height: 812px !important;
    max-height: 812px !important;
    padding-bottom: 40px;
  }
}
