.mobileTrailerCardContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
  z-index: 0;
  position: relative;
}

.mobileCardOwner {
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  margin-bottom: 0px;
}

.mobileCardAvatar {
  width: 32px !important;
  height: 32px !important;
}

.mobileCardOwner > p {
  margin-right: auto;
  margin-left: 12px;
  font-size: 14px;
  font-weight: var(--semibold);
  flex-wrap: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  color: var(--text);
}

.mobileCardOwner > span {
  font-size: 13px;
  font-weight: var(--medium);
}

.trailerImagesSliderMob {
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 12px;
  width: 100vw;
  display: flex;
  align-items: center;
  min-height: fit-content;
  margin-top: px;
  height: 237px;
  margin-top: 12px;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
}

.mobileTrailerImagesContainer {
  width: 80%;
  min-width: 340px;
  height: 235px;
  max-height: 235px;
  margin-right: 12px;
  position: relative;
  border-radius: 12px;
  scroll-snap-align: center;
  background-color: var(--secondary);
}

.mobileTrailerImagesContainer > img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  scroll-snap-type: x mandatory;
}

.mobilePriceLabel {
  width: 110px;
  height: 36px;
  font-size: 13px;
  font-weight: var(--medium);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 14px;
  position: absolute;
  top: 15px;
  left: 15px;
  color: var(--white);
  border-radius: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mobilePriceLabel > p {
  margin-bottom: 1px;
}

.mobileCardFooterContainer {
  box-sizing: border-box;
  padding: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border);
  width: calc(100% - 48px);
  margin: 0px auto;
  margin-top: 10px;
}

.saveTrailerIconMob {
  margin-left: auto !important;
  font-size: 20px !important;
  color: var(--error) !important;
}

.mobileCardFooterItem:last-of-type {
  margin-left: 20px;
}

.mobileCardFooterItem:last-of-type > p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}

.mobileCardFooterItem:last-of-type svg {
  stroke-width: 1.9;
}

.mobileCardFooterItem:first-of-type svg {
  max-width: 25px;
  width: 22px;
  stroke-width: 1.6;
}

.mobileCardFooterItem > p {
  margin-left: 10px;
  font-size: 13px;
  font-weight: var(--medium);
}

.dotsContainer {
  margin: 15px auto 5px auto;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--border);
}

.dot.active {
  background-color: var(--text);
}
