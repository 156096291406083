.ownerDashboardHeaderContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  border-bottom: 1px solid var(--border);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 14px 30px;
  z-index: 90;
  background-color: var(--white);
}

.ownerDashboardHeaderContent {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.ownerDashboardHeaderContent > a {
  font-size: 13.8px;
  font-weight: var(--semibold);
  color: var(--subheader);
  margin-right: 60px;
  cursor: pointer;
}

.ownerDashboardHeaderContent > a:hover {
  color: #000;
}

.navlinkActive {
  text-decoration: none;
  color: var(--primary) !important;
}

.ownerDashboardHeaderContent > div > div {
  display: flex;
  align-items: center;
  padding: 7px 10px 8px 10px;
  margin-left: -10px;
  cursor: pointer;
  border-radius: 5px;
  color: var(--subheader);
  font-size: 17px;
}

.ownerDashboardHeaderContent > div > div:hover {
  background-color: #f1f1f1;
}

.ownerDashboardHeaderContent > div > div > p {
  font-size: 13.8px;
  font-weight: var(--semibold);
  color: var(--subheader);
  margin-right: 13px;
}

.ownerDashboardHeaderUser {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 6.5px 10px 6.5px 14px;
  border-radius: 5px;
  cursor: pointer;
}

.ownerDashboardHeaderUser:hover {
  background-color: #f1f1f1;
}

.ownerDashboardHeaderUser > p {
  margin-right: 15px;
  font-size: 15px;
  font-weight: var(--semibold);
  color: var(--secondary);
}

@media (max-width: 768px) {
  .ownerDashboardHeaderContainer {
    padding: 14px 20px;
    justify-content: space-between;
  }

  .ownerDashboardHeaderContent {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 860px) {
  .profileSubheaderMenuWrapper {
    display: none;
  }
}

/* Bottom nav */

.ownerDasboardBottomNavContainer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 70px;
  border-top: 1px solid var(--border);
  width: 100vw;
  z-index: 90;
  background-color: var(--white);
}

.ownerDasboardBottomNavContent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 100%;
}

.ownerDasboardBottomNavContent > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 25px;
}

.ownerDasboardBottomNavContent > div > svg {
  stroke-width: 1.5px;
}

/* Mobile menu */

.ownerDashboardMobileMenuContainer {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  z-index: 99;
  box-sizing: border-box;
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
}

.ownerDashboardMobileMenuContainer > p {
  font-size: 20px;
  font-weight: var(--semibold);
  margin-bottom: 30px;
  margin-left: 10px;
}

.mobileMenuItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.mobileMenuItem > p {
  font-size: 14.5px;
  font-weight: var(--medium);
  margin-left: 25px;
}

.mobileMenuItem > svg {
  width: 24px;
}

.mobileMenuItem > svg:nth-child(3) {
  margin-left: auto;
}

.ownerDashboardMobileMenuContainer .divider.fullWidth {
  margin: 15px auto 30px auto;
  width: calc(100% - 20px);
}

.ownerDashboardMobileMenuContainer > span {
  font-size: 13.5px;
  font-weight: var(--semibold);
  color: var(--subheader);
  margin-bottom: 15px;
  margin-left: 10px;
}

.mobileMenuAvatar {
  width: 32px !important;
  height: 32px !important;
}

.primaryBtn.border.primary.menuBtn {
  background-color: var(--white);
  width: calc(100% - 25px);
  margin: 20px auto 0 auto;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size: 14.5px;
  max-height: 58px;
}

.profileSubheaderMenuWrapper,
.ownerMenuContainer {
  z-index: 2000 !important;
}

.ownerMenuContainer:hover {
  background-color: var(--white) !important;
  cursor: default;
}

.ownerMenuItem {
  width: 100%;
  border-radius: 5px;
}

.ownerMenuItem:hover {
  color: var(--text) !important;
}

.dropdown__menu {
  /*display: flex;*/
  /*position: absolute;*/
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 999999 !important;
  top: 65px;
}

.ownerMenuItem.rentalOverview p {
  font-size: 14.5px;
  font-weight: var(--medium);
  box-sizing: border-box;
  padding: 6px 8px;
}

.ownerMenuItem.rentalOverview:hover {
  background-color: var(--input);
  cursor: pointer;
}

@media (max-width: 480px) {
  .ownerDashboardMobileMenuContainer {
    padding-left: 24px;
    padding-right: 24px;
  }
}
