.aboutReservationContent.settings {
  overflow-x: hidden;
}

.changeNotificationItem > div > p:nth-child(1) {
  font-size: 13.5px;
  font-weight: var(--medium);
}

.changeNotificationItem > div > p:nth-child(2) {
  font-size: 13.5px;
  margin-top: 4px;
  box-sizing: border-box;
  padding-right: 30px;
}

.changeNotificationItem {
  margin-bottom: 35px;
}

.aboutReservationContent.settings.notifications > span {
  margin-bottom: 25px;
}

@media (max-width: 480px) {
  .aboutReservationContent.settings.notifications,
  .aboutReservationContent.settings {
    min-height: 841px !important;
    max-height: 841px !important;
    padding-bottom: 40px;
  }
}
