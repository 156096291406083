.notice {
  width: 100%;
  border-radius: var(--borderRadiusMedium);
  padding: var(--padding2Xl) var(--padding2Xl);
}

.notice.notice__normal {
  border: 1px solid var(--input);
}

.notice.notice__error {
  border: 1px solid var(--input);
}

.notice.notice__title {
  font-weight: var(--bold);
}

.notice__error_triangle {
  color: var(--error);
}
