.calendarOverViewContainer {
  min-width: 100vw;
  width: 100vw;
  height: fit-content;
  min-height: 80vh;
  display: grid;
  position: relative;
  top: -6px;
  grid-template-rows: auto 1fr;
  z-index: 90;
  box-sizing: border-box;
  padding-left: 380px;
}

.calendarOverViewCalendar {
  width: 100%;
}

.calendarOverViewCalendar > p {
  font-size: 18px;
  font-weight: var(--semibold);
}

.calendarLegenda {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.calendarLegenda > p {
  margin-left: 10px;
  font-size: 14.5px;
  margin-right: 30px;
  font-weight: var(--medium);
}

.calendarLegenda .calendarLegendaDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--primary);
}

.calendarOverviewHeader {
  display: flex;
  align-items: center;
  width: 100%;
}

.calendarOverviewSelect {
  width: 82%;
  border: 1px solid var(--border);
  height: 42px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 12px;
  cursor: pointer;
}

.calendarOverviewSelect:hover,
.openCalendarSettings:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 1.3px;
  border: 1px solid transparent;
}

.calendarOverviewSelect > p {
  font-size: 14px;
  font-weight: var(--medium);
  margin: 0 auto 0 8px;
}

.openCalendarSettings {
  width: 42px;
  height: 42px;
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 5px;
  font-size: 20px;
  margin-left: auto;
}

.calendarViewGridContainer {
  background-color: var(--white);
  position: relative;
  min-height: 85.5vh;
  height: fit-content;
  width: 100%;
  flex: 1;
  min-width: 100%;
  border-left: 1px solid var(--border);
}

.calendarOverviewActions {
  position: fixed;
  top: 74px;
  left: 0px;
  width: 380px;
  height: 92.5vh;
  max-height: 92.5vh;
  height: 100%;
}
