.accessoiresInput {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--input);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px 10px 10px 15px;
}

.accessoiresInput > input {
  flex: 1;
  margin: 0 12px;
  height: 80%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 15.5px;
  font-weight: var(--medium);
}

.accessoiresInput > input::placeholder {
  color: var(--black);
}

.addBtn {
  background-color: var(--primary);
  width: 36px;
  height: 36px;
  border-radius: 5px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popularAccessoiresContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.popularAccessoiresContainer > span {
  margin: 30px 0;
  font-size: 15px;
  font-weight: var(--semibold);
}

.accessoireTagRow {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--border);
  margin: 30px 0 8px 0;
}

.accessoireTag {
  padding: 6px 18px;
  border: 1px solid var(--primary);
  border-radius: 30px;
  margin-right: 10px;
  font-size: 14.5px;
  font-weight: var(--medium);
  color: var(--primary);
  background-color: var(--white);
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.accessoireTag:hover {
  cursor: pointer;
  padding: 6px 10px 6px 18px;
}

.deleteTagButton {
  font-size: 16px !important;
  margin-left: 12px;
  display: none;
}

.accessoireTag:hover .deleteTagButton {
  display: inline;
}

.popupContainerContent.accessoireInfo > svg {
  margin-bottom: -10px;
  width: 32px;
  stroke-width: 1.4;
}

.trailerTypeGrid.accessoires {
  grid-gap: 10px;
  margin-top: 0px;
}
