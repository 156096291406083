.ticketWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 440px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
  animation: appear_bottom_slide 0.3s ease;
}

.ticketCardContainer {
  width: 100%;
  height: 246px;
  border-radius: 15px;
  position: relative;
  padding: 0px;
}

.ticketCardContainer > img {
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 100%;
  z-index: 1;
}

.ticketInfoContainer {
  margin-top: 10px;
  width: 100%;
  height: 580px;
  border-radius: 15px;
  background-color: var(--white);
  box-sizing: border-box;
  padding: 25px 35px;
  max-height: 580px;
  overflow-y: auto;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
}

.dottedLine {
  display: flex;
  flex-direction: column;
  position: absolute;
  column-gap: 10px;
  z-index: 2;
  height: 100%;
  right: 60px;
}

.dottedLine > span {
  width: 1.5px;
  height: 10px;
  background-color: var(--border);
  margin-top: 10px;
}

.ticketCardContent {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* padding: 25px 30px 22px 35px; */
  display: flex;
  flex-direction: column;
}

.ticketAvatar {
  width: 60px !important;
  height: 60px !important;
}

.ticketContent.flexbox {
  margin: 0px;
}

.ticketContent.flexbox:nth-child(3) {
  margin-top: 18px;
}

.ticketInformationItem {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}

.ticketInformationItem > p:nth-child(1) {
  font-size: 13px;
  color: var(--subheader);
}

.ticketInformationItem > p:nth-child(2) {
  font-size: 13.5px;
  font-weight: var(--medium);
}

.ticketContentInfo {
  margin-left: 18px;
}

.ticketContentInfo > p:nth-child(1) {
  font-weight: var(--semibold);
}

.ticketContentInfo > p:nth-child(2) {
  margin-top: 3px;
  font-size: 14px;
  color: var(--subheader);
}

.ticketInfoContainer > b {
  font-weight: var(--semibold);
  font-size: 15px;
  margin-top: 50px;
}

.ticketInfoContainer > b:nth-child(1) {
  margin-top: 0px;
}

.ticketDetailItem:nth-child(2) {
  margin-top: 40px;
}

.ticketDetailItem {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.ticketDetailItem > img {
  max-width: 25px;
}

.ticketDetailItem > svg {
  font-size: 24px;
  stroke-width: 1.2;
}

.ticketDetailItem > p {
  margin-left: 20px;
}

.ticketRentalReason {
  margin-top: 35px;
  border: 1px solid var(--border);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
}

.ticketRentalReason {
  font-size: 14.5px;
}

.confirmedReservation {
  margin-top: auto;
  margin-bottom: 12px;
}

.ticketActionRow {
  margin-top: auto;
  margin-right: auto;
  margin-left: 0px;
}

.confirmedReservation > svg {
  stroke-width: 1.5;
  font-size: 22px;
  color: var(--secondary);
}

.confirmedReservation > p {
  margin-left: 12px;
  font-weight: var(--semibold);
  color: var(--text);
  font-size: 14px;
}

.borderBtn {
  height: 38px;
  border: 1px solid var(--border);
  border-radius: 5px;
  font-weight: var(--medium);
  width: 120px;
  margin-right: 15px;
  background-color: var(--white);
  font-size: 13px;
}

.borderBtn.secondary {
  background-color: var(--secondary);
  color: var(--white);
  border: 1px solid var(--secondary);
}

.ticketSocialRow.flexbox {
  margin-top: 10px;
}

.socialIconBtn {
  font-size: 23px !important;
  color: var(--subheader) !important;
}

.socialIconBtn > svg {
  stroke-width: 1.5;
}

.closeBtn {
  position: absolute !important;
  top: -20px;
  right: -15px;
  color: var(--text) !important;
  font-size: 20px !important;
}

@keyframes appear_bottom_slide {
  0% {
    top: 150%;
  }

  100% {
    top: 50%;
  }
}

.reviewUserContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.reviewUserContainer > div:nth-child(1) {
  width: 38px !important;
  height: 38px !important;
}

.reviewUserContainer > div:nth-child(2) {
  margin-left: 15px;
}

.reviewUserContainer > div:nth-child(2) > p:nth-child(1) {
  font-weight: var(--semibold);
  font-size: 13px;
}

.reviewUserContainer > div:nth-child(2) > p:nth-child(2) {
  font-size: 13px;
  color: var(--subheader);
}

.borderBtn.fullWidth {
  margin-top: 20px;
  margin-left: 0px;
  width: 100%;
  min-height: 52px;
  border: 1.5px solid var(--text);
  font-size: 14px;
  font-weight: var(--semibold);
}

.iconGridItem.ticket {
  margin: 10px 0px 10px 0px;
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
  color: var(--white);
  position: relative;
  min-width: 100%;
  justify-content: center;
  height: 44px;
}

.iconGridItem.ticket > p {
  min-width: fit-content;
  overflow: auto;
  max-width: unset;
  color: var(--white);
  font-weight: var(--medium);
  font-size: 13.5px;
  margin-left: 12px;
}

.iconGridItem.ticket.denied {
  border: 1px solid var(--error);
  background-color: var(--error);
  color: var(--white);
}

.iconGridItem.ticket.denied > p {
  color: var(--white);
}

.changeReservationChoice {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 15px);
  font-weight: var(--medium);
  color: var(--text);
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.changeReservationChoice > svg {
  margin-right: 5px;
}

.ticketCardContent .aboutUserCardWrapper .aboutOwnerCard {
  padding-bottom: 20px;
  padding-top: 25px;
  border-radius: 20px;
}

.aboutOwnerCard .ticketPopupCardActions {
  min-width: 100% !important;
  justify-content: center;
}

.trailerReservationIcon > svg {
  width: 25px;
}

.aboutOwnerCard .ticketPopupCardActions .borderBtn {
  padding: 12px 6px;
  height: 44px;
  flex: 1;
  border-radius: 12px;
  margin-right: 7.5px;
}

.aboutOwnerCard .ticketPopupCardActions .borderBtn.secondary {
  margin-right: 0px;
  margin-left: 7.5px;
}

.aboutOwnerCard .ticketPopupCardActions .borderBtn.secondary:hover {
  border: 1px solid var(--secondary);
}

@media (max-width: 460px) {
  .ticketWrapper {
    top: auto;
    bottom: 0px;
    position: fixed;
    left: 0px;
    transform: translate(0, 0);
    width: 100vw;
  }

  .ticketCardContainer {
    background-color: var(--white);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    height: fit-content;
  }

  .ticketInfoContainer {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    margin-top: 0px;
    height: 480px;
  }

  .dottedLine {
    width: 100%;
    bottom: 0px;
    left: 0px;
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .dottedLine > span {
    height: 1.5px;
    width: 10px;
    background-color: var(--border);
    margin-top: 0px;
    margin-right: 10px;
  }

  .ticketContent:nth-child(2) {
    flex-direction: column;
  }

  .ticketContent:nth-child(2) > div {
    margin: 15px auto 0 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .ticketContent:nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ticketContent:nth-child(3) .ticketInformationItem {
    margin-right: 25px;
    margin-left: 25px;
    text-align: center;
  }

  .iconGridItem.ticket {
    margin: 25px auto 10px auto;
    border: 1px solid var(--secondary);
    color: var(--secondary);
    position: relative;
  }

  .iconGridItem.ticket > p {
    min-width: fit-content;
    overflow: auto;
    max-width: unset;
    color: var(--secondary);
    font-weight: var(--semibold);
    font-size: 13.2px;
  }

  .ticketAvatar {
    width: 80px !important;
    height: 80px !important;
  }
}
