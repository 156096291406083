.calendarDayDetailsContainer {
  width: 100%;
  height: fit-content;
  height: 100%;
  border-right: 1px solid var(--border);
  box-sizing: border-box;
  padding: 25px 24px 35px 24px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  align-items: start;
  position: sticky;
  top: 0px;
  left: 0px;
  background-color: var(--white);
  z-index: 1;
}

.calendarDayDetailsContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 100%;
  position: relative;
}

.backToOverview {
  font-size: 22px !important;
  padding: 4px;
  color: var(--primary) !important;
  position: relative;
  left: -7px;
}

.calendarDayDetailsContentWrapper {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding-bottom: 50px;
  margin-top: 25px;
}

.calendarDayDetailsContentWrapper > b,
.calendarDayDetailsContentWrapper > div > b {
  font-size: 21px;
  display: flex;
  align-items: center;
  font-weight: var(--semibold);
  margin-left: 4px;
}

.calendarDayDetailsContentWrapper > div {
  display: flex;
  align-items: center;
}

.isAvailableContainer {
  display: flex;
  align-items: center;
  margin-top: 50px;
  justify-content: space-between;
}

.isAvailableContainer > p {
  font-size: 16px;
  font-weight: var(--semibold);
}

.isAvaiableContainerActions {
  display: flex;
  align-items: center;
}

.isAvailableAction {
  min-width: 26px;
  width: 26px;
  height: 26px;
  background-color: #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 10px;
  color: var(--subheader);
  font-size: 14px;
  border: 1px solid #e2e2e2;
}

.isAvailableAction.active.false {
  background-color: var(--error);
  color: var(--white);
  border: 1px solid var(--error);
}

.isAvailableAction.inactive.false:hover,
.isAvailableAction.false:hover {
  background-color: var(--error);
  color: var(--white);
  border: 1px solid var(--error);
}

.isAvailableAction.inactive.true:hover,
.isAvailableAction.true:hover {
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
  color: var(--white);
}

.isAvailableAction.active.true {
  background-color: var(--secondary);
  color: var(--white);
  border: 1px solid var(--secondary);
}

.divider.calendar {
  min-width: 100%;
  margin: 30px 0 25px 0;
  padding: 0;
}

.divider.calendar:nth-child(3) {
  margin-top: 25px;
}

.dayDetailsTimeSelectContainer {
  display: flex;
  flex-direction: column;
  align-items: start !important;
}

.dayDetailsTimeSelectHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 4px;
}

.dayDetailsTimeSelectHeader > span {
  font-size: 16px;
  font-weight: var(--semibold);
  color: var(--text);
}

.roundedPrimary {
  border-radius: 50px;
  font-size: 12.5px;
  font-weight: normal;
  padding: 5px 12px;
  background-color: var(--primary);
  color: var(--white);
}

.roundedPrimary.disabled {
  cursor: default;
  background-color: var(--border);
}

.deleteTimeBlock {
  font-size: 19px !important;
  margin-top: 28px !important;
  padding: 0px !important;
  max-height: 30px !important;
  color: var(--primary) !important;
  min-width: 30px !important;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: start !important;
}

.priceContainer > p {
  font-size: 13.5px;
  font-weight: var(--semibold);
}

.customInput.dayDetails {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 16px;
  /* height: 55px; */
  box-sizing: border-box;
  padding: 20px 20px 21px 20px;
  position: relative;
  margin-top: 0px;
  cursor: pointer;
}

.customInput:hover,
.dayDetailsTimeSelect:hover {
  border: 1px solid var(--text);
}

.customInput.dayDetails input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 32px;
  color: var(--text);
  font-weight: var(--bold);
  padding-top: 8px;
  cursor: pointer;
}

.customInput.dayDetails > span {
  position: relative;
  font-weight: var(--normal);
  pointer-events: none;
  font-size: 14px !important;
  color: var(--text);
}

.customInput > input:valid ~ span,
.customInput > input:focus ~ span {
  color: var(--subheader);
  transform: translateX(0px) translateY(-20px);
  font-size: 11px;
}

.calendarSwithItem {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: start !important;
  border: 1px solid var(--border);
  border-radius: 16px;
  padding: 26px 20px;
  box-sizing: border-box;
  /* height: 78px; */
}

.calendarSwithItem:hover {
  border: 1px solid var(--text);
  cursor: pointer;
}

.calendarSwithItem > p:first-child {
  font-size: 14px;
  font-weight: var(--normal);
}

.calendarSwithItem .switchContainer {
  right: 20px;
}

.saveChanges.calendar {
  width: 100%;
  border-radius: 10px;
  height: 52px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  bottom: -45px;
  border: 1.5px solid var(--primary);
  background-color: var(--white);
  color: var(--primary);
  font-size: 14px;
  font-weight: var(--semibold);
}

.switchContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.dateDivider {
  width: 8px;
  height: 2.5px;
  background-color: #000;
  margin: 0 9px;
}

/* Not available content */

.notAvailableSidebarContent {
  display: flex;
  flex-direction: column;
  align-items: start !important;
  background-color: #f1f1f1;
  position: relative;
  top: 28px;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 22px 20px 24px 20px;
}

.notAvailableSidebarContent > p {
  font-size: 14.2px;
  margin-top: 20px;
  line-height: 1.5;
}

.infoHoverContainer {
  position: absolute;
  left: 162px;
  top: 3px;
  width: fit-content;
}

.infoHoverContainer.paddingLeft {
  left: 186px;
}

.isAvailableContainerActions {
  display: flex !important;
  align-items: center;
}

/* active reservation on date */

.hasReservationContent {
  margin-top: 25px;
  font-size: 15px;
  color: var(--subheader);
}

.hasReservationActionsContainer {
  display: flex;
  flex-direction: column;
}

.hasReservationActionsContainer .sidebarShortcutItem {
  width: 100%;
}

/* Updated styling */

.calendarDayDetailsContentTitle {
  flex-direction: column;
  align-items: start !important;
  margin-bottom: 24px;
}

.calendarDayDetailsContentTitle > p {
  font-size: 18px;
  font-weight: var(--semibold);
  color: var(--text);
}

.calendarDayDetailsContentTitle > span {
  font-size: 14px;
  margin-top: 5px;
  font-weight: normal;
  color: var(--subheader);
}

/* Test time period */

.dayDetailsTimeSelectWrapper {
  position: relative;
  width: 100%;
}

.dayDetailsTimeSelectCombo.test {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 20px;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  padding: 20px 20px 21px 20px;
  margin-top: 24px;
  border: 1px solid var(--border);
  border-radius: 16px;
}

.dayDetailsTimeSelectCombo.test:nth-child(3) {
  margin-top: 16px;
}

.newPeriodTextButton {
  margin-top: 16px;
  font-size: 14.5px;
  font-weight: var(--semibold);
  cursor: pointer;
  margin-left: 3px;
  margin-bottom: 30px;
  color: var(--white);
  width: 100%;
  border-radius: 10px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0;
}

.dayDetailsTimeSelectCombo.test > label {
  margin-top: auto;
  font-size: 14px;
  color: var(--text);
}

.dayDetailsTimeSelectCombo.test:hover {
  cursor: pointer;
  border: 1px solid var(--text);
}

.dayDetailsTimeSelectContainer > u,
.timePeriodActions > u {
  font-size: 13px;
  font-weight: var(--medium);
  padding-top: 10px;
  padding-left: 10px;
  cursor: pointer;
  color: var(--subheader);
}

.timePeriodActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.timePeriodActions > u:nth-child(2) {
  color: var(--primary);
  margin-left: auto;
  margin-right: 10px;
}

.timePeriodActions > u:nth-child(2):hover {
  font-weight: var(--semibold);
}

.dayDetailsTimeSelectContainer > u:hover,
.timePeriodActions > u:nth-child(1):hover {
  font-weight: var(--semibold);
  color: var(--text);
}

.dayDetailsTimeSelectCombo.test > p {
  font-size: 14px;
  color: var(--text);
  font-weight: var(--bold);
  padding-top: 6px;
  margin-bottom: auto;
}

.dayDetailsTimeSelectCombo.test > svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  font-size: 19px;
}

/* New style */

.calendarSwithItem.availability {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative !important;
  top: 24px;
}

.calendarSwithItem.availability:hover {
  border: 1px solid var(--border);
  cursor: default;
}

.isAvailableContainerActions.newStyle {
  position: absolute !important;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
