.calendarDayDetailsContent.out > b:first-child {
  font-size: 21px;
  display: flex;
  align-items: center;
  font-weight: var(--bold);
  margin-top: 0px;
  color: var(--text);
  margin-left: 5px;
}

.calendarDayDetailsContainer {
  overflow-y: auto !important;
}

.calendarDayDetailsContent.out > p {
  font-size: 14.5px;
  color: var(--subheader);
  margin-top: 12px;
  margin-left: 5px;
}

.customCalendarSelect,
.bbRoundedOutlined {
  min-width: 100%;
  width: 100%;
  margin-top: 30px;
  border: 1px solid var(--border);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 12px 15px;
  background-color: var(--white);
}

.customCalendarSelect > p {
  font-size: 14.5px;
  font-weight: var(--medium);
}

.changePeriodsContainer {
  width: 100%;
}

.changePeriodsContainerHeader {
  width: 100%;
  position: relative;
}

.changePeriodsContainerHeader > p:first-child {
  font-size: 14px;
  margin-top: 15px;
  font-weight: var(--semibold);
}

.changePeriodsContainerHeader > p:nth-child(2) {
  font-size: 14px;
  color: var(--subheader);
  margin-top: 2px;
}

.infoCircle {
  color: var(--primary);
  position: absolute;
  top: 0px;
  right: 0px;
}

.bbRoundedOutlined.calendar {
  margin-top: 30px;
  border: 1px solid var(--primary);
  color: var(--primary);
  justify-content: center;
  transition: 0.2s ease;
}

.bbRoundedOutlined.calendar:hover {
  background-color: var(--primary);
  color: var(--white);
}

.bbRoundedOutlined.calendar > p {
  font-weight: var(--medium);
  margin-left: 8px;
  font-size: 14px;
}

.customCalendarSelect.times {
  margin-top: 20px;
}

/* New sidebar styling */

.selectTrailerSelectWrapper {
  width: 100%;
  position: relative;
}

.selectTrailerSelectContainer {
  display: flex;
  align-items: center;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 1.2px;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 15px;
  cursor: pointer;
  margin-top: 40px;
  position: relative;
}

.selectTrailerSelectContainer:hover {
  background-color: #f1f1f1;
}

.selectTrailerSelectContainer > p {
  font-size: 14.5px;
  margin-left: 10px;
  margin-right: auto;
  font-weight: var(--medium);
}

.selectTrailerOptionContainer {
  position: absolute;
  top: 100px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  padding-bottom: 0px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  z-index: 2;
  cursor: default;
}

.selectTrailerOverflowOptions {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 190px;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.selectTrailerItem {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  cursor: pointer;
}

.selectTrailerItem > img {
  width: 64px;
  height: 50px;
  border-radius: 4px;
  object-fit: cover;
}

.selectTrailerItem > div {
  margin-left: 11px;
}

.selectTrailerItem > div > p:first-child {
  font-size: 13px;
  font-weight: var(--semibold);
}

.selectTrailerItem > div > p:last-child {
  color: var(--subheader);
  font-size: 13px;
  margin-top: 2px;
}

.isSelectedIndicatorCircle,
.isSelectedIndicatorCircleActive {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-left: auto !important;
  margin-right: 5px;
  border: 1.5px solid var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.isSelectedIndicatorCircle {
  border: 1px solid var(--border);
}

.isSelectedActive {
  width: 58%;
  height: 58%;
  background-color: var(--primary);
  border-radius: 50%;
}

.selectTrailerItem:last-child {
  margin-bottom: 2px;
}

.activeTrailerSelectActions {
  border-top: 1px solid var(--border);
  width: calc(100% + 24px);
  margin-left: -12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 14px 18px;
  border-radius: 0 0 8px 8px;
}

.activeTrailerSelectActions > p {
  font-size: 14px;
  text-decoration: underline;
  font-weight: var(--medium);
  cursor: pointer;
}

.activeTrailerSelectActions > button {
  padding: 7px 12px;
  border-radius: 3px;
  background-color: var(--primary);
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
}

.trailerIcon {
  display: flex;
}

.trailerIcon > svg,
.trailerIcon > img {
  width: 24px !important;
  stroke-width: 1.5;
}

.calendarTrailerSelectItem:first-of-type {
  margin-top: 40px;
}

.calendarTrailerSelectItem {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-sizing: border-box;
  border: 1px solid var(--border);
  width: 100%;
  padding: 20px;
  position: relative;
  cursor: pointer;
  transition: 0.2s ease;
}

.calendarTrailerSelectItem:hover {
  border: 1px solid var(--text);
}

.calendarTrailerSelectItem:hover .selectDot {
  border: 1px solid var(--primary);
}

.calendarTrailerSelectItem.active {
  border: 1px solid var(--text);
  cursor: default;
}

.calendarTrailerSelectItem > svg {
  stroke-width: 1.6;
  width: 26px;
}

.calendarTrailerSelectItem > b {
  margin-top: 15px;
  color: var(--text);
  font-size: 14.5px;
  font-weight: var(--semibold);
}

.calendarTrailerSelectItem > span {
  font-size: 14px;
  font-weight: var(--normal);
  margin-top: 3px;
  color: var(--subheader);
}

.selectDot {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid var(--border);
  position: absolute;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 16px;
  transition: 0.2s ease;
}

.selectDot.active {
  border: 1px solid var(--primary);
}

.activeDot {
  width: 70%;
  height: 70%;
  background-color: var(--primary);
  border-radius: 50%;
}
