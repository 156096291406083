.mobileOfferHeader {
  box-sizing: border-box;
  padding: 40px 24px 20px 24px;
  position: fixed;
  background-color: var(--white);
  width: 100%;
  top: 0px;
  z-index: 2;
}

.mobileOfferHeader>div>h3 {
    color: var(--text);
    margin-right: auto;
}

.pageAvatar {
    width: 38px !important;
    height: 38px !important;
}

.mobileInputWrapper {
    background-color: #F2F3F6;
    flex: 1;
    border-radius: 50px;
    box-sizing: border-box;
    padding: 0 15px;
    margin-right: 15px;
    /* margin-right: 15px; */
}

.mobileInputWrapper>input {
    background-color: transparent;
    flex: 1;
    box-sizing: border-box;
    padding: 10px 0;
    width: 100%;
    margin-left: 10px;
}

.mobileOfferHeaderSearch {
    margin-top: 20px;
}

.filtersMob {
    height: 38px;
    width: 38px;
    justify-content: center;
    border: 1px solid var(--border);
    border-radius: 50%;
    background-color: var(--white);
    margin-left: 15px;
}

.filtersMob svg {
    stroke-width: 1.5px;
    font-size: 16px;
    color: var(--text);
}

.filtersMob:hover {
    border: 1px solid var(--text);
}

@media (min-width: 768px) {
    .mobileOfferHeader {
        top: 80px;
        padding-right: 40px;
        padding-left: 44px;
        position: absolute;
        width: calc(55% - 2px);
    }

    .filtersMob {
        width: fit-content !important;
        min-width: fit-content !important;
        border-radius: 50px;
        height: 44px;
        box-sizing: border-box;
        padding-left: 15px;
        padding-right: 15px;
        font-weight: var(--semibold);
        border-radius: 12px;
        border: 1px solid var(--text);
        color: var(--text);
    }

    .filtersMob>svg {
        margin-right: 8px;
        font-size: 18px;
        stroke-width: 1.7;
        color: var(--text);
    }

    .mobileInputWrapper {
        padding-left: 20px;
    }

    .mobileInputWrapper>input {
        padding-top: 13px;
        padding-bottom: 13px;
        margin-left: 15px;
    }
}
