.myTrailersSortByWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px;
  left: 0px;
  z-index: 90;
}

.mobileSortByPopup {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 400px;
  background-color: var(--white);
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  animation: slide_up_sortby 0.3s ease;
  box-sizing: border-box;
  padding: 40px 30px;
}

.mobileSortByPopup > p {
  font-weight: var(--semibold);
  color: var(--text);
  font-size: 18px;
}

.closeBtn.sortBy {
  top: 36px !important;
  right: 30px !important;
}

@keyframes slide_up_sortby {
  0% {
    bottom: -400px;
  }

  100% {
    bottom: 0px;
  }
}
