/* reset css */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* general styling */
body {
  margin: 0;
  font-family: 'Open sans';
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  overflow-x: hidden;
}

:root {
  /* Color variables */
  --primary: #ee7b46;
  --primary-darker: #eb672a;
  --secondary: #43938f;
  --secondary-darker: #3c8481;
  --secondary-lighter: #ebf6f5;
  --white: #ffffff;
  --error: #eb5c5c;
  --border: #dddddd;
  --text: #373530;
  --subheader: #717171;
  --background: #ffffff;
  --darkBackground: #000000;
  --contrast: #f8e6dc;
  --input: #f1f1f1;

  /* Font weight variables */
  --regular: 300;
  --medium: 500;
  --semibold: 600;
  --bold: 700;

  /* Font sizes */
  --fontSmall: 12px;
  --fontMedium: 14px;
  --fontNormal: 16px;
  --fontLarge: 20px;
  --fontXl: 24px;
  --font2xl: 32px;

  /* Padding & margins */
  --basePadding: ;
  --baseMaring: ;

  /* Shadows */
  --optionShadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

  /* Radius */
  --borderRadiusSmall: 4px;
  --borderRadiusMedium: 8px;
  --borderRadiusLarge: 12px;
  --borderRadiusXl: 16px;
  --borderRadiusFull: 999px;

  /* Layout */
  --containerWidthXl: 1200px;
  --containerWidthLarge: 928px;
  --containerWidthMedium: 100%;
  --containerWidthSmall: 100%;

  /* Breakpoints */
  --breakpointSmall: 576px;
  --breakpointMedium: 768px;
  --breakpointLarge: 992px;

  /* Padding */
  --paddingPageGlobal: 2rem 2rem;
  --paddingPageGlobalMedium: 1rem 1rem;
  --paddingPageGlobalSmall: 0rem 1rem;

  --paddingXs: 4px;
  --paddingSm: 8px;
  --paddingMd: 12px;
  --paddingLg: 16px;
  --paddingXl: 20px;
  --padding2Xl: 24px;
  --padding3Xl: 32px;
  --padding4Xl: 40px;
  --padding5Xl: 48px;
}

* {
  font-family: 'Open sans';
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: var(--bold);
}

b {
  font-weight: var(--bold);
}

p {
  margin: 0;
  line-height: 1.5;
}

span {
  font-weight: var(--semibold);
}

h3 {
  font-weight: bold;
  color: var(--secondary);
  margin: 0;
  font-size: 24px;
}

input {
  border: none;
  outline: none;
}

/* text */
.heading-2 {
  font-size: 26px;
  font-weight: var(--semibold);
  color: var(--primary);
}

.heading-3 {
  font-size: 22px;
  font-weight: var(--semibold);
  color: var(--primary);
}

.heading-4 {
  font-size: 18px;
  font-weight: var(--semibold);
  color: var(--text);
}

.paragraph-light {
  font-weight: var(--regular);
}

/* form */
.form-full_width {
  width: 100%;
}

/* others */
.navlink {
  text-decoration: none;
  color: var(--darkBackground);
}

.iconInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.iconInputContainer > span {
  font-size: 14px;
  font-weight: 600;
  margin: 20px 0 15px 0;
}

.iconInput {
  display: flex;
  align-items: center;
}

.iconContainer {
  width: 54px;
  height: 50px;
  min-width: 54px;
  min-height: 50px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 22px;
  border-radius: 5px 0 0 5px;
}

.iconInput > input {
  border-radius: 0 5px 5px 0;
  border: 1px solid var(--border);
  border-left: none;
  height: 50px;
  box-sizing: border-box;
  padding: 0 0 0 15px;
  width: 100%;
  outline: none;
}

.TrailerFormContainer {
  width: 100%;
  height: fit-content;
  position: relative;
  box-sizing: border-box;
  margin: 120px auto 0 auto;
}

.addTrailerPage {
  width: 100vw;
  height: 80vh;
}

.chevron {
  transition: 0.3s ease !important;
}

.chevronTranfsormed,
.chevronTransFormed {
  transform: rotate(180deg) !important;
  transition: 0.3s ease !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.page {
  width: 100vw;
  min-height: 91.8vh;
  height: fit-content;
  position: relative;
  top: 0px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 50px;
}

/* Profile page small styling */

.profileGrid {
  display: grid;
  grid-template-columns: 1fr 0.37fr;
  grid-gap: 20px;
  width: 66vw;
  margin: 0 auto;
}

.profileTrailersHeader {
  display: flex;
  align-items: center;
  width: 66vw;
  margin: 90px auto 15px auto;
  justify-content: space-between;
}

.profileTrailersHeader > p {
  font-weight: var(--semibold);
  font-size: 14.5px;
}

.profileTrailersGridSelect {
  display: flex;
  align-items: center;
  min-width: fit-content;
}

.verticalDivider {
  margin: 0 8px;
  width: 1px;
  min-width: 1px;
  height: 20px;
  background-color: var(--subheader) !important;
}

.seperator {
  border-bottom: 1px solid var(--input);
}

.flexbox {
  display: flex;
  align-items: center;
}

.profileInfo {
  display: flex;
  margin: 60px auto 0 auto;
  width: 1150px;
  max-width: 100vw;
  /* display: grid !important;
  grid-template-columns: 1fr; */
  height: fit-content;
  min-height: 100px;
  position: relative;
}

@media (max-width: 1260px) {
  .profileInfo {
    width: 100%;
    box-sizing: border-box;
    padding: 0 50px;
  }
}

@media (max-width: 768px) {
  .profileInfo.mobile {
    width: 100vw !important;
    display: flex !important;
    flex-direction: column !important;
    height: fit-content !important;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0px;
    margin-top: -20px;
  }
}

.messengerPageWrapper {
  display: flex;
  align-items: top;
  border-top: 1px solid var(--border);
  overflow-y: hidden !important;
}

/* .offerPageGrid {
  display: grid;
  grid-template-columns: 0.32fr 0.65fr 0.255fr;
} */

.pilot__box-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .pilot__box {
    display: none;
    content: "BuurBak is gedurende de pilot niet beschikbaar op mobiele devices.";
  }

  .pilot__box-mobile {
    min-height: 100vh;
    padding: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .pilot__box-mobile p {
    font-size: 24px !important;
  }
}
