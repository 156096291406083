.calendarMontHeader {
  flex: 1;
  height: 92px;
  min-height: 92px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-bottom: 1px solid var(--border);
  z-index: 1;
  position: sticky;
  top: 74px;
  background-color: var(--white);
}

.calendarMonthHeaderContent {
  flex: 1;
  box-sizing: border-box;
  padding: 10px 20px 0 8px;
  position: relative;
}

.calendarMonthSelectWrapper {
  position: relative;
  width: fit-content;
}

.calendarMonthSelect {
  display: flex;
  align-items: center;
  width: 180px;
  cursor: pointer;
  justify-content: space-between;
  font-size: 19px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 8px 10px 8px 12px;
  border-radius: 5px;
}

.calendarMonthSelect > p {
  font-size: 17.5px;
  font-weight: var(--semibold);
  color: var(--text);
}

.calendarMonthOptionsContainer {
  position: absolute;
  top: calc(100% + 10px);
  left: 10px;
  width: 140%;
  background-color: var(--white);
  z-index: 2;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  max-height: 310px;
  overflow-y: auto;
}

.calendarMonthOptionItem {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.calendarMonthOptionItem:hover .isSelectedIndicatorCircle.left {
  border: 1px solid #000;
}

.isSelectedIndicatorCircle.left,
.isSelectedIndicatorCircleActive.left {
  margin-left: 0px !important;
  margin-right: 12px;
  width: 18px;
  height: 18px;
}

.calendarMonthOptionItem > p {
  font-size: 15px;
}

.calendarMonthDays {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  height: fit-content;
  z-index: 1;
  position: relative;
  background-color: var(--white);
  margin-top: auto;
  padding-bottom: 10px;
}

.calendarMonthDays > p {
  font-size: 12px;
  font-weight: var(--medium);
  margin-left: 20px;
  color: #000;
}

@media (max-width: 767px) {
  .calendarMontHeader {
    top: 0px;
  }
}
