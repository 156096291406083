.trailerFormHeaderContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 81px !important;
  background-color: var(--background);
  z-index: 91;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 50px;
  justify-content: space-between;
}

.trailerFormHeaderContainer {
  border: none !important;
}

.progressBar {
  width: 55%;
}

/* Responsive layout */

@media (max-width: 1200px) {
  .progressBar {
    display: none;
  }
}

@media (max-width: 750px) {
  .trailerFormHeaderContainer {
    padding: 14px 25px;
  }
}

@media (max-width: 480px) {
  .trailerFormHeaderContainer {
    padding: 14px 24px;
    height: 80px;
  }
}
