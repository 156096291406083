.primaryBtn.cancel {
  width: 100%;
  font-size: 14px;
  padding: 15px 0;
}

.divider.sidebar {
  width: 100%;
  margin: 30px 0;
}

.sidebarSettingsContentContainer.variable.cancel {
  padding: 0 0 10px 0;
  border-bottom: 1px solid var(--border);
  margin-top: 60px;
}

.sidebarOverlayWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
}

.sidebarOverlayContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vw;
  height: 70vh;
  border-radius: 8px;
  background-color: var(--white);
  animation: slideInBottom 0.3s ease;
  box-sizing: border-box;
  padding: 30px;
  max-height: 70vh;
  overflow-y: auto;
}

.blur {
  animation: appear 0.3s ease;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.4;
  }
}

@keyframes slideInBottom {
  0% {
    top: 170vh;
  }

  100% {
    top: 50%;
  }
}

/* Sidebar overlay content */

.sidebarOverlayContainer > p:nth-child(2) {
  font-weight: var(--semibold);
  font-size: 15px;
  margin-bottom: 30px;
}

.cancelItem {
  margin-top: 30px;
}

.cancelItem > p:nth-child(1) {
  font-weight: var(--medium);
  font-size: 14px;
}

.cancelItem > p:nth-child(1) span {
  font-weight: normal;
  color: var(--subheader);
}

.selectContainer {
  width: 100%;
  height: 44px;
  border: 1px solid var(--border);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
  cursor: pointer;
  margin-top: 15px;
}

.selectContainer > p {
  font-size: 13px !important;
  font-weight: var(--medium);
}

.autoSizeTextarea {
  width: 100%;
  height: 130px !important;
  min-height: 130px !important;
  max-height: 130px !important;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 15px;
  outline: none;
  resize: none;
  border: 1px solid var(--border);
  margin-top: 15px;
}

.overlayFooter {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: var(--white);
  box-sizing: border-box;
  padding: 0 115px;
}
