.dialog__trigger {
  /* */
}

.dialog__overlay {
  background-color: rgba(0, 0, 0, 25%);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1000;
}

.dialog__content {
  background-color: var(--white);
  border-radius: var(--borderRadiusMedium);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--containerWidthLarge) - 4rem);
  padding: 2rem;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 1001;
}

.dialog__header {
  margin-bottom: 1.5rem;
}

.dialog__title {
  font-size: var(--fontLarge);
  font-weight: var(--medium);
}

.dialog__close--icon {
  rotate: -45deg;
}

@media screen and (max-width: 992px) {
  .dialog__content {
    padding: 1rem;
    width: calc(var(--containerWidthSmall) - 2rem);
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
