.tag {
  border-radius: var(--borderRadiusMedium);
  font-weight: var(--regular);
  font-size: 14px;
}

/* tag color */
.tag.tag__primary {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.tag.tag__secondary {
  background-color: var(--secondary);
  color: var(--white);
  border: 1px solid var(--secondary);
}
.tag.tag__outline {
  background-color: transparent;
  border: 1px solid var(--input);
}

.tag.tag__outline-primary {
  color: var(--primary);
  border: 1px solid var(--primary);
}

/* tag size */
.tag.tag__small {
  padding: var(--paddingSm) var(--paddingMd);
}

.tag.tag__medium {
  padding: var(--paddingMd) var(--paddingMd);
}

.tag.tag__large {
  padding: var(--paddingLg) var(--paddingLg);
}
