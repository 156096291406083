.sidebarSettingsContentContainer.edit {
  display: flex;
  flex-direction: column;
}

.sidebarSettingsContentContainer.edit > span:nth-child(1) {
  margin-top: 30px;
}

.sidebarSettingsContentContainer.edit > span {
  margin-top: 50px;
  font-size: 13px !important;
  margin-bottom: 5px;
}

.dayDetailsTimeSelectWrapper.fullWidth {
  grid-template-columns: 1fr 1fr;
}

.sidebarSettingsContentContainer.edit > span:nth-child(5) {
  margin-bottom: 0px;
}
