.heroInfoContainer {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 0px 82px 60px 82px;
  display: flex;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 50px;
}

.heroInfoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heroInfoIcon {
  border: 2.5px solid var(--secondary);
  width: 70px;
  height: 70px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: var(--secondary);
  margin-top: 0px;
}

.heroInfoItem > b {
  margin-top: 15px;
  color: var(--secondary);
}

.heroInfoItem > p {
  max-width: 80%;
  text-align: center;
  margin-top: 10px;
  font-weight: normal;
}
