.previewContainer {
  position: fixed;
  width: 500px;
  height: auto;
  max-height: 90vh;
  overflow-y: scroll;
  background-color: white;
  z-index: 98;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 80px 22px 50px 22px;
  animation: slide_in 0.4s ease;
}

.previewContainerContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto; */
  box-sizing: border-box;
  padding-bottom: 30px;
}

.previewHeader {
  width: 100%;
  height: fit-content;
  position: fixed;
  top: 0px;
  left: 0px;
  border-bottom: 1px solid var(--border);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 12px 20px;
  border-radius: 10px 10px 0 0;
}

.previewHeader > p {
  font-weight: var(--medium);
  font-size: 15px;
}

.previewImagesSlider {
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  min-height: 156px;
  overflow-y: hidden;
}

.previewImage {
  width: 160px;
  min-width: 160px;
  height: 150px;
  min-height: 150px;
  background-color: var(--secondary);
  border-radius: 5px;
  margin-right: 10px;
}

.previewImage > img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.previewImage:last-child {
  margin-right: 0px;
}

.previewContainerInformation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.previewContainerInformationItem > p:first-child {
  font-weight: var(--medium);
  font-size: 15px;
}

.previewContainerInformationItem > p:last-child {
  color: var(--primary);
  margin-top: 4px;
  font-size: 14px;
}

.previewContainerPrice {
  font-size: 13px;
  font-weight: var(--medium);
  background-color: var(--primary);
  color: var(--white);
  padding: 8px 12px;
  border-radius: 5px;
  /* margin-top: auto; */
}

.divider.preview {
  margin: 20px 0;
  min-height: 1px !important;
}

.trailerPreviewStats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  row-gap: 20px;
}

.trailerPreviewStatItem {
  display: flex;
  align-items: center;
}

.trailerPreviewStatItem > svg {
  stroke-width: 1.5px;
}

.trailerPreviewStatItem > p {
  margin-left: 15px;
  font-size: 13.5px;
}

.previewDescription {
  font-size: 14.5px;
  word-wrap: anywhere;
}

.closePreviewButton {
  font-size: 16px !important;
  color: var(--primary) !important;
}

@keyframes slide_up {
  0% {
    top: 150%;
  }

  100% {
    top: 50%;
  }
}

@media (max-width: 525px) {
  .blur {
    position: fixed;
    top: 0px;
    left: 0px;
    max-width: 100vw;
    max-height: 100vh;
  }

  .previewContainer {
    width: 100vw;
    top: 200px;
    border-radius: 10px 10px 0 0;
    left: 0px;
    transform: translate(0%, 0%);
    height: auto;
    max-height: 90vh;
    overflow-y: scroll;
  }
}

@media (max-width: 390px) {
  .trailerPreviewStats {
    grid-template-columns: 1fr;
  }
}
