.addTrailerFormWrapper {
  width: 860px;
  height: fit-content;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.addTrailerFormWrapper > p:first-child {
  font-weight: var(--medium);
}

.addTrailerFormWrapper > h2 {
  margin: 10px 0 0 0;
  color: var(--primary);
}

.addTrailerFormWrapper > p:nth-child(3) {
  margin-top: 12px;
  /* color: var(--subheader); */
  font-weight: var(--medium);
  max-width: 70%;
  font-size: 15px;
}

.trailerPreviewListStyle {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.trailerPreviewListStyleImage {
  width: 66px;
  min-height: 66px;
  max-height: 66px;
  height: 66px;
  min-width: 66px;
  max-width: 66px;
  border-radius: 50%;
  background-color: var(--primary);
}

.trailerPreviewListStyleImage > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.trailerPreviewListStyleInfo {
  margin-left: 25px;
}

.trailerPreviewListStyleInfo > p:first-child {
  font-weight: var(--medium);
  font-size: 15px;
}

.trailerPreviewListStyleInfo > p:last-child {
  margin-top: 6px;
  font-size: 15px;
  color: var(--primary);
}

.trailerPreviewListStyleInfo.secondary > p:last-child {
  color: var(--subheader);
}

.trailerPreviewListStyleInfo.secondary {
  margin-left: 60px;
}

.trailerPreviewListStyle .bbOutlineRounded {
  margin-left: auto;
}

.additionalInformationContainer {
  margin-top: 35px;
}

.additionalInformationContainer > b {
  font-weight: var(--semibold);
  font-size: 18px;
}

.additionalInformationGrid {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  row-gap: 50px;
}

.additionalInformationGridItem {
  display: flex;
  align-items: top;
}

.additionalInformationIconContainer > svg {
  stroke-width: 1.5px;
}

.additionalInformationIconContainer {
  min-width: fit-content;
  min-height: fit-content;
}

.additionalInformationGridItemContent {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
}

.additionalInformationGridItemContent > p:first-child {
  font-weight: var(--medium);
  font-size: 15px;
  margin-top: -2px;
}

.additionalInformationGridItemContent > p:nth-child(2) {
  font-size: 14px;
  margin-top: 8px;
  color: var(--subheader);
  max-width: 80%;
}

.textButton.primary {
  color: var(--primary);
  margin-top: 20px;
  font-size: 13.5px;
}

.placeTrailer,
.bbOutlineRounded.padding {
  padding: 11px 25px;
  border-radius: 50px;
  background-color: var(--primary);
  margin-top: 60px;
  color: var(--white);
  font-weight: normal;
  border: 1px solid var(--primary);
}

.bbOutlineRounded.padding {
  background-color: var(--white);
  color: var(--primary);
  margin-left: 20px;
}

.blurContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 91;
}

.blur {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
}

.popupContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 310px;
  height: fit-content;
  background-color: var(--white);
  border-radius: 10px;
  box-sizing: border-box;
}

.popupHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 10px 10px 0 0;
  height: 150px;
}

.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 30px 25px 22px 25px;
}

.popupContent > p:first-child {
  font-weight: var(--semibold);
}

.popupContent > p:nth-child(2) {
  margin-top: 8px;
  font-size: 15px;
  text-align: center;
  color: var(--subheader);
}

.bbOutlineRounded.border {
  margin: 30px 0 10px 0;
  padding: 9px 30px;
  font-size: 15px;
  color: var(--subheader);
  border: 1px solid var(--border);
}

.bbOutlineRounded.border:hover {
  border: 1px solid var(--primary);
  background-color: var(--white);
  color: var(--primary);
}

.additionalInformationContainer {
  display: grid;
  grid-template-columns: 2fr 1.6fr;
}

.completeFormTrailerPreview {
  margin-left: 70px;
  flex: 1;
  margin-top: 30px;
}

.trailerPreviewImage {
  background-color: var(--secondary);
  max-height: 160px;
  border-radius: 5px;
  width: 100%;
  object-fit: cover;
}

.completeFormTrailerPreviewInfo {
  margin-top: 10px;
}

.completeFormTrailerPreviewInfo > p:nth-child(1) {
  font-weight: var(--medium);
  font-size: 15px;
}

.completeFormTrailerPreviewInfo > p:nth-child(2) {
  font-size: 13.5px;
  margin-top: 5px;
  color: var(--primary);
}

.completeFormTrailerPreview .divider {
  margin: 20px 0;
}

.primaryBtn.preview {
  font-size: 13.5px;
  font-weight: normal;
  background-color: var(--white);
  border: 1px solid var(--border);
  color: var(--text);
  font-weight: var(--medium);
}

.primaryBtn.preview:hover {
  border: 1px solid var(--text);
}

.buttonRow {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.primaryBtn.preview.filled {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: var(--white);
}

.primaryBtn.preview.border {
  border: 1px solid var(--primary);
  color: var(--primary);
}

@media (max-width: 990px) {
  .addTrailerFormWrapper {
    width: 600px;
  }

  .completeFormTrailerPreview {
    margin-left: 40px;
  }
}

@media (max-width: 660px) {
  .addTrailerFormWrapper {
    width: 90vw;
    min-height: fit-content;
    padding-bottom: 60px;
  }

  .additionalInformationContainer {
    display: flex;
    flex-direction: column-reverse;
  }

  .completeFormTrailerPreview {
    margin-left: 0px;
    margin-bottom: 30px;
  }

  .trailerPreviewImage {
    max-height: 200px;
  }
}

@media (max-width: 380px) {
  .addTrailerFormWrapper {
    padding-bottom: 100px;
  }
}

.completeFormActions {
  position: fixed;
  z-index: 91;
  bottom: 14px;
  right: 20px;
  display: flex;
  align-items: center;
}

.primaryBtn.submit {
  font-size: 14px;
  font-weight: var(--medium);
  border-radius: 50px;
  margin-left: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.primaryBtn.submit.disabledBtn {
  background-color: var(--border) !important;
}

.primaryBtn.submit.border {
  background-color: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.popupContainerContent.succes > b {
  margin: 10px auto 8px auto;
  font-size: 16px;
  color: var(--primary);
}

.popupContainerContent.succes > p {
  text-align: center;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 14.2px;
}

.shareTrailerContainer {
  display: flex;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 20px;
}

.shareTrailerContainer > img {
  width: 60%;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
  margin: auto;
}

.popupContainerContent.succes .primaryBtn.preview.primary {
  padding-right: 50px;
  padding-left: 50px;
  border-radius: 50px;
}
