.recentRentalsContainer {
  position: relative;
  margin-left: 40px;
  width: 100%;
  background-color: var(--white);
  max-width: 100%;
  height: 82vh;
  overflow-y: auto;
}

.recentRentalsHeader.borderless {
  border-bottom: none;
  padding-bottom: 0px;
  justify-content: space-between;
}

.recentRentalsHeader > p {
  font-weight: var(--semibold);
  font-size: 15px;
}

.upcomingRentalsContainer {
  margin-top: 30px;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.rentalTrailersListView {
  display: flex;
  margin-top: 20px;
  position: relative;
  cursor: pointer;
}

.recentRentalsDateSelect {
  display: flex;
  align-items: center;
}

.recentRentalsDateSelect .verticalDivider {
  margin: auto 17px auto 3px;
}

.selectDate {
  color: var(--primary) !important;
  font-size: 22px !important;
}

.mainInfoTagRow {
  display: flex;
  align-items: center;
}

.contentTag,
.contentTagActive {
  font-size: 13px;
  font-weight: var(--medium);
  padding: 8px 16px;
  box-sizing: border-box;
  border: 1px solid var(--border);
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
  min-width: fit-content;
}

.contentTagActive {
  background-color: #f1f1f1;
}

.contentTag:hover {
  background-color: #f1f1f1;
}

.recentRentalsHeader.main {
  position: sticky !important;
  top: 0px !important;
  background-color: var(--white);
  z-index: 2;
  box-sizing: border-box;
  padding-top: 0px !important;
  width: 100%;
  padding-bottom: 15px;
  overflow-x: auto;
  border-bottom: 1px solid var(--border);
}

@media (max-width: 768px) {
  .mainUserInfoContainer {
    position: relative;
    min-width: fit-content;
    margin-top: 75px;
    max-height: fit-content;
  }

  .recentRentalsContainer {
    margin-left: 0px;
    margin-top: 50px;
    /* width: calc(100vw - 40px); */
    max-height: 66vh;
  }

  .contentTag,
  .contentTagActive {
    min-width: fit-content;
  }

  .smallDescription {
    display: none !important;
  }
}

.select.box.recentRentals {
  width: fit-content;
  border-radius: 10px !important;
  padding: 5px 8px;
  width: 60px;
  border-radius: 5px;
  background-color: var(--input);
  border: 1px solid var(--input);
  cursor: pointer;
}

.select.box > select {
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--input);
  font-weight: var(--medium);
  cursor: pointer;
}

.moreContentIndicator {
  position: sticky;
  right: 0px;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white,
    white
  );
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: none;
  align-items: center;
  height: 36px;
  justify-content: right;
  min-width: 30px;
  display: none;
}

.moreContentIndicator > svg {
  font-size: 20px;
}

/* Mobile */

.profileContentHeader {
  display: flex;
  align-items: center;
  margin-top: -30px;
  margin-bottom: 30px;
}

.profileContentHeader > p {
  font-size: 18px;
  color: var(--text);
  font-weight: var(--semibold);
  margin-left: 5px;
}

.backToProfile {
  font-size: 22px !important;
  color: var(--text) !important;
}

@media only screen and (max-width: 970px) and (min-width: 769px) {
  .moreContentIndicator {
    display: flex;
  }
}

@media (max-width: 534px) {
  .moreContentIndicator {
    display: flex;
  }
}

@media (min-width: 768px) {
  .profileContentHeader {
    display: none;
  }
}
