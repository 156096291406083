.aboutReservationContent.settings.transactions {
  overflow-y: auto !important;
}

.aboutReservationContent.settings.transactions > textarea {
  margin-top: 10px;
  border: 1px solid var(--border);
  border-radius: 16px;
  resize: none;
  height: 240px;
  box-sizing: border-box;
  padding: 20px;
  min-height: 240px;
  outline: none;
}

.aboutReservationContent.settings.transactions > textarea:hover {
  cursor: pointer;
  border: 1px solid var(--text);
}

.uploadFilesContainer {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 18px 16px;
  border-radius: 12px;
  border: 1px dashed var(--border);
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;
  cursor: pointer;
  background-color: var(--white);
  margin-bottom: 30px;
}

.uploadFilesContainer:hover {
  border: 1px dashed var(--text);
}

.uploadFilesContainer > p {
  font-size: 14px;
  font-weight: var(--medium);
}

.transactionHistoryItem.restitution {
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: var(--input);
  border-radius: 12px;
  padding: 12px 18px 12px 14px;
}

.transactionHistoryItem.restitution .transactionHistoryIconWrapper {
  background-color: var(--white);
}
