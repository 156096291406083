/* global form */
.form__field {
}

/* form field */
.form__field .header {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
}

.form__field .label {
  font-weight: var(--medium);
  font-size: 14px;
}

.form__field .message,
.checkbox__error {
  font-size: 14px;
  color: var(--error);
  font-weight: var(--regular);
}

.form__field .control {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: var(--borderRadiusMedium);
  border: 1px solid var(--input);
  gap: 0;

  &:focus-within {
    outline: 2px solid var(--primary);
  }
}

.form__field .control__normal {
  border: 1px solid var(--input);
}

.form__field .control__error {
  border: 1px solid var(--error);
  color: var(--error);
}

/* form input */
.form__field .control__error > .input {
  color: var(--error);
}

.form__field .left__icon {
  padding: 12px 0px 12px 12px;
}

.form__field .right__icon {
  padding: 12px 12px 12px 0px;
}

.form__field .input {
  font-size: 14px;
  font-weight: var(--regular);
  padding: 12px 12px;
  width: 100%;
  border-radius: var(--borderRadiusMedium);
}

/* form textarea */
.form__field .control__error > .textarea {
  color: var(--error);
}

.form__field .textarea {
  font-size: 14px;
  font-weight: var(--regular);
  padding: 12px 12px;
  width: 100%;
  border: 0px;
  border-radius: var(--borderRadiusMedium);

  &:focus {
    outline: 2px solid var(--primary);
  }
}

/* checkbox */
.form__checkbox {
  width: 20px;
  height: 20px;
  border-radius: var(--borderRadiusSmall);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1.5px solid var(--input);
  appearance: none;
}

.form__checkbox.checked {
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid var(--primary);
}

.form__checkbox .checkbox__indicator {
  display: flex;
  align-items: center;
  justify-items: center;
}

.checkbox__label {
  font-size: 14px;
  font-weight: var(--regular);
  display: flex;
  align-items: center;
}
