/* label styling */
.input__label {
  font-size: 14px;
  font-weight: var(--medium);
}

/* input field styling */
.input__default .input__field {
  border: 1px solid var(--input);
  border-radius: var(--borderRadiusMedium);
  padding: var(--paddingSm) var(--paddingMd);
  width: 100%;
}

.input__field_icon {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--input);
  border-radius: var(--borderRadiusMedium);
  padding: var(--paddingSm) var(--paddingMd);
  align-items: center;
  overflow: hidden;
}

.input__field_icon .input__field {
  border: none;
  padding: 0;
  border-radius: 0;
}

.input__error {
  border: 1px solid var(--error);
}

.input__error_text {
  color: var(--error);
  font-size: 14px;
  font-weight: var(--regular);
}
