.tableHeaderContainer {
  height: 65px;
  background-color: var(--white);
  border-bottom: 1px solid var(--border);
  box-sizing: border-box;
  padding-top: 30px;
  padding-right: 60px;
  padding-left: 20px;
  /* max-width: 1382px; */
  min-width: 1373px;
  max-width: 1373px;
}

.tableHeaderContainerGrid {
  display: grid;
  grid-template-columns: 0.4fr 0.13fr 0.3fr 0.07fr 0.09fr min-content min-content;
  column-gap: 30px;
  align-items: center;
  min-width: 100%;
  justify-content: space-between;
}

.checkbox {
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableHeaderCategory {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tableHeaderCategory > p {
  font-weight: var(--semibold);
  font-size: 13.5px;
  color: #000;
}

.tableHeaderCategory > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 18px;
  color: var(--subheader);
}
