.zoomBtn {
  position: absolute;
  width: 30px;
  height: 80px;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  background-color: var(--primary);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.map {
  width: 100%;
  height: 88vh;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-top: auto;
  background-color: var(--border);
  position: relative;
  /* border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  border-top: 1px solid var(--border); */
}

.map > div {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.map:focus {
  outline: none !important;
}

.filterButton {
  position: fixed;
  top: 100px;
  left: 20px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  z-index: 99 !important;
  width: 50px;
  height: 50px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.trailerMarkerOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trailerMarkerOverlay > p {
  color: var(--white);
  font-size: 12.5px;
  font-weight: var(--semibold);
}

/* .customMarkerContainer>p {
  font-size: 13.5px;
  font-weight: var(--semibold);
} */

.trailerTypeMarker {
  width: 52px;
  height: 52px;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .activeTrailerCard {
  width: 30%;
} */

.activeTrailerMapContainer {
  position: absolute;
  bottom: 45px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 14px 14px;
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 20px;
  left: 50%;
  transform: translateX(-50%);
  height: fit-content !important;
  max-height: fit-content !important;
  min-height: fit-content;
  z-index: 90;
}

.activeTrailerMapContainer > img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  background-color: var(--secondary);
}

.activeTrailerMapContainer .activeTrailerInfo {
  margin-left: 18px;
  flex: 1;
  min-width: 200px;
}

.activeTrailerMapContainer .activeTrailerInfo > p:nth-child(1) {
  font-weight: var(--semibold);
  font-size: 14px;
}

.activeTrailerMapContainer .activeTrailerInfo > p:nth-child(2) {
  margin-top: 5px;
  color: var(--subheader);
  font-size: 13.5px;
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
}

.roundedBtn {
  margin-left: auto;
  margin-right: 15px;
  border: 1px solid var(--primary);
  color: var(--primary);
  border-radius: 50px;
  font-weight: var(--medium);
  padding: 7.5px 15px;
  font-size: 12.8px;
  background-color: var(--white);
}

.trailerOfferMap .customMarkerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13.5px;
  font-weight: var(--semibold);
  color: var(--white);
  box-sizing: border-box;
  padding: 6px 15px;
  background-color: var(--primary);
  color: var(--white);
  cursor: pointer;
  border-radius: 50px !important;
  z-index: 1;
  position: relative;
}
