.sidebarStatItem {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.sidebarStatItem:nth-child(2) {
  margin-top: 30px;
}

.sidebarStatItem > p {
  margin-left: 12px;
  margin-right: auto;
  font-size: 15px;
  font-weight: var(--medium);
}

.sidebarStatItem > input {
  border: 1px solid var(--border);
  border-radius: 6px;
  height: 44px;
  width: 34%;
  box-sizing: border-box;
  padding-left: 10px;
}
