.sideBarLicenseSelectContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid var(--border);
  border-radius: 6px;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
}

.sideBarLicenseSelectContainer .select {
  position: absolute;
  top: 14px;
  right: 14px;
}

.sideBarLicenseSelectContainer:hover .select {
  border: 1px solid var(--primary);
}

.sideBarLicenseSelectContainer > div:nth-child(2) {
  /* margin-left: 15px; */
  margin-right: auto;
  margin-top: 20px;
}

.sideBarLicenseSelectContainer > div > p:first-child {
  font-size: 13.8px;
  font-weight: var(--semibold);
  color: var(--text);
}

.sideBarLicenseSelectContainer > div > p:last-child {
  font-size: 13.8px;
  margin-top: 2px;
  color: var(--subheader);
}

.select.license,
.select.license.active {
  margin-left: auto;
  margin-right: 0px;
}
