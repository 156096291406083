/* container styles */
.container {
  width: var(--containerWidthXl);
  margin: 0 auto;
  padding: var(--paddingPageGlobal);
}

@media screen and (max-width: 1200px) {
  .container {
    width: var(--containerWidthLarge);
  }
}

@media screen and (max-width: 992px) {
  .container {
    width: var(--containerWidthMedium);
    padding: var(--paddingPageGlobalMedium);
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: var(--containerWidthSmall);
    padding: var(--paddingPageGlobalSmall);
  }
}

/* grid styles */
.grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
}

.grid__item {
  max-width: 100%;
}

/* gap */
.gap--0 {
  gap: 0px;
}

.gap--1 {
  gap: 1px;
}

.gap--2 {
  gap: 2px;
}

.gap--3 {
  gap: 3px;
}

.gap--4 {
  gap: 4px;
}

.gap--5 {
  gap: 5px;
}

.gap--6 {
  gap: 6px;
}

.gap--7 {
  gap: 7px;
}

.gap--8 {
  gap: 8px;
}

.gap--9 {
  gap: 9px;
}

.gap--10 {
  gap: 10px;
}

.gap--11 {
  gap: 11px;
}

.gap--12 {
  gap: 12px;
}

.gap--13 {
  gap: 13px;
}

.gap--14 {
  gap: 14px;
}

.gap--15 {
  gap: 15px;
}

.gap--16 {
  gap: 16px;
}

.gap--17 {
  gap: 17px;
}

.gap--18 {
  gap: 18px;
}

.gap--19 {
  gap: 19px;
}

.gap--20 {
  gap: 20px;
}

.gap--21 {
  gap: 21px;
}

.gap--22 {
  gap: 22px;
}

.gap--23 {
  gap: 23px;
}

.gap--24 {
  gap: 24px;
}

.gap--25 {
  gap: 25px;
}

.gap--26 {
  gap: 26px;
}

.gap--27 {
  gap: 27px;
}

.gap--28 {
  gap: 28px;
}

.gap--29 {
  gap: 29px;
}

.gap--30 {
  gap: 30px;
}

.gap--31 {
  gap: 31px;
}

.gap--32 {
  gap: 32px;
}

.gap--33 {
  gap: 33px;
}

.gap--34 {
  gap: 34px;
}

.gap--35 {
  gap: 35px;
}

.gap--36 {
  gap: 36px;
}

.gap--37 {
  gap: 37px;
}

.gap--38 {
  gap: 38px;
}

.gap--39 {
  gap: 39px;
}

.gap--40 {
  gap: 40px;
}

.gap--41 {
  gap: 41px;
}

.gap--42 {
  gap: 42px;
}

.gap--43 {
  gap: 43px;
}

.gap--44 {
  gap: 44px;
}

.gap--45 {
  gap: 45px;
}

.gap--46 {
  gap: 46px;
}

.gap--47 {
  gap: 47px;
}

.gap--48 {
  gap: 48px;
}

.gap--49 {
  gap: 49px;
}

.gap--50 {
  gap: 50px;
}

.gap--51 {
  gap: 51px;
}

.gap--52 {
  gap: 52px;
}

.gap--53 {
  gap: 53px;
}

.gap--54 {
  gap: 54px;
}

.gap--55 {
  gap: 55px;
}

.gap--56 {
  gap: 56px;
}

.gap--57 {
  gap: 57px;
}

.gap--58 {
  gap: 58px;
}

.gap--59 {
  gap: 59px;
}

.gap--60 {
  gap: 60px;
}

.gap--61 {
  gap: 61px;
}

.gap--62 {
  gap: 62px;
}

.gap--63 {
  gap: 63px;
}

.gap--64 {
  gap: 64px;
}

/* columns */
.columns--1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.columns--2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.columns--3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.columns--4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.columns--5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.columns--6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.columns--7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.columns--8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.columns--9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.columns--10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}
.columns--11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}
.columns--12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.columns--13 {
  grid-template-columns: repeat(13, minmax(0, 1fr));
}
.columns--14 {
  grid-template-columns: repeat(14, minmax(0, 1fr));
}
.columns--15 {
  grid-template-columns: repeat(15, minmax(0, 1fr));
}
.columns--16 {
  grid-template-columns: repeat(16, minmax(0, 1fr));
}
.columns--17 {
  grid-template-columns: repeat(17, minmax(0, 1fr));
}
.columns--18 {
  grid-template-columns: repeat(18, minmax(0, 1fr));
}
.columns--19 {
  grid-template-columns: repeat(19, minmax(0, 1fr));
}
.columns--20 {
  grid-template-columns: repeat(20, minmax(0, 1fr));
}
.columns--21 {
  grid-template-columns: repeat(21, minmax(0, 1fr));
}
.columns--22 {
  grid-template-columns: repeat(22, minmax(0, 1fr));
}
.columns--23 {
  grid-template-columns: repeat(23, minmax(0, 1fr));
}
.columns--24 {
  grid-template-columns: repeat(24, minmax(0, 1fr));
}

@media screen and (min-width: 576px) {
  .sm__columns--1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm__columns--2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm__columns--3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm__columns--4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm__columns--5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm__columns--6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .sm__columns--7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .sm__columns--8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .sm__columns--9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .sm__columns--10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .sm__columns--11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .sm__columns--12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .sm__columns--13 {
    grid-template-columns: repeat(13, minmax(0, 1fr));
  }
  .sm__columns--14 {
    grid-template-columns: repeat(14, minmax(0, 1fr));
  }
  .sm__columns--15 {
    grid-template-columns: repeat(15, minmax(0, 1fr));
  }
  .sm__columns--16 {
    grid-template-columns: repeat(16, minmax(0, 1fr));
  }
  .sm__columns--17 {
    grid-template-columns: repeat(17, minmax(0, 1fr));
  }
  .sm__columns--18 {
    grid-template-columns: repeat(18, minmax(0, 1fr));
  }
  .sm__columns--19 {
    grid-template-columns: repeat(19, minmax(0, 1fr));
  }
  .sm__columns--20 {
    grid-template-columns: repeat(20, minmax(0, 1fr));
  }
  .sm__columns--21 {
    grid-template-columns: repeat(21, minmax(0, 1fr));
  }
  .sm__columns--22 {
    grid-template-columns: repeat(22, minmax(0, 1fr));
  }
  .sm__columns--23 {
    grid-template-columns: repeat(23, minmax(0, 1fr));
  }
  .sm__columns--24 {
    grid-template-columns: repeat(24, minmax(0, 1fr));
  }
}

@media screen and (min-width: 768px) {
  .md__columns--1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md__columns--2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md__columns--3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md__columns--4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md__columns--5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md__columns--6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md__columns--7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .md__columns--8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .md__columns--9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .md__columns--10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .md__columns--11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .md__columns--12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .md__columns--13 {
    grid-template-columns: repeat(13, minmax(0, 1fr));
  }
  .md__columns--14 {
    grid-template-columns: repeat(14, minmax(0, 1fr));
  }
  .md__columns--15 {
    grid-template-columns: repeat(15, minmax(0, 1fr));
  }
  .md__columns--16 {
    grid-template-columns: repeat(16, minmax(0, 1fr));
  }
  .md__columns--17 {
    grid-template-columns: repeat(17, minmax(0, 1fr));
  }
  .md__columns--18 {
    grid-template-columns: repeat(18, minmax(0, 1fr));
  }
  .md__columns--19 {
    grid-template-columns: repeat(19, minmax(0, 1fr));
  }
  .md__columns--20 {
    grid-template-columns: repeat(20, minmax(0, 1fr));
  }
  .md__columns--21 {
    grid-template-columns: repeat(21, minmax(0, 1fr));
  }
  .md__columns--22 {
    grid-template-columns: repeat(22, minmax(0, 1fr));
  }
  .md__columns--23 {
    grid-template-columns: repeat(23, minmax(0, 1fr));
  }
  .md__columns--24 {
    grid-template-columns: repeat(24, minmax(0, 1fr));
  }
}

@media screen and (min-width: 992px) {
  .lg__columns--1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .lg__columns--2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg__columns--3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg__columns--4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg__columns--5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg__columns--6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .lg__columns--7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .lg__columns--8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .lg__columns--9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .lg__columns--10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .lg__columns--11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .lg__columns--12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .lg__columns--13 {
    grid-template-columns: repeat(13, minmax(0, 1fr));
  }
  .lg__columns--14 {
    grid-template-columns: repeat(14, minmax(0, 1fr));
  }
  .lg__columns--15 {
    grid-template-columns: repeat(15, minmax(0, 1fr));
  }
  .md__columns--16 {
    grid-template-columns: repeat(16, minmax(0, 1fr));
  }
  .lg__columns--17 {
    grid-template-columns: repeat(17, minmax(0, 1fr));
  }
  .lg__columns--18 {
    grid-template-columns: repeat(18, minmax(0, 1fr));
  }
  .lg__columns--19 {
    grid-template-columns: repeat(19, minmax(0, 1fr));
  }
  .lg__columns--20 {
    grid-template-columns: repeat(20, minmax(0, 1fr));
  }
  .lg__columns--21 {
    grid-template-columns: repeat(21, minmax(0, 1fr));
  }
  .lg__columns--22 {
    grid-template-columns: repeat(22, minmax(0, 1fr));
  }
  .lg__columns--23 {
    grid-template-columns: repeat(23, minmax(0, 1fr));
  }
  .lg__columns--24 {
    grid-template-columns: repeat(24, minmax(0, 1fr));
  }
}

/* align items */
.jitem--start {
  justify-items: start;
}

.jitem--end {
  justify-items: end;
}

.jitem--center {
  justify-items: center;
}

.jitem--stretch {
  justify-items: stretch;
}

.aitem--start {
  align-items: start;
}

.aitemf--start {
  align-items: flex-start;
}

.aitem--end {
  align-items: end;
}

.aitemf--end {
  align-items: flex-end;
}

.aitem--center {
  align-items: center;
}

.aitem--stretch {
  align-items: stretch;
}

.aitem--baseline {
  align-items: baseline;
}

.jcontent--start {
  justify-content: start;
}

.jcontentf--start {
  justify-content: flex-start;
}

.jcontent--end {
  justify-content: end;
}

.jcontentf--end {
  justify-content: flex-end;
}

.jcontent--center {
  justify-content: center;
}

.jcontent--stretch {
  justify-content: stretch;
}

.jcontent--space_around {
  justify-content: space-around;
}

.jcontent--space_between {
  justify-content: space-between;
}

.jcontent--space_evenly {
  justify-content: space-evenly;
}

.acontent--start {
  align-content: start;
}

.acontentf--start {
  align-content: flex-start;
}

.acontent--end {
  align-content: end;
}

.acontentf--end {
  align-content: flex-end;
}

.acontent--center {
  align-content: center;
}

.acontent--stretch {
  align-content: stretch;
}

.acontent--space_around {
  align-content: space-around;
}

.acontent--space_between {
  align-content: space-between;
}

.acontent--space_evenly {
  align-content: space-evenly;
}

/* single item related code */
.cspan--1 {
  grid-column-start: span 1;
}
.cspan--2 {
  grid-column-start: span 2;
}
.cspan--3 {
  grid-column-start: span 3;
}
.cspan--4 {
  grid-column-start: span 4;
}
.cspan--5 {
  grid-column-start: span 5;
}
.cspan--6 {
  grid-column-start: span 6;
}
.cspan--7 {
  grid-column-start: span 7;
}
.cspan--8 {
  grid-column-start: span 8;
}
.cspan--9 {
  grid-column-start: span 9;
}
.cspan--10 {
  grid-column-start: span 10;
}
.cspan--11 {
  grid-column-start: span 11;
}
.cspan--12 {
  grid-column-start: span 12;
}
.cspan--13 {
  grid-column-start: span 13;
}
.cspan--14 {
  grid-column-start: span 14;
}
.cspan--15 {
  grid-column-start: span 15;
}
.cspan--16 {
  grid-column-start: span 16;
}
.cspan--17 {
  grid-column-start: span 17;
}
.cspan--18 {
  grid-column-start: span 18;
}
.cspan--19 {
  grid-column-start: span 19;
}
.cspan--20 {
  grid-column-start: span 20;
}
.cspan--21 {
  grid-column-start: span 21;
}
.cspan--22 {
  grid-column-start: span 22;
}
.cspan--23 {
  grid-column-start: span 23;
}
.cspan--24 {
  grid-column-start: span 24;
}

.rspan--1 {
  grid-row-start: span 1;
}
.rspan--2 {
  grid-row-start: span 2;
}
.rspan--3 {
  grid-row-start: span 3;
}
.rspan--4 {
  grid-row-start: span 4;
}

@media screen and (min-width: 576px) {
  .sm__cspan--1 {
    grid-column-start: span 1;
  }
  .sm__cspan--2 {
    grid-column-start: span 2;
  }
  .sm__cspan--3 {
    grid-column-start: span 3;
  }
  .sm__cspan--4 {
    grid-column-start: span 4;
  }
  .sm__cspan--5 {
    grid-column-start: span 5;
  }
  .sm__cspan--6 {
    grid-column-start: span 6;
  }
  .sm__cspan--7 {
    grid-column-start: span 7;
  }
  .sm__cspan--8 {
    grid-column-start: span 8;
  }
  .sm__cspan--9 {
    grid-column-start: span 9;
  }
  .sm__cspan--10 {
    grid-column-start: span 10;
  }
  .sm__cspan--11 {
    grid-column-start: span 11;
  }
  .sm__cspan--12 {
    grid-column-start: span 12;
  }
  .sm__cspan--13 {
    grid-column-start: span 13;
  }
  .sm__cspan--14 {
    grid-column-start: span 14;
  }
  .sm__cspan--15 {
    grid-column-start: span 15;
  }
  .sm__cspan--16 {
    grid-column-start: span 16;
  }
  .sm__cspan--17 {
    grid-column-start: span 17;
  }
  .sm__cspan--18 {
    grid-column-start: span 18;
  }
  .sm__cspan--19 {
    grid-column-start: span 19;
  }
  .sm__cspan--20 {
    grid-column-start: span 20;
  }
  .sm__cspan--21 {
    grid-column-start: span 21;
  }
  .sm__cspan--22 {
    grid-column-start: span 22;
  }
  .sm__cspan--23 {
    grid-column-start: span 23;
  }
  .sm__cspan--24 {
    grid-column-start: span 24;
  }

  .sm__rspan--1 {
    grid-row-start: span 1;
  }
  .sm__rspan--2 {
    grid-row-start: span 2;
  }
  .sm__rspan--3 {
    grid-row-start: span 3;
  }
  .sm__rspan--4 {
    grid-row-start: span 4;
  }
}

@media screen and (min-width: 768px) {
  .md__cspan--1 {
    grid-column-start: span 1;
  }
  .md__cspan--2 {
    grid-column-start: span 2;
  }
  .md__cspan--3 {
    grid-column-start: span 3;
  }
  .md__cspan--4 {
    grid-column-start: span 4;
  }
  .md__cspan--5 {
    grid-column-start: span 5;
  }
  .md__cspan--6 {
    grid-column-start: span 6;
  }
  .md__cspan--7 {
    grid-column-start: span 7;
  }
  .md__cspan--8 {
    grid-column-start: span 8;
  }
  .md__cspan--9 {
    grid-column-start: span 9;
  }
  .md__cspan--10 {
    grid-column-start: span 10;
  }
  .md__cspan--11 {
    grid-column-start: span 11;
  }
  .md__cspan--12 {
    grid-column-start: span 12;
  }
  .md__cspan--13 {
    grid-column-start: span 13;
  }
  .md__cspan--14 {
    grid-column-start: span 14;
  }
  .md__cspan--15 {
    grid-column-start: span 15;
  }
  .md__cspan--16 {
    grid-column-start: span 16;
  }
  .md__cspan--17 {
    grid-column-start: span 17;
  }
  .md__cspan--18 {
    grid-column-start: span 18;
  }
  .md__cspan--19 {
    grid-column-start: span 19;
  }
  .md__cspan--20 {
    grid-column-start: span 20;
  }
  .md__cspan--21 {
    grid-column-start: span 21;
  }
  .md__cspan--22 {
    grid-column-start: span 22;
  }
  .md__cspan--23 {
    grid-column-start: span 23;
  }
  .md__cspan--24 {
    grid-column-start: span 24;
  }

  .md__rspan--1 {
    grid-row-start: span 1;
  }
  .md__rspan--2 {
    grid-row-start: span 2;
  }
  .md__rspan--3 {
    grid-row-start: span 3;
  }
  .md__rspan--4 {
    grid-row-start: span 4;
  }
}

@media screen and (min-width: 992px) {
  .lg__cspan--1 {
    grid-column-start: span 1;
  }
  .lg__cspan--2 {
    grid-column-start: span 2;
  }
  .lg__cspan--3 {
    grid-column-start: span 3;
  }
  .lg__cspan--4 {
    grid-column-start: span 4;
  }
  .lg__cspan--5 {
    grid-column-start: span 5;
  }
  .lg__cspan--6 {
    grid-column-start: span 6;
  }
  .lg__cspan--7 {
    grid-column-start: span 7;
  }
  .lg__cspan--8 {
    grid-column-start: span 8;
  }
  .lg__cspan--9 {
    grid-column-start: span 9;
  }
  .lg__cspan--10 {
    grid-column-start: span 10;
  }
  .lg__cspan--11 {
    grid-column-start: span 11;
  }
  .lg__cspan--12 {
    grid-column-start: span 12;
  }
  .lg__cspan--13 {
    grid-column-start: span 13;
  }
  .lg__cspan--14 {
    grid-column-start: span 14;
  }
  .lg__cspan--15 {
    grid-column-start: span 15;
  }
  .lg__cspan--16 {
    grid-column-start: span 16;
  }
  .lg__cspan--17 {
    grid-column-start: span 17;
  }
  .lg__cspan--18 {
    grid-column-start: span 18;
  }
  .lg__cspan--19 {
    grid-column-start: span 19;
  }
  .lg__cspan--20 {
    grid-column-start: span 20;
  }
  .lg__cspan--21 {
    grid-column-start: span 21;
  }
  .lg__cspan--22 {
    grid-column-start: span 22;
  }
  .lg__cspan--23 {
    grid-column-start: span 23;
  }
  .lg__cspan--24 {
    grid-column-start: span 24;
  }

  .lg__rspan--1 {
    grid-row-start: span 1;
  }
  .lg__rspan--2 {
    grid-row-start: span 2;
  }
  .lg__rspan--3 {
    grid-row-start: span 3;
  }
  .lg__rspan--4 {
    grid-row-start: span 4;
  }
}

/* align item self */
.aself--auto {
  align-self: auto;
}

.aself--start {
  align-self: start;
}

.aselff--start {
  align-self: flex-start;
}

.aself--end {
  align-self: end;
}

.aselff--end {
  align-self: flex-end;
}

.aself--center {
  align-self: center;
}

.aself--stretch {
  align-self: stretch;
}

.aself--baseline {
  align-self: baseline;
}

.jself--start {
  justify-self: start;
}

.jself--end {
  justify-self: end;
}

.jself--center {
  justify-self: center;
}

.jself--stretch {
  justify-self: stretch;
}

/* flex layout */
.flex {
  display: flex;
}

.flex--row {
  flex-direction: row;
}

.flex--row-reverse {
  flex-direction: row-reverse;
}

.flex--col {
  flex-direction: column;
}

.flex--col-reverse {
  flex-direction: column-reverse;
}

.flex--nowrap {
  flex-wrap: nowrap;
}

.flex--wrap {
  flex-wrap: wrap;
}

.flex--wrap-reverse {
  flex-wrap: wrap-reverse;
}

.order--1 {
  order: 1;
}
.order--2 {
  order: 2;
}
.order--3 {
  order: 3;
}
.order--4 {
  order: 4;
}
.order--5 {
  order: 5;
}
.order--6 {
  order: 6;
}
.order--7 {
  order: 7;
}
.order--8 {
  order: 8;
}
.order--9 {
  order: 9;
}
.order--10 {
  order: 10;
}
.order--11 {
  order: 11;
}
.order--12 {
  order: 12;
}
.order--13 {
  order: 13;
}
.order--14 {
  order: 14;
}
.order--15 {
  order: 15;
}
.order--16 {
  order: 16;
}
.order--17 {
  order: 17;
}
.order--18 {
  order: 18;
}
.order--19 {
  order: 19;
}
.order--20 {
  order: 20;
}
.order--21 {
  order: 21;
}
.order--22 {
  order: 22;
}
.order--23 {
  order: 23;
}
.order--24 {
  order: 24;
}

.grow--1 {
  flex-grow: 1;
}
.grow--2 {
  flex-grow: 2;
}
.grow--3 {
  flex-grow: 3;
}
.grow--4 {
  flex-grow: 4;
}
.grow--5 {
  flex-grow: 5;
}
.grow--6 {
  flex-grow: 6;
}
.grow--7 {
  flex-grow: 7;
}
.grow--8 {
  flex-grow: 8;
}
.grow--9 {
  flex-grow: 9;
}
.grow--10 {
  flex-grow: 10;
}
.grow--11 {
  flex-grow: 11;
}
.grow--12 {
  flex-grow: 12;
}
.grow--13 {
  flex-grow: 13;
}
.grow--14 {
  flex-grow: 14;
}
.grow--15 {
  flex-grow: 15;
}
.grow--16 {
  flex-grow: 16;
}
.grow--17 {
  flex-grow: 17;
}
.grow--18 {
  flex-grow: 18;
}
.grow--19 {
  flex-grow: 19;
}
.grow--20 {
  flex-grow: 20;
}
.grow--21 {
  flex-grow: 21;
}
.grow--22 {
  flex-grow: 22;
}
.grow--23 {
  flex-grow: 23;
}
.grow--24 {
  flex-grow: 24;
}

.shrink--1 {
  flex-shrink: 1;
}
.shrink--2 {
  flex-shrink: 2;
}
.shrink--3 {
  flex-shrink: 3;
}
.shrink--4 {
  flex-shrink: 4;
}
.shrink--5 {
  flex-shrink: 5;
}
.shrink--6 {
  flex-shrink: 6;
}
.shrink--7 {
  flex-shrink: 7;
}
.shrink--8 {
  flex-shrink: 8;
}
.shrink--9 {
  flex-shrink: 9;
}
.shrink--10 {
  flex-shrink: 10;
}
.shrink--11 {
  flex-shrink: 11;
}
.shrink--12 {
  flex-shrink: 12;
}
.shrink--13 {
  flex-shrink: 13;
}
.shrink--14 {
  flex-shrink: 14;
}
.shrink--15 {
  flex-shrink: 15;
}
.shrink--16 {
  flex-shrink: 16;
}
.shrink--17 {
  flex-shrink: 17;
}
.shrink--18 {
  flex-shrink: 18;
}
.shrink--19 {
  flex-shrink: 19;
}
.shrink--20 {
  flex-shrink: 20;
}
.shrink--21 {
  flex-shrink: 21;
}
.shrink--22 {
  flex-shrink: 22;
}
.shrink--23 {
  flex-shrink: 23;
}
.shrink--24 {
  flex-shrink: 24;
}

@media screen and (min-width: 576px) {
  .sm__flex--row {
    flex-direction: row;
  }

  .sm__flex--row-reverse {
    flex-direction: row-reverse;
  }

  .sm__flex--col {
    flex-direction: column;
  }

  .sm__flex--col-reverse {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 768px) {
  .md__flex--row {
    flex-direction: row;
  }

  .md__flex--row-reverse {
    flex-direction: row-reverse;
  }

  .md__flex--col {
    flex-direction: column;
  }

  .md__flex--col-reverse {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 992px) {
  .lg__flex--row {
    flex-direction: row;
  }

  .lg__flex--row-reverse {
    flex-direction: row-reverse;
  }

  .lg__flex--col {
    flex-direction: column;
  }

  .lg__flex--col-reverse {
    flex-direction: column-reverse;
  }
}

/* hide component */
.hidden {
  display: none;
}
