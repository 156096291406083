.mobileOfferFiltersContainer > h3 {
  font-size: 26px;
  font-weight: 700;
  color: #1a1b24;
}

.mobileOfferFiltersContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 91;
  background-color: var(--white);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 33px 20px;
  display: flex;
  flex-direction: column;
  animation: filter_slide 0.4s ease;
  overflow-y: auto;
}

.mobileOfferFiltersContainer > span:nth-child(2) {
  margin-top: 35px;
}

.mobileOfferFiltersContainer > span {
  font-size: 15px;
  color: #1a1b24;
}

.divider.filters {
  margin-top: 35px;
  margin-bottom: 30px;
  min-height: 1px;
}

.mobileOfferFiltersContainer > p {
  font-size: 14px;
  margin-top: 6px;
  color: #747474;
}

.mobileOfferFiltersContainer > p > u {
  color: var(--text);
  cursor: pointer;
  font-weight: var(--medium);
}

.filterSelectContainer {
  display: flex;
  align-items: center;
  background-color: #f2f3f6;
  border-radius: 14px;
  height: 50px;
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 30px;
}

.filterSelectContainer > p {
  font-size: 13.5px;
  font-weight: var(--semibold);
  color: var(--text);
  margin-left: 12px;
  margin-right: auto;
}

.offerFilterItem {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.offerFilterItem:nth-child(11) {
  margin-top: 30px;
}

.offerFilterItem > p {
  margin-left: 10px;
  margin-right: auto;
  font-size: 14px;
  font-weight: var(--medium);
}

.offerFilterItem > svg {
  font-size: 22px;
  stroke-width: 1.4;
}

.offerFilterItem .checkbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.offerFilterItem > input {
  max-width: 110px;
  height: 42px;
  margin-left: 2px;
  border-radius: 10px;
  border: 1px solid var(--border);
  flex: 1;
  box-sizing: border-box;
  padding-left: 12px;
}

.dimensionFilterAnimationContainer {
  width: 100%;
  min-height: 300px;
  border-radius: 20px;
  background-color: var(--input);
  margin-top: 30px;
}

@keyframes filter_slide {
  0% {
    top: 100vh;
  }

  100% {
    top: 0vh;
  }
}

@media (min-width: 767px) {
  .mobileOfferFiltersContainer {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 430px;
    height: 78vh;
    border-radius: 20px;
    padding: 40px 35px;
    animation: filter_slide_desktop 0.3s ease;
  }

  .closeBtn {
    top: 20px;
    right: 20px;
  }
}

@keyframes filter_slide_desktop {
  0% {
    top: 100vh;
  }

  100% {
    top: 50%;
  }
}
