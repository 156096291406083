.sidebarSettingsContentContainer.location > p {
  margin-top: 30px;
  font-weight: var(--medium);
}

.locationRadiusInfoContainer {
  display: flex;
  align-items: center;
}

.locationRadiusImage {
  width: fit-content;
  height: fit-content;
  display: flex;
}

.locationRadiusImage img {
  width: 88px;
  height: 88px;
}

.radiusInfoContent {
  margin-left: 20px;
}

.radiusInfoContent > p:first-child {
  font-size: 14.5px;
  font-weight: var(--semibold);
}

.radiusInfoContent > p:last-child {
  font-size: 14px;
  color: var(--subheader);
  margin-top: 3px;
}

.exactLocationSidebarGrid {
  border: 1px solid var(--border);
  border-radius: 5px;
  margin-top: 30px;
}

.locationSideBarGridItem {
  height: fit-content;
  box-sizing: border-box;
  padding: 8px 10px;
}

.locationSideBarGridItem:nth-child(1),
.locationSideBarGridItem:nth-child(2),
.locationSideBarGridItem:nth-child(3) {
  font-size: 11.5px;
  width: 100%;
  border-bottom: 1px solid var(--border);
}

.locationSideBarGridItem:nth-child(4) {
  font-size: 11.5px;
  width: 100%;
}

.locationSideBarGridItem > span,
.locationSideBarGridItem > div > span {
  font-weight: var(--medium);
  color: var(--subheader);
}

.locationSideBarGridItem > div {
  padding: 8px 10px;
  font-size: 11.5px;
  max-width: 88%;
  padding-left: 10px;
}

.locationSideBarGridItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.locationSideBarGridItem > input,
.locationSideBarGridItem > div > input {
  flex: 1;
}

.locationInput.sidebar {
  box-shadow: none;
  width: 98%;
  border-radius: 5px;
  max-width: 98%;
  height: 100%;
  top: 0px;
  left: 0px;
  transform: translateX(0px);
  font-weight: normal;
  padding: 0px 8px;
}

.searchbox.price.location {
  position: relative;
  padding-right: 5px;
}

.locationInput.sidebar::placeholder {
  font-weight: normal;
}
