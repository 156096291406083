.tableHeaderContainerGrid.reservations {
  display: grid;
  grid-template-columns: 0.34fr 0.2fr 0.06fr 0.17fr 0.1fr 0.1fr 0.08fr 0.06fr;
}

.tableHeaderContainerGrid.reservations .tableHeaderCategory {
  cursor: default;
}

.tableHeaderContainerGrid.reservations .tableHeaderCategory:nth-child(1) p {
  margin-left: 35px;
}
