.trailerTypeInformationWrapper {
  box-sizing: border-box;
  padding: 50px 20vw;
}

.trailerTypeInfoHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trailerTypeInfoHeader > b {
  font-weight: var(--bold);
  font-size: 34px;
  color: var(--text);
  max-width: 500px;
  text-align: center;
}

.trailerTypeInfoHeader > p {
  font-size: 18px;
  margin-top: 22px;
  color: var(--text);
}

.trailerTypeGrid {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;
  row-gap: 40px;
}

.trailerTypeGridItem {
  box-sizing: border-box;
  /* padding: 40px 8px; */
  position: relative;
  display: flex;
  flex-direction: column;
}

.trailerTypeGridItem > b {
  font-weight: var(--semibold);
  color: var(--text);
  margin-top: 22px;
  font-size: 15px;
}

.trailerTypeGridItem > p {
  max-width: 500px;
  margin-top: 8px;
  font-size: 14.5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text);
}

.trailerTypeInfoImageWrapper {
  width: 100%;
  height: 290px;
  background-color: var(--input);
  border-radius: 12px;
  display: flex;
  position: relative;
  min-width: 300px;
}

.trailerTypeInfoImageWrapper > img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 280px;
  object-fit: contain;
  left: 20px;
}

/* Popup */

.trailerTypeInfoPopup {
  width: 1040px;
  height: 520px;
  border-radius: 12px;
  background-color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: slide_up 0.4s ease;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 40px;
}

.trailerPopupModelContainer {
  width: 450px;
  min-width: 450px;
  height: 100%;
  border-radius: 8px;
  background-color: var(--input);
  position: relative;
}

.trailerPopupModelContainer > img {
  width: 115%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0 !important;
}

.trailerPopupModelInformation {
  margin-left: auto;
  margin-right: 30px;
  max-width: 420px;
}

.trailerPopupModelInformation > b {
  font-weight: var(--semibold);
  font-size: 18px;
  color: var(--text);
}

.trailerPopupModelInformation > p {
  margin-top: 12px;
  color: var(--text);
}

.trailerTypeInfoPopup .closeBtn {
  z-index: 99;
}

@media (max-width: 1150px) {
  .trailerTypeInfoPopup {
    flex-direction: column;
    width: fit-content;
    border-radius: 20px;
    height: fit-content;
  }

  .trailerPopupModelContainer {
    height: 300px;
    min-height: 300px;
    border-radius: 15px;
  }

  .trailerPopupModelInformation {
    margin-left: 0px;
    margin-top: 20px;
    min-height: fit-content;
  }
}

@media (max-width: 767px) {
  .trailerTypeInfoPopup {
    width: 100vw;
    border-radius: 0px;
    height: 100vh;
    top: 0px;
    left: 0px;
    transform: unset;
    padding-left: 24px;
    padding-right: 24px;
    animation: unset;
  }

  .trailerPopupModelContainer {
    width: 100%;
    margin-top: 30px;
  }

  .trailerPopupModelInformation {
    width: 100%;
    max-width: 100%;
    margin: 0px;
    margin-top: 30px;
  }
}
