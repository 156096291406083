.statsInputGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2%;
}

.statInputItem {
  width: fit-content;
  height: 50px;
  border-radius: 5px;
  border: 1px solid var(--border);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 25px 14px;
  background-color: white;
  margin: 10px 0;
}

.formStepContainer > p > u {
  font-size: 14px;
  color: var(--text);
  font-weight: var(--semibold);
  cursor: pointer;
}

input {
  font-size: 14px;
}

.statInputItem > input {
  flex: 1;
  border: none;
  outline: none;
  height: 96%;
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 15px;
  color: black;
}

.statInputItem > input::placeholder {
  color: black;
  font-size: 15px;
}

.statsInputItem {
  display: flex;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid var(--border);
  position: relative;
}

.statsInputItemFlexbox {
  display: flex;
  align-items: center;
}

.statsInputItem > svg {
  min-width: 19px;
}

.statsInputItem > p,
.statsInputItemFlexbox > p {
  margin-left: 20px;
  font-weight: var(--medium);
  font-size: 15.8px;
}

.statInput {
  margin-left: auto;
  padding: 12px 10px;
  border-radius: 5px;
  border: 1px solid var(--border);
  outline: none;
}

.statInput::-webkit-outer-spin-button,
.statInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.statInput {
  -moz-appearance: textfield;
}

.statInput:focus,
.statInput:active {
  border: 1.8px solid var(--text);
  color: black;
}

.statsInputItemFlexbox img {
  stroke-width: 5px !important;
}

/* Responsive layout */

@media (min-width: 1355px) {
  .maxWidthHeader {
    max-width: 76%;
  }
}

@media (max-width: 500px) {
  .statsInputItem.column {
    flex-direction: column;
    align-items: start;
  }

  .statsInputItem.column:last-child {
    border-bottom: none;
  }

  .statsInputItem.column > input {
    margin-left: 0px;
    width: 92%;
    margin-top: 25px;
  }
}

/* info box */

.popupContainerInfoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;
  row-gap: 32px;
  max-height: 400px;
  overflow-y: auto;
}

.popupContainerGridItem {
  display: flex;
  flex-direction: column;
}

.popupContainer.infoBox.stats {
  width: 700px;
}

.popupContainerGridItem > b {
  font-size: 14.5px;
  font-weight: var(--semibold);
  color: var(--color);
  margin-top: 15px;
}

.popupContainerGridItem > p {
  margin-top: 6px;
  font-size: 14px;
}

@media (max-width: 750px) {
  .popupContainer.infoBox.stats {
    width: 100vw;
    top: 0px;
    left: 0px;
    transform: none;
    height: 100vh;
    border-radius: 0px;
  }

  .popupContainerContent.stats {
    max-height: fit-content;
    height: fit-content;
    min-height: fit-content;
    overflow-y: hidden;
    padding: 0px;
  }

  .popupContainerInfoGrid {
    grid-template-columns: 1fr;
    margin-top: 20px;
    height: 850px;
    min-height: 850px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .popupContainerGridItem {
    margin-top: 20px;
  }
}
