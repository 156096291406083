.heroContainer {
  width: 100%;
  height: 820px;
  min-height: 820px;
  background-color: var(--secondary);
  box-sizing: border-box;
  padding: 100px 8%;
  background-image: url(../../data/images/BuurBak_Duurzaam-Delen_Aanhanger02972022_1920_1.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  justify-content: center;
  align-content: center;
}

.heroOverlay {
  width: 100%;
  height: 100%;
  background-color: var(--darkBackground);
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.45;
  z-index: 1;
}

.heroContainer > h1 {
  margin: 100px auto 0 auto;
  font-weight: var(--bold);
  color: var(--white);
  font-size: 50px;
  max-width: 50%;
  text-align: center;
  z-index: 2;
  position: relative;
}

.heroIncluded {
  z-index: 2;
  position: relative;
  margin-top: 30px;
}

.heroIncludedItem {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: var(--white);
}

.heroIncludedItem > p {
  margin-left: 20px;
  font-weight: var(--semibold);
}

.heroCta {
  align-items: center;
  margin: 60px auto 0 auto;
  max-width: 97%;
  display: grid;
  grid-template-columns: 29.5% 29.5% 29.5% 11.5%;
  z-index: 2;
  position: relative;
  column-gap: 15px;
}

.heroCtaItem > span {
  font-weight: var(--bold);
  color: var(--white);
  font-size: 14px;
}

.inputContainer {
  margin-top: 10px;
  background-color: var(--white);
  width: 92%;
  height: 48px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputContainer > input,
.inputContainer > select {
  height: 100%;
  padding: 0px;
  border: none;
  outline: none;
  flex: 0.96;
  font-size: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 14px;
  margin: 0px;
  background-color: var(--background);
  font-weight: var(--semibold);
}

.inputContainer > input::placeholder {
  color: var(--primary);
  font-weight: 500;
  font-weight: var(--semibold);
  font-size: 14px;
}

.inputContainerIcon {
  width: 64px;
  height: 49px;
  background-color: var(--primary);
  border-radius: 0 3px 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 26px;
}

.agendaIcon {
  color: white;
  margin-left: -15px;
}

.ctaAction {
  height: 42px;
  margin-top: 31px;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
  padding: 0 20px;
  min-width: fit-content;
}

.customSelect {
  width: 90%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
  color: var(--primary);
  cursor: pointer;
  position: relative;
}

.customSelect > p {
  font-size: 14px;
  font-weight: var(--medium);
}

.suggestionsContainer {
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: none;
  box-sizing: border-box;
  padding: 15px;
  max-height: 220px;
  position: absolute;
  top: 50px;
  background-color: var(--white);
  width: 78%;
  max-height: 220px;
  overflow: auto;
}

.trailerTypesInputContainer {
  width: 100%;
}

.suggestionResults {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #b3b3b3;
}

.suggestionResults:hover {
  background-color: var(--border);
  cursor: pointer;
}

.suggestionResults:hover p {
  font-weight: var(--bold);
}

.trailerFilterContainer {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.suggestionResults > p {
  margin-left: 18px;
  font-size: 12.7px;
  font-weight: var(--semibold);
  color: black;
}

.trailerFilterContainer > p {
  color: var(--primary);
  margin-left: 10px;
  font-size: 14px;
  font-weight: var(--semibold);
}

.calendar {
  background-color: white;
}

@media only screen and (max-width: 1200px) {
  .heroContainer > h1 {
    font-size: 40px;
  }

  .heroCta {
    grid-template-columns: 50% 50%;
    row-gap: 20px;
  }
}

@media only screen and (max-width: 811px) {
  .heroContainer {
    padding-top: 1px;
  }

  .heroContainer > h1 {
    font-size: 30px;
    margin-top: 40px;
  }

  .heroCta {
    grid-template-columns: 100%;
    row-gap: 20px;
  }

  .heroIncludedItem {
    font-size: 16px;
  }

  .ctaAction {
    width: 92%;
  }
}

@media only screen and (max-width: 600px) {
  .heroContainer > h1 {
    max-width: 60%;
  }
}

@media only screen and (max-width: 455px) {
  .heroContainer > h1 {
    font-size: 25px;
    max-width: 70%;
  }

  .heroIncluded {
    margin-left: 0px;
  }

  .heroIncludedItem > p {
    margin-left: 10px;
  }
}
