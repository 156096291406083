.offerCategoriesSlider {
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 25px;
  width: 100vw;
  overflow-x: auto;
  margin-top: 34px;
}

.mobOfferCategory {
  min-width: fit-content;
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 50px;
  box-sizing: border-box;
  padding: 9px 16px 9px 16px;
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.mobOfferCategory.active {
  border: 1px solid var(--primary);
}

.mobOfferCategory > svg {
  max-width: 28px;
  stroke-width: 1.5;
}

.mobOfferCategory > p {
  font-weight: var(--medium);
  margin-left: 10px;
  font-size: 13px;
}

.mobOfferCategory.active > p {
  color: var(--primary);
}

.mobOfferCategory.active > svg {
  stroke: var(--primary);
}

.mobOfferCategory:nth-child(16) > svg {
  max-width: 18px;
  margin-right: 0px;
}

.mobOfferCategory:nth-child(10) > svg {
  max-width: 22px;
}

@media (min-width: 480px) {
  .offerCategoriesSlider {
    position: relative;
    padding-left: 0px;
    margin-top: -16px;
    width: 100%;
    position: relative;
    margin-bottom: 15px;
  }

  .offerCategoriesSlider .moreContentIndicator {
    right: -24px;
    display: block;
    display: flex;
    align-items: center;
    min-width: 35px;
    height: 40px;
  }

  .offerCategoriesSlider .moreContentIndicator > svg {
    color: var(--primary);
  }
}
