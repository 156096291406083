.footerContainer {
  width: 100vw;
  height: fit-content;
  background-color: var(--background);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 75px 250px 50px 250px;
  border-top: 1px solid var(--border);
  margin-top: 75px;
}

.footerLogo {
  width: 120px;
  height: 34px;
  background-image: url(../../../data/images/logov2.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.footerLinks {
  flex: 1;
  height: fit-content;
  background-color: var(--background);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 58vw;
  min-width: 58vw;
}

@media (max-width: 1165px) {
  .footerContainer {
    flex-direction: column;
  }

  .bbFooterSocials {
    width: 100%;
    position: relative;
    max-width: 58vw;
    min-width: 58vw;
    margin: 30px 0 0 0 !important;
  }

  .socialContainer {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    margin: 0px !important;
  }

  .footerLinks {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
  }

  .bbFooterLegal {
    justify-content: space-between;
    align-items: start !important;
    padding: 18px 235px 35px 235px !important;
  }

  .bbFooterLegal > p {
    margin: 15px 0 0 0 !important;
  }

  .divider.footer {
    width: 58vw;
    max-width: 58vw;
  }
}

@media (max-width: 1075px) {
  .bbFooterLegal {
    flex-direction: column;
    margin: 0px !important;
    max-width: 58vw;
    min-width: 58vw;
    margin: 0 auto !important;
    align-items: start !important;
    justify-content: start !important;
    padding: 18px 0 !important;
  }

  .bbFooterLegal > p {
    margin: 10px 0 !important;
  }
}

@media (max-width: 700px) {
  .footerLinks {
    grid-template-columns: repeat(1, 1fr);
  }

  .socialContainer {
    position: relative !important;
    top: 40px;
  }

  .footerContainer {
    padding: 50px 0px 20px 0;
  }

  .bbFooterSocials {
    min-height: 150px;
  }
}

.footerLinkContent {
  width: 100%;
}

.footerGrid {
  width: 80%;
  height: fit-content;
}

.footerGrid > p:nth-child(1) {
  color: var(--primary);
  font-weight: var(--bold);
  font-size: 14px;
  margin-bottom: 10px;
}

.footerLinkContent {
  margin-top: 6px;
}

.footerLinkContent:hover {
  font-weight: var(--medium);
}

.bbFooterSocials {
  margin-left: auto;
}

.bbFooterSocials > p:nth-child(3) {
  font-size: 14px;
  margin-top: 3px;
  color: var(--primary);
}

.socialContainer {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.socialContainerItem {
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #f1f1f1;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
  color: var(--subheader);
  cursor: pointer;
  border: 1px solid #f1f1f1;
  transition: 0.3s ease;
}

.socialContainerItem:hover {
  background-color: var(--primary);
  color: var(--white);
}

.bbFooterLegal {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 18px 250px 22px 250px;
}

.bbFooterLegal a {
  text-decoration: none;
  color: rgb(116, 116, 116);
}

.divider.footer {
  width: 71vw;
  margin: 0 auto;
}

.bbFooterLegal > p {
  font-size: 14px;
  margin-right: 80px;
  cursor: pointer;
}

.bbFooterLegal > p:hover {
  text-decoration: underline;
}

.bbFooterLegal > p:nth-child(3) {
  margin-left: auto;
  margin-right: 0px;
  cursor: default;
}
