.aboutReservationContent.settings.changePassword > span {
  margin-bottom: 0px;
}

.aboutReservationContent.settings.changePassword .inputSpanContainer {
  margin-bottom: 15px;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
}

.aboutReservationContent.settings.changePassword > p > u {
  font-weight: var(--medium);
  cursor: pointer;
  color: var(--text);
}

.aboutReservationContent.settings.changePassword > p > u:hover {
  font-weight: var(--semibold);
}

.aboutReservationContent.settings.changePassword > p {
  margin-top: 10px !important;
}

.inputIcon {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  font-size: 20px !important;
  color: var(--text) !important;
}

.aboutReservationContent.settings.changePassword .errorMessage {
  color: var(--error);
  margin-top: 0px;
  font-size: 13px;
  font-weight: normal;
}

@media (max-width: 480px) {
  .aboutReservationContent.settings.changePassword {
    min-height: 841px !important;
    max-height: 841px !important;
  }
}
