.mainUserInfoContainer {
  min-height: 100px;
  height: fit-content;
  border-radius: 12px;
  border: 1px solid var(--border);
  padding: 50px 28px 100px 28px;
  position: sticky;
  background-color: var(--white);
  min-width: 350px;
  top: 0px;
}

.currentUserInformation,
.currentUserInformation > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profileAvatarWrapper {
  position: relative;
  max-width: fit-content;
  max-height: fit-content;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profileAvatar {
  width: 96px !important;
  height: 96px !important;
  cursor: pointer;
}

.changeAvatarOverlay {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  /* display: none; */
}

.changeAvatarOverlay::-webkit-file-upload-button {
  display: none;
}

.profileAvatarWrapper .profileAvatar:hover .changeAvatarOverlay {
  display: block !important;
}

.currentUserInformation > div > b {
  font-weight: var(--semibold);
  margin-top: 20px;
  font-size: 16.5px;
}

.currentUserInfoMobile > b {
  font-size: 15px;
}

.currentUserInformation > div > p,
.currentUserInfoMobile > p {
  margin-top: 3px;
  font-size: 15.5px;
  color: var(--primary);
  font-weight: var(--medium);
}

.userProfileActions {
  display: grid;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 15px;
  margin-top: 25px;
  box-sizing: border-box;
}

.primaryBtn,
.secondaryBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 11px;
  border-radius: 5px;
  flex: 1;
  font-weight: var(--medium);
  border-radius: 10px;
  /* font-size: 16px; */
}

.userProfileActions .primaryBtn:hover {
  border: 1px solid var(--text) !important;
}

.primaryBtn > p,
.secondaryBtn > p {
  margin-right: 0px;
  font-size: 13px;
}

.primaryBtn {
  background-color: var(--primary);
  color: var(--white);
}

.secondaryBtn {
  border: 1px solid var(--primary);
  color: var(--primary);
  background-color: var(--white);
}

.accountInformationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.accountInformationItem > svg {
  margin-left: auto;
  stroke-width: 1.5;
  font-size: 21px;
}

.accountInformationItem {
  font-size: 20px !important;
}

.accountInformationItem > div > span {
  font-size: 14.5px;
  color: var(--primary);
}

.accountInformationItem > div {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px;
}

.accountInformationItem > div > p,
.accountInformationItem > div > u {
  margin-top: 5px;
  font-size: 14px;
  font-weight: var(--medium);
}

.accountInformationItem > div > u {
  color: var(--text);
  cursor: pointer;
}

.accountInformationItem > div > u:hover {
  font-weight: var(--semibold);
}

.profileSettings {
  position: absolute !important;
  top: 12px;
  right: 12px;
  color: var(--primary) !important;
}

.primaryBtn.settings {
  background-color: var(--white);
  border: 1px solid var(--border);
  color: var(--text);
}

.primaryBtn.settings > p {
  font-weight: var(--medium);
}

.showNotifications {
  position: absolute !important;
  top: 14px;
  right: 14px;
  font-size: 22px !important;
  color: var(--primary) !important;
}

.sidebarShortcutItem p {
  margin-right: auto;
}

.showNotifications > svg {
  stroke-width: 1.5px !important;
}

@media (max-width: 768px) {
  .mainUserInfoContainer {
    padding: 30px 25px 25px 25px;
    position: relative;
    height: fit-content;
    top: 15px;
    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border: 1px solid transparent;
    margin-top: 20px !important;
  }

  .profileAvatar {
    width: 110px !important;
    height: 110px !important;
  }

  .currentUserInfoMobile {
    display: block;
    margin-top: 12px;
    width: 100%;
    margin-bottom: 20px;
  }

  .userProfileActions {
    margin-top: 25px !important;
    display: flex;
    align-items: center;
    display: none;
  }

  .userProfileActions .sidebarShortcutItem.minPad.button {
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 12px;
    padding-left: 14px;
    background-color: #f1f1f1;
    margin-left: 0px;
  }

  .userProfileActions .sidebarShortcutItem.minPad.button > p {
    font-size: 14px;
    margin-right: auto;
  }

  .userProfileActions .sidebarShortcutItem.minPad.button .sidebarIcon {
    font-size: 18px;
    margin-right: 11px;
  }

  .currentUserInfoMobile > b {
    font-size: 18px !important;
    margin: 0px !important;
  }

  .currentUserInfoMobile > p {
    font-size: 14px !important;
    margin-top: 4px;
  }

  .primaryBtn.mobile,
  .secondaryBtn.mobile {
    height: 38px;
    width: 100% !important;
    min-width: 100% !important;
    flex: 1;
  }

  .primaryBtn.mobile > p,
  .secondaryBtn.mobile > p {
    font-size: 12px;
    margin: 0px;
  }
}

@media (max-width: 400px) {
  .userProfileActions .sidebarShortcutItem.minPad.button {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }

  .userProfileActions .sidebarShortcutItem.minPad.button > svg {
    font-size: 20px !important;
    margin-right: 0px !important;
  }

  .userProfileActions .sidebarShortcutItem.minPad.button > p {
    /* max-width: fit-content; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 2px;
    text-align: center;
    font-weight: var(--medium);
  }
}
