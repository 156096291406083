.calendarDayDetailsContent {
  animation: sidebar_animation 0.6s ease;
}

@keyframes sidebar_animation {
  0% {
    opacity: 0;
    left: -15px;
  }

  100% {
    opacity: 1;
    left: 0px;
  }
}

.dayDetailsTimeSelectHeader.nested {
  margin-top: 30px;
  flex-direction: column;
  align-items: start;
}

.multipleTrailerSelectWrapper {
  margin-top: 64px;
  width: 100%;
  position: relative;
}

.multipleTrailerSelectItem {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;
  width: 100%;
}

.multipleTrailerSelectItem > img {
  width: 100px;
  height: 64px;
  border-radius: 6px;
  background-color: var(--secondary);
}

.multipleTrailerSelectItem > div {
  margin-left: 15px;
}

.multipleTrailerSelectItem > div > p:nth-child(1) {
  font-weight: var(--medium);
  font-size: 14.2px;
}

.multipleTrailerSelectItem > div > p:nth-child(2) {
  font-size: 14.2px;
  margin-top: 4px;
  color: var(--subheader);
}

.multiSelectCheckbox {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid var(--border);
  margin-left: auto !important;
}

.multipleTrailerSelectItem:hover .multiSelectCheckbox {
  border: 1.5px solid var(--primary);
}

.calendarDayDetailsContent .primaryBtn.sidebar {
  width: 100%;
  padding: 14px;
  margin-top: auto;
  border-radius: 8px;
}

.notAvailableSidebarContent.infoBox {
  margin-top: 12px;
  padding-top: 20px;
}

.notAvailableSidebarContent.infoBox > p {
  margin-top: 0px;
  font-size: 14px;
}
