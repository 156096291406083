.trailerListViewImage,
.activeTrailerImage {
  object-fit: cover;
  width: 124px;
  min-width: 124px;
  height: 122px;
  border-radius: 5px;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.rentalTrailersListView > img {
  width: 300px;
  border-radius: 10px;
}

.rentalTrailersListView {
  margin-bottom: 25px;
}

.trailerListViewContainer,
.trailerListViewActive {
  width: 100%;
  height: fit-content;
  min-height: 50px;
  display: flex;
  position: relative;
}

.trailerListViewWrapper .divider {
  margin: 20px 0;
}

.trailerListViewWrapper:last-child .divider {
  display: none;
}

.trailerListViewWrapperActive .divider:last-child {
  display: none;
}

.trailerListViewActive {
  margin-bottom: 20px;
}

.trailerListViewImage,
.activeTrailerImage {
  object-fit: cover;
  width: 124px;
  min-width: 124px;
  height: 122px;
  border-radius: 5px;
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeTrailerImage {
  width: 152px;
  min-width: 152px;
  height: 147px;
  min-height: 147px;
}

.trailerListViewInformation {
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  position: relative;
}

.trailerListViewInformation > b {
  font-weight: var(--semibold);
  color: var(--primary);
}

.ratingRow {
  margin-top: 3px;
  font-size: 16px;
}

.smallDescription,
.trailerDescription {
  font-size: 13px;
  color: var(--subheader);
  margin-top: 8px;
  max-width: 90% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  /* number of lines to show */
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.smallDescription {
  margin-top: 10px;
  max-width: 100%;
}

.iconGrid,
.iconGridSmall {
  margin-top: 10px !important;
}

.trailerlistViewInformationStats {
  display: flex;
  align-items: center;
  font-size: 12.5px;
  font-weight: var(--medium);
  margin-top: 8px;
}

.trailerlistViewInformationStats > p {
  margin-left: 6px;
}

.trailerlistViewInformationStats > span {
  margin: 0 10px;
}

.trailerListViewActions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
}

.trailerCardContainer {
  width: fit-content;
  height: 100px;
}

.trailerActionbutton {
  margin-left: 8px !important;
}

/* Active trailer availability styling */

.activeTrailerDateSelect {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.activeTrailerDateSelect .divider {
  margin: 10px 0 0 0;
  width: 100%;
  height: 1px;
  min-height: 1px;
  background-color: var(--border);
}

.activeTrailerDateSelect > p {
  font-size: 15px;
  font-weight: var(--semibold);
  margin: 20px 0 15px 0;
}

.trailerListIcons {
  display: flex;
  flex-direction: column;
}

.trailerListIcons .divider {
  margin: 16px 0;
}

.iconGrid,
.iconGridSmall {
  display: flex;
  align-items: center;
  margin-top: auto;
  column-gap: 10px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  flex-wrap: wrap;
  row-gap: 10px;
  margin-bottom: 2px;
}

@media (max-width: 768px) {
  .iconGridSmall {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    flex-wrap: wrap;
  }
}

.iconGridSmall {
  margin-top: auto;
}

.iconGridSmall .iconGridItem {
  padding: 8px 13px 8px 10px;
  font-size: 15px;
  width: fit-content;
}

.iconGridItem.primary {
  background-color: var(--primary);
  color: white;
  border: 1px solid var(--primary);
}

.iconGridSmall .iconGridItem > p {
  font-size: 13px;
}

.iconGridItem {
  display: flex;
  align-items: center;
  font-size: 15px;
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 4px;
  width: fit-content;
  background-color: var(--white);
}

.iconGridItem > p {
  font-size: 12px;
  font-weight: var(--medium);
  margin-left: 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rentalHistoryList {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}

.rentalHistoryAvatar {
  width: 46px !important;
  height: 46px !important;
}

.iconGridItem.reservationStatus.confirmed,
.iconGridItem.reservationStatus.pending,
.iconGridItem.reservationStatus.denied {
  color: var(--secondary);
  /* border: 1px solid var(--secondary); */
  justify-content: center;
  font-size: 19.2px;
}

.iconGridItem.reservationStatus.pending {
  color: var(--text);
  stroke-width: 1.8;
  /* border: 1px solid #F7CB73; */
}

.iconGridItem.reservationStatus.denied {
  color: var(--error);
  /* border: 1px solid var(--error); */
}

.iconGridItem.reservationStatus > svg {
  stroke-width: 1.4;
  padding-right: 12px;
  padding-left: 12px;
}

.saveListViewTrailerBtn {
  position: absolute !important;
  top: -10px;
  right: 0px;
  font-size: 22px !important;
  color: var(--error) !important;
  stroke-width: 1.2 !important;
}

@media (max-width: 1000px) {
  .rentalTrailersListView {
    flex-direction: column;
  }

  .trailerListViewImage {
    max-width: 100%;
    width: 300px;
    height: 170px;
    object-position: center;
  }

  .trailerListViewInformation {
    margin-left: 0px;
    margin-top: 20px;
  }

  .iconGridSmall,
  .iconGrid {
    margin-top: 10px !important;
  }
}

@media (max-width: 510px) {
  .rentalTrailersListView {
    margin-bottom: 50px;
  }

  .trailerListViewImage {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
    height: 200px;
    object-position: center;
  }
}
