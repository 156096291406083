/* general button styling */
.button {
  font-weight: var(--semibold);
  transition: 0.2s ease;
  text-decoration: none;
  text-align: center;
}

/* variant specific button styling */
.button.primary {
  background-color: var(--primary);
  color: var(--white);

  &:not(:disabled):hover {
    background-color: var(--primary-darker);
  }
}

.button.gray {
  background-color: var(--input);
  color: var(--text);

  &:not(:disabled):hover {
    color: var(--darkBackground);
  }
}

.button.secondary {
  background-color: var(--secondary);
  color: var(--white);

  &:not(:disabled):hover {
    background-color: var(--secondary-darker);
  }
}

.button.primary__outline {
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);

  &:not(:disabled):hover {
    background-color: var(--primary);
    color: var(--white);
  }
}

.button.black__outline {
  background-color: transparent;
  border: 1px solid var(--border);
  color: var(--text);

  &:not(:disabled):hover {
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--primary);
  }
}

.button.primary__ghost {
  background-color: transparent;
  color: var(--primary);

  &:not(:disabled):hover {
    text-decoration: underline;
  }
}

.button.secondary__ghost {
  background-color: transparent;
  color: var(--secondary);

  &:not(:disabled):hover {
    background-color: var(--secondary-lighter);
  }
}

.button.underline {
  background-color: transparent;
  color: var(--primary);
  text-decoration: underline;
}

.button.disabled {
  background-color: var(--border);
}

/* size specific button styling */
.button.zero {
  padding: 0px 0px;
  font-size: 14px;
}

.button.small {
  padding: 12px 13.5px;
  font-size: 14px;
}

.button.medium {
  padding: 12px 18px;
  font-size: 14px;
}

.button.large {
  padding: 18px 27px;
}

.button.icon {
  height: 32px;
  width: 32px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* radius specific button styling */
.button.radius__small {
  border-radius: var(--borderRadiusSmall);
}

.button.radius__medium {
  border-radius: var(--borderRadiusMedium);
}

.button.radius__large {
  border-radius: var(--borderRadiusLarge);
}

.button.radius__xl {
  border-radius: var(--borderRadiusXl);
}

.button.radius__full {
  border-radius: var(--borderRadiusFull);
}

/* button is full width */
.button.is__full-width {
  width: 100%;
}

.button.is__disabled {
  background-color: var(--input);
}
