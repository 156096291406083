.ownerDashboardContent {
  width: 66vw;
  margin: 0 auto;
  box-sizing: border-box;
  /*padding: 50px 0;*/
  margin-top: -8px;
}

@media (max-width: 768px) {
  .css-130jr3z {
    padding-top: 25px !important;
  }
}
