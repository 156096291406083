.reservationPopupContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 430px;
  min-height: fit-content;
  border-radius: 18px;
  background-color: var(--white);
  box-sizing: border-box;
  padding: 40px 0px;
  animation: slide_in 0.3s ease;
  display: flex;
  flex-direction: column;
  max-height: 780px;
  height: fit-content;
}

.reservationPopupContainerHeader {
  box-sizing: border-box;
  padding: 0 35px;
}

.reservationPopupContainerHeader > p {
  font-size: 20px;
  font-weight: var(--semibold);
  color: var(--text);
  max-width: 210px;
}

.reservationPopupContainerMainContent {
  box-sizing: border-box;
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.aboutReservationContent > span:nth-child(1) {
  margin-top: 0px;
}

.divider.fullWidth {
  min-height: 1px !important;
  height: 1px !important;
  margin-top: 16px;
}

.reservationPopupContainerMainContent > span,
.aboutReservationContent > span {
  margin-top: 16px;
  font-size: 14px;
  color: var(--text);
  margin-bottom: 15px;
}

.sidebarShortcutItem.minPad {
  width: calc(100% + 16px);
  margin-left: -8px;
}

.previousStepBtn {
  max-width: fit-content !important;
  left: -10px !important;
  top: -20px !important;
  color: var(--primary) !important;
  margin-bottom: -5px !important;
}

.aboutReservationContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  min-height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 0 35px;
  margin-top: 15px;
}

.inputContainer.cancel,
.selectContainer {
  border: 1px solid var(--border);
  border-radius: 10px !important;
  height: 50px;
  min-height: 50px;
  margin-top: 20px;
}

.selectContainer:hover,
.inputContainer.cancel:hover {
  border: 1px solid var(--text);
}

.inputContainer.cancel > input {
  border-radius: 8px;
  font-size: 13.5px;
  font-weight: var(--medium);
}

.inputContainer.cancel > input::placeholder {
  font-size: 13.5px;
  font-weight: var(--medium);
  color: var(--subheader);
}

.imagesSliderContainer.reservation {
  height: 180px;
  min-height: 180px;
  margin-bottom: 15px;
  border-radius: 12px;
}

.ticketDetailItem.trailer > svg {
  stroke-width: 1.5px;
}

.reservationMapContainer {
  height: 220px;
  min-height: 220px;
  width: 100%;
}

.imagesSliderContainer.reservation img {
  height: 180px;
  object-fit: cover;
  border-radius: 12px;
}

.sidebarShortcutItem.reservations:nth-child(4) > svg:nth-child(1) {
  margin-right: 12px;
  width: 27px;
  stroke-width: 1.6;
}

/* About owner */

.aboutOwnerCard {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 35px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  background-color: var(--white);
  border-radius: 15px;
  position: relative;
  margin-top: 35px;
  margin-bottom: 10px;
  left: 0;
}

.aboutOwnerCard > div {
  width: 70px !important;
  height: 70px !important;
  min-width: 70px !important;
  min-height: 70px !important;
}

.aboutOwnerCard > p:nth-child(2) {
  font-size: 17px;
  font-weight: var(--semibold);
  margin-top: 8px;
}

.aboutOwnerCard > p:nth-child(3) {
  font-size: 15px;
  color: var(--primary);
  margin-top: 2px;
  font-weight: var(--medium);
}

/* Cancel */

.aboutReservationContent > p {
  font-size: 14px;
  color: var(--subheader);
}

.autoSizeTextarea.cancelPopup {
  max-height: 160px !important;
  height: 160px !important;
  margin-bottom: 30px !important;
  min-height: fit-content !important;
  border-radius: 12px;
  font-size: 14px !important;
}

.autoSizeTextarea.cancelPopup:hover {
  border: 1px solid var(--text);
}

.selectContainerWrapper {
  position: relative;
  overflow: visible !important;
}

.selectOptionsContainer {
  position: absolute;
  background-color: var(--white);
  width: 100%;
  top: 55px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.selectOptionsContainer > p {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
  font-weight: var(--medium);
}

.selectOptionsContainer > p:hover {
  background-color: var(--border);
}

.primaryBtn.cancel {
  max-height: 58px;
  margin-top: auto;
  margin-bottom: 30px;
}

@media (max-width: 480px) {
  .reservationPopupContainer {
    width: 100vw;
    top: auto;
    bottom: 0px;
    transform: translate(-50%, 0);
    min-height: 100vh;
    border-radius: 0px;
    animation: bottom_slide 0.3s ease;
  }

  .aboutReservationContent {
    max-height: 84vh;
    height: 84vh;
    padding: 0 24px;
  }
}

@keyframes bottom_slide {
  0% {
    bottom: -92vh;
  }

  100% {
    bottom: 0px;
  }
}
