/* trailer profile header */
.trailer__offer__image {
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  border-radius: var(--borderRadiusMedium);
  object-fit: cover;
  position: relative;
  transition-duration: 200ms;
  filter: brightness(80%);
  cursor: pointer;
}

.trailer__offer__image:not(.cover) {
  max-height: 100%;
}

.grid__item__cover {
  min-height: 332px;
  max-height: 332px;
  overflow: hidden;
}

.grid__item__normal {
  min-height: 158px;
  max-height: 158px;
  overflow: hidden;
}

.trailer__offer__image:hover {
  filter: brightness(100%);
}

.trailer__modal__button--wrapper {
  right: 0;
  width: 100%;
  transform: translateY(-54px);
  padding: 0px 8px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -54px;
  z-index: 1;
}

.trailer__modal__button {
  top: 0;
  z-index: 1000;
}

/* trailer profile photos slider */
.carousel__image {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: var(--borderRadiusMedium);
}

.carousel__footer__group {
  transform: translateY(-100%);
  margin-bottom: -4rem;
  padding: 1rem;
}

.carousel__button__back,
.carousel__button__next {
  padding: 12px 18px;
  border-radius: var(--borderRadiusMedium);
  background-color: var(--input);
  color: var(--darkBackground);
  font-weight: var(--semibold);
}

.carousel__stats {
  background-color: var(--darkBackground);
  color: var(--white);
  border-radius: var(--borderRadiusMedium);
  padding: 0.5rem;
  font-size: var(--fontSmall);
}

.carousel__dot-group {
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.carousel__dot-group::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.carousel__dot {
  margin-top: 0.5rem;
  padding: 0px;
  background-color: transparent;
}

.carousel__dot--selected .carousel__dot-image {
  border: 3px solid var(--secondary);
}

.carousel__dot-image {
  border-radius: var(--borderRadiusMedium);
  max-width: 120px;
  object-fit: cover;
  min-height: 100%;
  max-height: 100px;
  border: 3px solid var(--white);
}

/* trailer content profile */
.trailer__content-title {
  padding: var(--padding5Xl) 0px;
}

.trailer__content-seperator {
  border-bottom: 1px solid var(--input);
  margin-bottom: var(--padding3Xl);
}

.trailer__flex-seperator {
  border-bottom: 1px solid var(--input);
  margin-top: 24px;
}

.trailer__profile_hire .trailer__flex-seperator {
  margin-top: 0px;
}

.trailer__content-icon--rotate90 {
  rotate: 90deg;
}

/* map */
.trailer__map {
  height: 220px;
  min-height: 220px;
  width: 100%;
}

.trailer__map_marker {
  width: 50px !important;
  height: 50px !important;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.trailer__map_marker > svg {
  stroke: var(--white);
  stroke-width: 1.5;
}

/* trailer profile */
.trailer__profile_header {
  border-radius: var(--borderRadiusMedium);
  border: 1px solid var(--input);
  padding: var(--padding2Xl) var(--padding2Xl);
}

.trailer__profile_header span {
  color: var(--primary);
  font-weight: var(--semibold);
}

.trailer__profile_header_image {
  max-width: 50px;
  min-width: 50px;
  min-height: 50px;
  max-height: 50px;
  object-fit: cover;
  border-radius: 100%;
}

.trailer__profile_header p {
  font-size: 14px;
  font-weight: var(--regular);
}

.trailer__profile_hire {
  border-radius: var(--borderRadiusMedium);
  border: 1px solid var(--input);
  padding: var(--padding2Xl) var(--padding2Xl);
  margin-top: 32px;
}

.trailer__profile_hire_total {
  font-size: 14px;
  color: var(--primary);
  padding: var(--paddingXs) 0;
}

.trailer__profile_hire_total_price {
  font-weight: var(--semibold);
}

@media screen and (max-width: 992px) {
  .trailer__content-title {
    padding: var(--padding5Xl) 0 var(--padding2Xl) 0;
  }

  .trailer__content-seperator {
    padding-top: var(--padding5Xl);
  }

  .trailer__content_body {
    margin-top: 32px;
  }
}
