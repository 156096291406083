.dayDetailsTimeSelectCombo {
  align-items: center;
  border: 1px solid var(--border);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 14px;
  border-radius: 16px;
}

.dayDetailsTimeSelectCombo:hover {
  border: 1px solid var(--text);
}

.dayDetailsTimeSelect,
.dayDetailsTimeSelectActive {
  min-width: 100%;
  width: 100%;
  height: 82px;
  /* margin-top: 18px; */
  /* border: 1px solid var(--border); */
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;
  position: relative;
}

.dayDetailsTimeSelect:hover {
  border: 1px solid transparent;
}

/* .dayDetailsTimeSelectActive {
    border: 1px solid var(--text);
} */

.dayDetailsTimeSelect > div,
.dayDetailsTimeSelectActive > div {
  display: flex;
  flex-direction: column;
}

.dayDetailsTimeSelect > div > span,
.dayDetailsTimeSelectActive > div > span {
  font-size: 12px;
  font-weight: normal;
  color: #707070;
}

.dayDetailsTimeSelect > div > p,
.dayDetailsTimeSelectActive > div > p {
  font-size: 13.5px;
  font-weight: var(--semibold);
  margin-top: 2px;
}

.chevronTranfsormed {
  transition: 0.2s ease;
  transform: rotate(180deg);
}

.chevron {
  transition: 0.2s ease;
}

.calendarTimeSuggestionsContainer {
  position: relative;
  top: 0p;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: 8px;
  box-sizing: border-box;
  /* padding: 10px; */
  z-index: 1;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 6px;
  cursor: default;
}

.dayDetailsTimeSelectOptions.startTime {
  padding-right: 20px;
  padding-left: 5px;
}

.dayDetailsTimeSelectOptions.endTime {
  padding-left: 20px;
  padding-right: 5px;
}

.timeSelectSuggestionText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
}

.timeSelectSuggestionText:hover,
.timeSelectSuggestionText:hover p {
  background-color: #f8e6dc;
  color: var(--primary);
  border-radius: 3px;
}

.timeSelectSuggestionText p {
  font-size: 13.5px !important;
  font-weight: var(--medium) !important;
}

.chevronTransFormed.timeSelectIcon {
  top: 38.5% !important;
}

.dayDetailsTimeSelectHeader > p {
  margin-top: 6px;
  font-size: 13.5px;
  color: var(--subheader);
}

.dayDetailsTimeSelectOptionsContainer {
  position: absolute;
  top: 125px;
  left: 0px;
  background-color: var(--white);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 15px 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  width: 100%;
  z-index: 92;
}

.dayDetailsTimeSelectOptionsContainerHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border);
}

.dayDetailsTimeSelectOptionsContainerHeader > p {
  font-weight: var(--semibold);
  font-size: 13.5px;
}

.timeSelectOptionsWrapper {
  display: flex;
}

.dayDetailsTimeSelectOptions {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  max-height: 140px;
  overflow-y: scroll;
  box-sizing: border-box;
  padding-top: 12px;
  min-height: 140px;
  align-items: start;
}

.dayDetailsTimeSelectOptions:nth-child(1) {
  border-right: 0.5px solid var(--border);
}

.dayDetailsTimeSelectOptions:nth-child(2) {
  border-left: 0.5px solid var(--border);
}

.dayDetailsTimeSelectOptions > div > p {
  width: 100%;
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13.5px;
  font-weight: var(--medium);
}

.timeSelectSuggestionText .selectedTime {
  color: var(--primary);
  font-weight: var(--semibold) !important;
}
