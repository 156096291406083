.calenderViewDayContainer.mobile {
  min-height: fit-content;
  padding: 0px;
  min-width: fit-content;
  min-height: 90px;
}

.calenderViewDayContainer.mobile > button > div {
  display: flex;
  flex-direction: column;
}

.mobileCalendarDayPrice {
  font-size: 12px !important;
  margin-top: 10px;
  color: var(--subheader) !important;
}

.currentDay.mobile {
  padding: 8px;
}

.dayItemContent.mobile span {
  font-size: 12.5px;
}

.calendarDayDetailsContainer.mobile {
  height: 100%;
  overflow-y: auto;
}
