.trailerFormContainer {
  width: 100vw;
  height: fit-content;
  min-height: fit-content;
  position: relative;
  top: 20px;
  box-sizing: border-box;
  padding: 35px 0 0 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: var(--background);
  background-color: var(--white);
  padding-bottom: 120px;
}

@media (max-width: 420px) {
  .trailerFormContainer {
    padding-bottom: 0px;
    top: 60px;
  }
}
