.textAreaContainer {
  position: relative;
}

.placeholder {
  position: absolute;
  top: 8px;
  left: -17px;
  color: var(--subheader);
  font-size: 14.5px;
  pointer-events: none;
  z-index: 2;
  max-width: calc(100% - 48px);
  line-height: 1.5;
}

.placeholderList > li {
  margin-bottom: 10px;
}

.textArea {
  width: 100% !important;
  height: 350px !important;
  border: 1px solid var(--border);
  padding: 20px;
  color: #222222;
  font-weight: var(--medium);
  font-size: 14.5px !important;
  transition: 0.3s ease !important;
  border-radius: 8px !important;
  outline: none !important;
  line-height: 1.5 !important;
  resize: none;
  background-color: transparent !important;
  position: relative;
  font-family: 'Open sans' !important;
}

.textArea.description {
  height: 350px !important;
  border-radius: 14px !important;
}

.charCountContainer {
  position: relative;
  top: 15px;
  right: 0px;
  background-color: white;
  border-radius: 4px;
  display: flex;
}

.textAreaCount {
  font-weight: var(--semibold);
  color: var(--text);
  margin: 0;
  margin-left: auto;
  right: 0px;
  position: relative;
}

.placeholderList {
  list-style: none;
}

.textArea:active,
.textArea:focus {
  border: 1.5px solid var(--darkBackground);
  outline: none;
  background-color: #fff;
}

.placeholderList > li {
  font-family: 'Open sans' !important;
  font-size: 14.5px !important;
  min-width: 160px;
}

.hiddenLi {
  visibility: hidden;
}

.trailerNameInput {
  width: 100%;
  border: 1px solid var(--border);
  border-radius: 14px;
  height: 75px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 14.5px;
  color: #222222;
  font-weight: var(--medium);
  transition: 0.3s ease;
}

.trailerNameInput:focus {
  border: 1.5px solid var(--text);
}

.trailerNameInput::placeholder {
  color: #2a2a2a;
}

.formStepContainer.description {
  min-height: 66vh;
}

/* Responsive layout */

@media (max-width: 420px) {
  .textArea.description {
    min-height: 500px !important;
    font-size: 15px;
  }
}
