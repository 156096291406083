.popupContainer {
  display: flex;
  flex-direction: column;
}

.actionRow {
  margin: 10px auto 30px auto;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.completeAction {
  padding: 8px 15px;
  border-radius: 50px;
  font-weight: var(--medium);
  background-color: var(--primary);
  color: var(--white);
  border: 1px solid var(--primary);
}

.completeAction.secondary {
  background-color: var(--white);
  color: var(--primary);
}
