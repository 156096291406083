.trailerFormMap {
  width: 100%;
  height: 500px;
  position: relative;
  margin-top: 0px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  padding-bottom: 30px;
}

.locationInput {
  width: 90%;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  padding: 0 20px 0 20px;
  border-radius: 30px;
  height: 48px;
  border: none;
  outline: none;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  font-weight: var(--semibold);
}

@media (max-width: 480px) {
  .locationInput {
    border: 1px solid var(--border);
  }
}

.locationInput::placeholder {
  color: var(--text);
  font-weight: var(--semibold);
}

.pac-container {
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: none;
  box-sizing: border-box;
  padding: 10px;
  max-height: 220px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.pac-item,
.pac-item-query {
  color: black;
  box-sizing: border-box;
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
}

.pac-item:hover {
  cursor: pointer;
  background-color: var(--border);
}

.pac-item:last-child {
  margin-bottom: 10px;
}

/* Exact location information */

.exactLocationInput {
  width: 100%;
  /* height: fit-content; */
  border: 1px solid var(--border);
  border-radius: 5px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.exactLocationInputItem {
  display: flex;
  border-bottom: 1px solid var(--border);
  box-sizing: border-box;
  padding: 12px 0 0 0;
  position: relative;
}

.exactLocationInputItem:nth-child(1) {
  grid-area: 1 / 1 / 2 / 6;
}

.exactLocationInputItem:nth-child(2) {
  grid-area: 2 / 1 / 3 / 2;
  border-bottom: none;
}

.exactLocationInputItem:nth-child(3) {
  grid-area: 2 / 2 / 3 / 4;
  border-right: 1px solid var(--border);
  border-left: 1px solid var(--border);
  border-bottom: none;
}

.exactLocationInputItem:nth-child(4) {
  grid-area: 2 / 4 / 3 / 6;
  border-bottom: none;
}

.exactLocationInputItem input {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 12px 8px 15px;
  border: none;
  outline: none;
  transition: 0.5s;
  font-size: 16px;
  border-radius: 5px;
}

.exactLocationInputItem span {
  position: absolute;
  font-weight: var(--medium);
  left: 15px;
  pointer-events: none;
  font-size: 15px;
  transition: 0.3s;
  top: 50%;
  transform: translateY(-55%);
  color: var(--subheader);
}

.exactLocationInputItem input:valid ~ span,
.exactLocationInputItem input:focus ~ span {
  color: var(--subheader);
  transform: translateX(0px) translateY(-20px);
  font-size: 11px;
}

@media (max-width: 500px) {
  .trailerFormMap {
    height: 360px;
  }
}

.heroLocationAutoComplete {
  flex: 1 !important;
  min-height: 90%;
  border: none;
  outline: none;
  box-sizing: border-box;
  padding: 0 10px;
  color: var(--primary);
  font-weight: var(--medium);
}

.googleMapContainer {
  width: 100%;
  height: 100%;
}

.trailerTypeMarkerWrapper {
  width: fit-content;
  height: fit-content;
  display: flex;
}

.trailerTypeMarkerRadius {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  position: relative;
}

.trailerTypeMarker {
  width: 66px !important;
  height: 66px !important;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.trailerTypeMarker > svg {
  stroke: var(--white);
  stroke-width: 1.5;
}
