/* Price filters */

.priceGraphContainer {
  width: 100%;
  height: fit-content;
  min-height: fit-content;
}

.priceSlider {
  width: 100%;
  height: 25px;
}

.example-thumb {
  height: 20px;
  width: 20px;
  background-color: var(--primary);
  border-radius: 50%;
  cursor: grab;
  top: -10px;
}

.example-track {
  background: #ddd;
  height: 2px;
}

.histogram {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 110px;
  width: 100%;
}

.histogram-bar {
  width: 10%;
  color: white;
  text-align: center;
  margin-right: 3.5px;
  margin-left: 3.5px;
  background-color: var(--input);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.sliderValues {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  position: relative;
}

.sliderValueItem {
  display: flex;
  align-items: center;
  border: 1px solid var(--border);
  height: 44px;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 6px;
  border-radius: 12px;
}

.sliderValueItem > input {
  max-width: 80px;
  height: 94%;
  margin-left: 2px;
  border-radius: 10px;
  flex: 1;
}
