.profileInfoMobile {
  margin-top: -15px;
}

.mobileProfileItem {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 22px 0;
  border-bottom: 1px solid var(--border);
  cursor: pointer;
}

.mobileProfileItem > svg {
  font-size: 24px;
  stroke-width: 1.5;
  color: var(--text);
}

.mobileProfileItem > p {
  margin-left: 14px;
  font-size: 14.5px;
  color: var(--text);
  margin-right: auto;
  font-weight: var(--medium);
}

.filledPrimary {
  margin-top: 25px;
  width: 100%;
  background-color: var(--text);
  font-weight: var(--semibold);
  color: var(--white);
  border-radius: 12px;
  height: 48px;
}
