.trailerCardListContainer {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 18px;
  text-decoration: none !important;
  /* flex-wrap: wrap; */
}

.trailer__card_list_link {
  color: inherit;
  text-decoration: none;
}

.trailerCardListImage {
  width: 295px;
  height: 170px;
  min-width: 295px;
  position: relative;
  background-color: var(--secondary);
  border-radius: 12px;
}

.trailerCardListImage > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.saveTrailerAction {
  position: absolute !important;
  top: 0px;
  right: 0px;
  color: var(--error) !important;
  font-size: 21px !important;
}

.trailerCardListInformation {
  margin-left: 22px;
  display: flex;
  flex-direction: column;
}

.trailerCardListInformation p {
  word-wrap: anywhere;
}

.trailerCardListInformation>p:nth-child(1) {
  color: var(--primary);
  font-weight: var(--medium);
  font-size: 14px;
}

.trailerCardListInformation > b {
  margin-top: 4px;
  font-weight: var(--semibold);
  color: var(--text);
  font-size: 15.5px;
}

.trailerCardListInformation > p:nth-child(3) {
  font-size: 14px;
  color: var(--subheader);
  margin-top: 8px;
  max-width: 80%;
}

.tagRow {
  margin-top: 16px;
  flex-wrap: wrap;
}

.trailerCardListTag {
  border: 1px solid var(--border);
  box-sizing: border-box;
  padding: 9px 16px 9px 16px;
  border-radius: 12px;
  min-width: fit-content;
  margin-right: 10px;
  margin-bottom: 15px;
}

.trailerCardListTag > p {
  font-size: 13.5px;
  margin-left: 8px;
  color: var(--text);
  font-weight: var(--medium);
  text-overflow: ellipsis;
  flex-wrap: wrap;
  overflow: hidden;
  max-width: 180px;
}

.arrow {
  position: absolute;
  top: 50%;
  font-size: 1.4em;
  background: rgba(255, 255, 255, 0.6);
  border: none;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--text);
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  max-height: 32px;
  max-width: 32px;
  transform: translateY(-50%);
  display: none;
}

.arrow > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.trailerCardListImage:hover .arrow {
  display: block;
}

@media (max-width: 768px) {
  .trailerCardListContainer {
    display: flex;
    flex-direction: column;
    /* Change to vertical layout on small screens */
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    margin-bottom: 18px;
    margin-bottom: 25px;
  }

  .trailerCardListImage {
    width: 100%;
    position: relative;
    background-color: var(--secondary);
    border-radius: 12px;
  }

  .trailerCardListImage > img {
    width: 100%;
    height: auto;
    /* Maintain aspect ratio */
    object-fit: cover;
    border-radius: 12px;
  }

  .trailerCardListInformation {
    margin-left: 0;
    /* Adjust margin for small screens */
    margin-top: 10px;
    /* Add top margin for vertical layout */
    display: flex;
    flex-direction: column;
    text-align: start;
    /* Center text for small screens */
  }
}
